import { createStoreContext } from '@vklink/libs-state';

import { type CMoveChangeStatusStoreInstance } from './CMoveChangeStatusStore';

const [CMoveStoreProvider, useCMoveChangeStatusStore] =
  createStoreContext<CMoveChangeStatusStoreInstance>();

export { CMoveStoreProvider, useCMoveChangeStatusStore };

export {
  default as CMoveChangeStatusStore,
  type CMoveChangeStatusStoreInstance,
  type CMoveChangeStatusStoreEnv,
} from './CMoveChangeStatusStore';
