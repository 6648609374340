import { generatePath } from 'react-router';

import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultLocationFilterParams,
  LocationFilterParams,
  LocationFilterParamsModel,
  LocationModel,
  DefaultLocationValue,
  WarehouseOptionsModel,
  SkuOptionsModel,
  SelectedLocationModel,
  SelectedLocation,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { LocationStatus } from 'enums';
import { removeEmptyInObject } from 'pages/shared/utils';
import { GOODS_API, LOCATION_API, WAREHOUSE_API } from 'api';

export type LocationStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const LocationStore = types
  .model('Location Store', {
    locations: types.array(LocationModel),
    locationDetail: types.maybe(LocationModel),
    selectedLocation: types.maybe(SelectedLocationModel),
    warehouseOptions: types.array(WarehouseOptionsModel),
    skuOptions: types.array(SkuOptionsModel),
    filterParams: types.optional(LocationFilterParamsModel, DefaultLocationFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get locationList() {
        return self.locations;
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getSkuOptions() {
        return self.skuOptions.map((el) => {
          return { value: el.id, label: el.code };
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<LocationStoreEnv>(self);

    const setQueryParams = (filterParams: LocationFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultLocationFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setSelectedItem = (location: SelectedLocation) => {
      self.selectedLocation = { ...location };
    };

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(WAREHOUSE_API.GET_WAREHOUSES);
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getSkuOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(GOODS_API.GET_GOODS);
        applySnapshot(self.skuOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationsAsync = flow(function* () {
      const loadingId = load('Get Location Async');
      const response = yield http.get(LOCATION_API.GET_LOCATIONS, {
        params: self.getQueryParams,
      });
      applySnapshot(self.locations, []);
      applySnapshot(self.locations, [...response.data]);
      applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const createLocation = flow(function* (
      location?: typeof DefaultLocationValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Location');
      try {
        const addLocation = removeEmptyInObject(location);
        yield http.post(LOCATION_API.POST_LOCATION, addLocation);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editLocation = flow(function* (
      location?: typeof DefaultLocationValue,
      id?: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Edit Location');
      try {
        yield http.put(generatePath(LOCATION_API.EDIT_LOCATION, { id: id }), { ...location, id });
        applySnapshot(self.locations, []);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationById = flow(function* (id: string) {
      const loadingId = load('Get Location By Id');
      const response = yield http.get(generatePath(LOCATION_API.GET_LOCATION_BY_ID, { id: id }));
      self.locationDetail = response.data;
      loaded(loadingId);
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Edit Location Status');
      try {
        const locationStatus = (
          self.selectedLocation?.status === LocationStatus.ACTIVE
            ? LocationStatus.INACTIVE
            : LocationStatus.ACTIVE
        ).toLowerCase();
        yield http.put(
          generatePath(LOCATION_API.PUT_STATUS_WAREHOUSE, {
            id: self.selectedLocation?.id,
            status: locationStatus,
          })
        );
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getLocationsAsync,
      createLocation,
      editLocation,
      getLocationById,
      setQueryParams,
      setSelectedItem,
      setPaginationParams,
      updateStatus,
      getWarehouseOptionsAsync,
      getSkuOptionsAsync,
    };
  });

export default LocationStore;
export type LocationStoreInstance = Instance<typeof LocationStore>;
