export enum OrderStatus {
  PICKED_UP_ORDER = 'PICKED_UP_ORDER',
  EXPORTED_ORDER = 'EXPORTED_ORDER',
  RECEIVED_ORDER = 'RECEIVED_ORDER',
  FULL_RETURN_ORDER = 'FULL_RETURN_ORDER',
  PARTIAL_RETURN_ORDER = 'PARTIAL_RETURN_ORDER',
  REPLACEMENT_ORDER = 'REPLACEMENT_ORDER',
  CANCEL_ORDER = 'CANCEL_ORDER',
  WAITING_ORDER = 'WAITING_ORDER',
  CONFIRMED_ORDER = 'CONFIRMED_ORDER',
}
