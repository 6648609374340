import { types, SnapshotOut } from '@vklink/libs-state';
import { guid } from '@vklink/libs-utils';

export const DummyDataModel = types.snapshotProcessor(
  types.model({
    id: types.identifier,
    column1: types.maybeNull(types.string),
    column2: types.maybeNull(types.string),
    column3: types.maybeNull(types.string),
    column4: types.maybeNull(types.string),
    column5: types.maybeNull(types.string),
    column6: types.maybeNull(types.string),
    column7: types.maybeNull(types.string),
    column8: types.maybeNull(types.string),
    column9: types.maybeNull(types.string),
    column10: types.maybeNull(types.string),
    column11: types.maybeNull(types.string),
    column12: types.maybeNull(types.string),
    column13: types.maybeNull(types.string),
    column14: types.maybeNull(types.string),
    column15: types.maybeNull(types.string),
    column16: types.maybeNull(types.string),
    column17: types.maybeNull(types.string),
    column18: types.maybeNull(types.string),
    column19: types.maybeNull(types.string),
    column20: types.maybeNull(types.string),
    column21: types.maybeNull(types.string),
  }),
  {
    preProcessor(sn: any): any {
      return {
        ...sn,
        id: guid(),
      };
    },
  }
);

export interface DummyData extends SnapshotOut<typeof DummyDataModel> {}
