import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { OrderPackagesModel } from './PackagesDetailModel';

const SalesReceModel = types.model('SalesRece Model', {
  id: types.identifier,
  name1: types.maybeNull(types.string),
  name2: types.maybeNull(types.string),
  tel1: types.maybeNull(types.string),
  tel2: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
});

const OrderPickupsModel = types.model('Order Pickups Model', {
  id: types.identifier,
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
});

const OrderDeliveryModel = types.model('Order Delivery Model', {
  id: types.identifier,
  outDate: types.maybeNull(types.string),
  outBy: types.maybeNull(types.string),
  packingDate: types.maybeNull(types.string),
  packingBy: types.maybeNull(types.string),
  receivedDate: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export const ExportStockModel = types.model('ExportStock Model', {
  id: types.identifier,
  orderNumber: types.string,
  customerName: types.maybeNull(types.string),
  fullAddress: types.maybeNull(types.string),
  gaOrder: types.string,
  mbid2: types.string,
  returnTf: types.string,
  sellDate: types.string,
  warehouse: types.model({
    id: types.identifier,
    name: types.string,
  }),
  orderDelivery: types.maybeNull(OrderDeliveryModel),
  orderPickups: types.array(OrderPickupsModel),
  orderPackages: types.array(OrderPackagesModel),
  salesRece: SalesReceModel,
  weight: types.maybeNull(types.number),
  numberOfPackages: types.maybeNull(types.number),
  receiveMethod: types.maybeNull(types.string),
});

export interface ExportStockInstance extends Instance<typeof ExportStockModel> {}
export interface ExportStockInputModel extends SnapshotIn<typeof ExportStockModel> {}
export interface ExportStock extends SnapshotOut<typeof ExportStockModel> {}
