export enum StockStatus {
  OTHER = '',
  AVAILABLE = 'AVAILABLE',
  BOOKING = 'BOOKING',
  COMMITTED = 'COMMITTED',
  SOLD = 'SOLD',
  RETURN = 'RETURN',
  INTERNAL = 'INTERNAL',
  UNAVAILABLE = 'UNAVAILABLE',
  QUARANTINE = 'QUARANTINE',
  DAMAGED = 'DAMAGED',
  DELIVERED = 'DELIVERED',
  INTRANSIT = 'INTRANSIT',
}
