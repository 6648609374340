import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const RefundProductModel = types.model('RefundProduct Model', {
  id: types.identifier,
  dateReturn: types.string,
  orderNumber: types.string,
  productValue: types.string,
  deduct: types.string,
  refundPoint: types.string,
  refundAmount: types.string,
  qvPaidProduct: types.string,
  note: types.string,
  orderDate: types.string,
  orderCode: types.string,
  orderCategorization: types.string,
});

export interface RefundProductInstance extends Instance<typeof RefundProductModel> {}
export interface RefundProductInputModel extends SnapshotIn<typeof RefundProductModel> {}
export interface RefundProduct extends SnapshotOut<typeof RefundProductModel> {}
