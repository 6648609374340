import { SnapshotOut, types } from 'mobx-state-tree';
import { OrderItemModel } from './OrderItemModel';

export const OrderDetailModel = types.model({
  orderNumber: types.string,
  mbid2: types.string,
  customerName: types.maybeNull(types.string),
  items: types.array(OrderItemModel),
  replacementDate: types.maybeNull(types.string),
  actionType: types.maybeNull(types.string),
  orderStatus: types.string,
});

export const DefaultOrderDetail: OrderDetail = {
  orderNumber: '',
  mbid2: '',
  customerName: '',
  items: [],
  replacementDate: null,
  actionType: '',
  orderStatus: '',
};

export interface OrderDetail extends SnapshotOut<typeof OrderDetailModel> {}
