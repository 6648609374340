import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const DeliveryInfoModel = types.model('Order Delivery Model', {
  id: types.identifier,
  orderNumber: types.string,
  name1: types.maybeNull(types.string),
  name2: types.maybeNull(types.string),
  tel1: types.maybeNull(types.string),
  tel2: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  wardCode: types.maybeNull(types.string),
  wardName: types.maybeNull(types.string),
  districtCode: types.maybeNull(types.string),
  districtName: types.maybeNull(types.string),
  provinceCode: types.maybeNull(types.string),
  provinceName: types.maybeNull(types.string),
  fullAddress: types.maybeNull(types.string),
});

export interface DeliveryInfoInstance extends Instance<typeof DeliveryInfoModel> {}
export interface DeliveryInfoInputModel extends SnapshotIn<typeof DeliveryInfoModel> {}
export interface DeliveryInfo extends SnapshotOut<typeof DeliveryInfoModel> {}
