import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ProductListModel = types.model('Product List Model', {
  id: types.identifier,
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  batchNumber: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  importLocationId: types.maybeNull(types.string),
  orderedQuantity: types.number,
  receivedQuantity: types.maybeNull(types.optional(types.string, '0')),
  remainQuantity: types.maybeNull(types.optional(types.string, '0')),
  timeImported: types.maybeNull(types.optional(types.string, '0')),
  importQuantity: types.maybeNull(types.optional(types.number, 0)),
  exportLocation: types.maybeNull(
    types.model({
      name: types.maybeNull(types.string),
      id: types.string,
      code: types.string,
    })
  ),
});

export interface ProductListInstance extends Instance<typeof ProductListModel> {}
export interface ProductListInputModel extends SnapshotIn<typeof ProductListModel> {}
export interface ProductList extends SnapshotOut<typeof ProductListModel> {}
