import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { CategoryType } from 'enums';

export const CategoryModel = types.model('Category Model', {
  id: types.identifier,
  column1: types.string,
  column2: types.string,
  column3: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  createdBy: types.string,
  updatedBy: types.string,
});

export const DefaultCategoryValue: Partial<CategoryInputModel> = {
  column1: '',
  column2: CategoryType.PRODUCT_ONLINE,
  column3: '',
};

export interface CategoryInstance extends Instance<typeof CategoryModel> {}
export interface CategoryInputModel extends SnapshotIn<typeof CategoryModel> {}
export interface Category extends SnapshotOut<typeof CategoryModel> {}
