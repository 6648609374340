import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const StockTakeVoucherItemModel = types.model('StockTakeVoucher Model', {
  id: types.maybeNull(types.string),
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  goodsSellPrice: types.number,
  goodsOriginalPrice: types.number,
  batchNumber: types.maybeNull(types.string),
  locationId: types.maybeNull(types.string),
  locationName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  statusABC: types.maybeNull(types.number),
  statusQ: types.maybeNull(types.number),
  statusRIU: types.maybeNull(types.number),
  inputQuantityABC: types.maybeNull(types.number),
  inputQuantityQ: types.maybeNull(types.number),
  inputQuantityRIU: types.maybeNull(types.number),
  differenceQuantityABC: types.maybeNull(types.number),
  differenceQuantityQ: types.maybeNull(types.number),
  differenceQuantityRIU: types.maybeNull(types.number),
  totalStockQuantity: types.maybeNull(types.number),
  totalInputQuantity: types.maybeNull(types.number),
  totalDifferenceQuantity: types.maybeNull(types.number),
  totalDifferencePrice: types.maybeNull(types.number),
  note: types.maybeNull(types.string),
  inputQuantity: types.maybeNull(types.number),
  stockQuantity: types.maybeNull(types.number),
  differenceStock: types.maybeNull(types.number),
  differenceOriginalPrice: types.maybeNull(types.number),
  differenceSellPrice: types.maybeNull(types.number),
});

export const DefaultStockTakeVoucherItemValue: Partial<StockTakeVoucherItemInputModel> = {
  id: '',
  goodsId: '',
  goodsCode: '',
  goodsName: '',
  goodsSellPrice: 0,
  goodsOriginalPrice: 0,
  status: '',
  batchNumber: '',
  locationId: '',
  locationName: '',
  inputQuantityABC: 0,
  inputQuantityQ: 0,
  inputQuantityRIU: 0,
  note: '',
};

export interface StockTakeVoucherItemInstance extends Instance<typeof StockTakeVoucherItemModel> {}
export interface StockTakeVoucherItemInputModel
  extends SnapshotIn<typeof StockTakeVoucherItemModel> {}
export interface StockTakeVoucherItem extends SnapshotOut<typeof StockTakeVoucherItemModel> {}
