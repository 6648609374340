import { ImportStockType, ImportStockStatus, ImportStockPart } from 'enums';

export const ImportStockTypeOptions: SelectOption[] = [
  {
    label: 'Purchase Order',
    value: ImportStockType.PURCHASE_ORDER,
  },
  {
    label: 'Transfer Note',
    value: ImportStockType.TRANSFER_NOTE,
  },
];

export const ImportStockStatusOptions: SelectOption[] = [
  {
    label: 'In Transit',
    value: ImportStockStatus.INTRANSIT,
  },
  {
    label: 'Completed (Abnormal)',
    value: ImportStockStatus.COMPLETED_ABNORMAL,
  },
  {
    label: 'Completed (Partial)',
    value: ImportStockStatus.INPUT_PARTIAL,
  },
  {
    label: 'Completed',
    value: ImportStockStatus.COMPLETED,
  },
  {
    label: 'Cancelled',
    value: ImportStockStatus.CANCELLED,
  },
];

export const ImportStockPartOptions: SelectOption[] = [
  {
    label: 'Full',
    value: ImportStockPart.FULL,
  },
  {
    label: 'Partial',
    value: ImportStockPart.PARTIAL,
  },
];
