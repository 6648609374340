import { types, Instance, flow, getEnv, applySnapshot } from '@vklink/libs-state';
import {
  CMoveChangeStatusFilterParams,
  CMoveChangeStatusFilterParamsModel,
  CMoveChangeStatusModel,
  CMoveModel,
  DefaultCMoveChangeStatusFilterParams,
  DefaultCMoveValue,
  LocationOptionsModel,
  WarehouseOptionsModel,
  DefaultChangeStatusValue,
  StatusOptionsModel,
} from './models';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import {
  CMoveChangeStatusType,
  LocationOptionType,
  LocationStatus,
  LocationType,
  StockStatus,
} from 'enums';
import { ChangeStatusModel } from './models/ChangeStatusModel';
import { removeEmptyInObject } from 'pages/shared/utils';
import { StockStatusOptions } from 'constant';

export type CMoveChangeStatusStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CMoveChangeStatusStore = types
  .model('CMove Change Status Store', {
    cmoveChangeStatus: types.array(CMoveChangeStatusModel),
    filterParams: types.optional(
      CMoveChangeStatusFilterParamsModel,
      DefaultCMoveChangeStatusFilterParams
    ),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    cmoveDetail: types.maybe(CMoveModel),
    warehouseOptions: types.array(WarehouseOptionsModel),
    locationOptions: types.array(LocationOptionsModel),
    locationOptionsTofilter: types.array(LocationOptionsModel),
    changeStatusDetail: types.maybe(ChangeStatusModel),
    statusOptions: types.array(StatusOptionsModel),
  })
  .views((self) => {
    return {
      get CMoveChangeStatustList() {
        return self.cmoveChangeStatus;
      },
      get getQueryParams() {
        return {
          ...self.filterParams,
          ...self.paginationParams,
        };
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getLocationOptions() {
        return self.locationOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getStatusOptions() {
        return self.statusOptions;
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<CMoveChangeStatusStoreEnv>(self);

    const setQueryParams = (filterParams: CMoveChangeStatusFilterParams) => {
      applySnapshot(self.filterParams, {
        ...DefaultCMoveChangeStatusFilterParams,
        ...filterParams,
      });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getCMoveChangeStatusAsync = flow(function* () {
      const loadingId = load('Get CMove & change status Async');
      const response = yield http.get('/warehouses/inventories/goods-batches', {
        params: {
          ...self.getQueryParams,
          type: CMoveChangeStatusType.CMOVE_AND_CHANGE_STATUS,
        },
      });
      applySnapshot(self.cmoveChangeStatus, []);
      applySnapshot(self.cmoveChangeStatus, response.data);
      applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses');
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationByWarehouse = flow(function* (warehouseId: string, type: string) {
      const loadingId = load('get Location by Warehouse');
      try {
        const response = yield http.get(
          `/locations?warehouseId=${warehouseId}&status=${LocationStatus.ACTIVE}`
        );
        if (type === LocationOptionType.C_MOVE) {
          response.data = response.data.filter(
            (element: any) =>
              element.id !== self.cmoveDetail?.location.id &&
              ((element.type !== LocationType.ORDERLOCATION &&
                element.type !== LocationType.PICKING) ||
                (element.type === LocationType.PICKING &&
                  element.pickingSku === self.cmoveDetail?.goods.id))
          );
        }

        applySnapshot(self.locationOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getStatuses = flow(function* () {
      const data = StockStatusOptions.filter(
        (element) =>
          element.value != self.changeStatusDetail?.status &&
          element.value != StockStatus.COMMITTED &&
          element.value != StockStatus.SOLD &&
          element.value != StockStatus.DELIVERED &&
          element.value != StockStatus.BOOKING
      );
      applySnapshot(self.statusOptions, data);
    });

    const getGoodsBatchById = flow(function* (id: string, type: string) {
      const loadingId = load('Get Goods Batch By Id');
      const response = yield http.get(`/warehouses/inventories/goods-batches/${id}`);
      if (type == CMoveChangeStatusType.CMOVE) {
        self.cmoveDetail = response.data;
      } else if (type == CMoveChangeStatusType.CHANGE_STATUS) {
        self.changeStatusDetail = response.data;
      }
      loaded(loadingId);
    });

    const saveCMove = flow(function* (
      warehouseId: string,
      cMove?: typeof DefaultCMoveValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save C-Move');
      try {
        const data = removeEmptyInObject(cMove);
        yield http.post(`/warehouses/${warehouseId}/c-move`, data);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const saveChangeStatus = flow(function* (
      warehouseId: string,
      locationId: string,
      changeStatus?: typeof DefaultChangeStatusValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Change Status');
      try {
        const data = removeEmptyInObject(changeStatus);
        yield http.post(`/warehouses/${warehouseId}/locations/${locationId}`, data);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getCMoveChangeStatusAsync,
      setQueryParams,
      setPaginationParams,
      getWarehouseOptionsAsync,
      getLocationByWarehouse,
      getGoodsBatchById,
      getStatuses,
      saveCMove,
      saveChangeStatus,
    };
  });

export default CMoveChangeStatusStore;
export type CMoveChangeStatusStoreInstance = Instance<typeof CMoveChangeStatusStore>;
