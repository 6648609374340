import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderPackageModel = types.model('Order Package Model', {
  orderNumber: types.string,
  packageNumber: types.maybeNull(types.string),
  weight: types.number,
  bolCode: types.maybeNull(types.string),
  trackAndTrace: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

export interface OrderPackage extends SnapshotOut<typeof OrderPackageModel> {}
