import { SnapshotIn, types } from 'mobx-state-tree';

export const ProductListFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface ProductListFilterParams extends SnapshotIn<typeof ProductListFilterParamsModel> {}
export const DefaultProductListFilterParams: ProductListFilterParams = {
  keyword: '',
};
