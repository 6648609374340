const env: Configuration = {
  appPrefix: 'warehouse-admin-web',
  environment: process.env.ENV,
  version: process.env.VERSION,
  baseApiUrl: process.env.BASE_API_URL + '/w/api/v1',
  authConfig: {
    authority: process.env.AUTHORITY,
    clientId: process.env.CLIENT_ID,
    clientUrl: process.env.CLIENT_URL,
    clientSecret: process.env.CLIENT_SECRET,
    redirectUri: `${process.env.CLIENT_URL}/auth/signin-callback`,
    silentRedirectUri: `${process.env.CLIENT_URL}/auth/silent-callback`,
    postLogoutRedirectUri: process.env.CLIENT_URL,
    scopes: [
      'openid',
      'profile',
      'email',
      'offline_access',
      'admin-aggregator.all',
      'identity-api.all',
      'notification-api.all',
      'object-storage-api.all',
      'catalog-api.all',
      'product-api.all',
      'customer-api.all',
      'ordering-api.all',
      'warehouse-api.all',
    ],
    storePrefix: 'eshop-admin-oidc.',
  },
};

export default env;
