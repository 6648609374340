import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ProductInventoryModel = types.model('Product Inventory Model', {
  id: types.identifier,
  warehouse: types.model({
    id: types.string,
    name: types.string,
  }),
  batchNumber: types.string,
  status: types.string,
  goods: types.maybeNull(
    types.model({
      id: types.string,
      code: types.string,
      name: types.string,
    })
  ),
  location: types.model({
    id: types.string,
    code: types.string,
  }),
  stockQuantity: types.number,
});

export interface ProductInventoryInstance extends Instance<typeof ProductInventoryModel> {}
export interface ProductInventoryInputModel extends SnapshotIn<typeof ProductInventoryModel> {}
export interface ProductInventory extends SnapshotOut<typeof ProductInventoryModel> {}
