import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { OrderPackageModel } from './OrderPackageModel';

export const OrderModel = types.model('Order Model', {
  id: types.identifier,
  orderNumber: types.maybeNull(types.string),
  sellDate: types.maybeNull(types.string),
  returnTf: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  receivedDate: types.maybeNull(types.string),
  orderPackages: types.array(OrderPackageModel),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  actionType: types.maybeNull(types.string),
  allowReplacement: types.maybeNull(types.boolean),
  allowReturnFull: types.maybeNull(types.boolean),
  allowReturnPart: types.maybeNull(types.boolean),
});

export const DefaultOrderValue: Partial<OrderInputModel> = {
  orderNumber: '',
  sellDate: '',
  returnTf: '',
};

export interface OrderInstance extends Instance<typeof OrderModel> {}
export interface OrderInputModel extends SnapshotIn<typeof OrderModel> {}
export interface Order extends SnapshotOut<typeof OrderModel> {}
