import { generatePath } from 'react-router';

import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { PurchaseOrderModel } from './models/PurchaseOrderModel';
import {
  DefaultPurchaseOrderFilterParams,
  PurchaseOrderFilterParams,
  PurchaseOrderFilterParamsModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import { WarehouseOptionsModel } from 'pages/location-management/stores/models';
import { PURCHASE_ORDER_API } from 'api/purchase-order';
import { WAREHOUSE_API } from 'api';

export type PurchaseOrderStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const PurchaseOrderStore = types
  .model('PurchaseOrder Store', {
    purchaseOrders: types.array(PurchaseOrderModel),
    purchaseOrderDetail: types.maybe(PurchaseOrderModel),
    filterParams: types.optional(PurchaseOrderFilterParamsModel, DefaultPurchaseOrderFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    warehouseOptions: types.array(WarehouseOptionsModel),
  })
  .views((self) => {
    return {
      get listPurchaseOrders() {
        return self.purchaseOrders;
      },
      get listPurchaseOrderDetails() {
        return self.purchaseOrderDetail?.purchaseOrderItems;
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<PurchaseOrderStoreEnv>(self);

    const setQueryParams = (filterParams: PurchaseOrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultPurchaseOrderFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getPurchaseOrdersAsync = flow(function* () {
      const loadingId = load('Get PurchaseOrder Async');
      const response = yield http.get(PURCHASE_ORDER_API.GET_PURCHASE_ORDER, {
        params: self.getQueryParams,
      });
      applySnapshot(self.purchaseOrders, response.data);
      applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const getPurchaseOrderById = flow(function* (id: string) {
      const loadingId = load('Get PurchaseOrder By Id');
      const response = yield http.get(
        generatePath(PURCHASE_ORDER_API.GET_PURCHASE_ORDER_BY_ID, { id })
      );
      self.purchaseOrderDetail = response.data;
      loaded(loadingId);
    });

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(WAREHOUSE_API.GET_WAREHOUSES);
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getPurchaseOrdersAsync,
      getPurchaseOrderById,
      setQueryParams,
      setPaginationParams,
      getWarehouseOptionsAsync,
    };
  });

export default PurchaseOrderStore;
export type PurchaseOrderStoreInstance = Instance<typeof PurchaseOrderStore>;
