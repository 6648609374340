import { SnapshotOut, types } from '@vklink/libs-state';
import { DeliveryAddressModel } from './DeliveryAddressModel';
import { OrderItemModel } from './OrderItemModel';
import { OrderPackageModel } from './OrderPackageModel';
import { OrderPickupModel } from './OrderPickupModel';

export const OrderDetailModel = types.model('Order Detail Model', {
  id: types.identifier,
  orderNumber: types.string,
  mbid2: types.string,
  customerName: types.maybeNull(types.string),
  orderDate: types.string,
  paymentStatus: types.string,
  orderStatus: types.string,
  receivedDate: types.maybeNull(types.string),
  items: types.array(OrderItemModel),
  orderPackages: types.array(OrderPackageModel),
  orderPickups: types.array(OrderPickupModel),
  deliveryAddress: types.maybe(DeliveryAddressModel),
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  allowReplacement: types.maybeNull(types.boolean),
  allowReturnFull: types.maybeNull(types.boolean),
  allowReturnPart: types.maybeNull(types.boolean),
});

export interface OrderDetail extends SnapshotOut<typeof OrderDetailModel> {}
