import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const PickupGoodsModel = types
  .model('Pickup Goods Model', {
    id: types.identifier,
    barcodeNumber: types.string,
    barcodeNumbers: types.optional(types.array(types.string), []),
    goodsId: types.string,
    goodsCode: types.string,
    goodsName: types.maybeNull(types.string),
    batchNumber: types.maybeNull(types.string),
    locationId: types.string,
    locationName: types.string,
    locationCode: types.maybeNull(types.string),
    locationType: types.maybeNull(types.string),
    quantity: types.number,
    inputQuantity: types.number,
    stockStatus: types.maybeNull(types.string),
    warehouseCode: types.maybeNull(types.string),
    warehouseName: types.string,
    salesItemDetailId: types.maybeNull(types.string),
    goodsSetId: types.maybeNull(types.string),
    goodsSetCode: types.maybeNull(types.string),
    orderPackageId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get getUnique() {
      return {
        goodsId: self.goodsId,
        stockStatus: self.stockStatus,
        batchNumber: self.batchNumber,
        locationId: self.locationId,
      };
    },
  }));

export interface PickupGoodsInstance extends Instance<typeof PickupGoodsModel> {}
export interface PickupGoodsInputModel extends SnapshotIn<typeof PickupGoodsModel> {}
export interface PickupGoods extends SnapshotOut<typeof PickupGoodsModel> {}
