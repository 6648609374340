import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const SelectedLocationModel = types.model('Location Model', {
  id: types.identifier,
  name: types.maybeNull(types.string),
  status: types.string,
});
export interface SelectedLocationInstance extends Instance<typeof SelectedLocationModel> {}
export interface SelectedLocationInputModel extends SnapshotIn<typeof SelectedLocationModel> {}
export interface SelectedLocation extends SnapshotOut<typeof SelectedLocationModel> {}
