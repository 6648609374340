import { SnapshotIn, types } from 'mobx-state-tree';

export const OrderFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
});

export interface OrderFilterParams extends SnapshotIn<typeof OrderFilterParamsModel> {}
export const DefaultOrderFilterParams: OrderFilterParams = {
  keyword: '',
  type: '',
  status: '',
  fromDate: null,
  toDate: null,
};
