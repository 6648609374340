import { SnapshotIn, types } from 'mobx-state-tree';

export const ReturnReplacementReportFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
});

export interface ReturnReplacementReportFilterParams
  extends SnapshotIn<typeof ReturnReplacementReportFilterParamsModel> {}
export const DefaultReturnReplacementReportFilterParams: ReturnReplacementReportFilterParams = {
  keyword: '',
  type: '',
  status: '',
};
