import { createStoreContext } from '@vklink/libs-state';
import { type PrintLabelStoreInstance } from './PrintLabelStore';

const [PrintLabelStoreProvider, usePrintLabelStore] = createStoreContext<PrintLabelStoreInstance>();

export { PrintLabelStoreProvider, usePrintLabelStore };

export {
  default as PrintLabelStore,
  type PrintLabelStoreEnv,
  type PrintLabelStoreInstance,
} from './PrintLabelStore';
