import { AdjustmentStatus } from 'enums';

export const AdjustmentNoteStatusOptions: SelectOption[] = [
  {
    label: 'Init',
    value: AdjustmentStatus.INIT,
  },
  {
    label: 'Approved',
    value: AdjustmentStatus.APPROVED,
  },
  {
    label: 'Cancelled',
    value: AdjustmentStatus.CANCELLED,
  },
  {
    label: 'Rejected',
    value: AdjustmentStatus.REJECTED,
  },
  {
    label: 'Processing',
    value: AdjustmentStatus.IN_PROCESS,
  },
];

export const AdjustmentVoucherStatusOptions: SelectOption[] = [
  {
    label: 'Init',
    value: AdjustmentStatus.INIT,
  },
  {
    label: 'Closed',
    value: AdjustmentStatus.IN_PROCESS,
  },
  {
    label: 'Cancelled',
    value: AdjustmentStatus.CANCELLED,
  },
];
