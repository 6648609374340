import { generatePath } from 'react-router';

import { types, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  WarehouseOptionsModel,
  DepartmentOptionsModel,
  ReasonOptionsModel,
  SkuOptionsModel,
  LocationOptionsModel,
  LocationOptions,
  SkuOptions,
} from 'pages/shared/models/common-options';
import {
  DEPARTMENT_API,
  GOODS_API,
  INTERNAL_ADJUSTMENTS_API,
  REASON_CODES_API,
  WAREHOUSE_API,
} from 'api';
import { onlyUniqueForOptions } from 'pages/shared/utils';
import { ProductModel } from 'pages/product-management/stores/models';
import { SelectFieldModel, SelectField } from 'stores/models';
import { AdjustmentKind, LocationType, ReferenceType, StockStatus } from 'enums';
import { StockStatusOptions } from 'constant';
import {
  AddInternalAdjustmentItems,
  CreateInternalAdjItems,
  DefaultInternalAdjValue,
  InternalAdjItems,
  InternalAdjItemsModel,
  VariableQuantityModel,
} from './models';

export type InternalAdjAddedStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const InternalAdjAddedStore = types
  .model('InternalAdj Store', {
    warehouseOptions: types.array(WarehouseOptionsModel),
    requestDepartmentOptions: types.array(DepartmentOptionsModel),
    reasonCodeOptions: types.array(ReasonOptionsModel),
    skuOptions: types.array(SkuOptionsModel),
    productInformation: types.maybe(ProductModel),
    locationOptions: types.array(LocationOptionsModel),
    batchNumberOptions: types.array(SelectFieldModel),
    statusOptions: types.array(SelectFieldModel),
    variableQuantity: types.maybe(VariableQuantityModel),
    productItems: types.array(InternalAdjItemsModel),
    selectedProduct: types.maybeNull(InternalAdjItemsModel),
    selectingProduct: types.maybeNull(types.boolean),
  })
  .views((self) => {
    return {
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getRequestDepartmentOptions() {
        return self.requestDepartmentOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getReasonCodeOptions() {
        return self.reasonCodeOptions.map((el) => {
          return { value: el.id, label: el.reason };
        });
      },
      get getSkuOptions() {
        const result = self.skuOptions.map((el) => {
          return { value: el.id, label: el.code };
        });
        return onlyUniqueForOptions(result);
      },
      get getLocationOptions() {
        const result = self.locationOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
        return onlyUniqueForOptions(result);
      },
      get getProductInformation() {
        return self.productInformation && getSnapshot(self.productInformation);
      },
      get getBatchNumberOptions() {
        const result = self.batchNumberOptions.map((el) => {
          return { value: el.value, label: el.value };
        });
        return onlyUniqueForOptions(result);
      },
      get getStatusOptions() {
        const result = self.statusOptions.map((el) => {
          return {
            value: el.value,
            label: StockStatusOptions.find((item) => item.value === el.value)?.label,
          };
        });
        return onlyUniqueForOptions(result);
      },
      get getProductItems() {
        return getSnapshot(self.productItems);
      },
      get getSelectingProduct() {
        return self.selectingProduct;
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<InternalAdjAddedStoreEnv>(self);

    const setProductItems = (productItem: AddInternalAdjustmentItems) => {
      applySnapshot(self.productItems, [...self.productItems, productItem]);
    };

    const setDuplicateProduct = (index: number, value: number) => {
      self.productItems[index].quantity = value;
    };

    const resetOptionsWhenEdit = () => {
      if (!self.selectedProduct) return;

      const skuOptions: SkuOptions = {
        id: self.selectedProduct.goodsId,
        code: self.selectedProduct.goodsCode,
      };

      const locationOptions: LocationOptions = {
        id: self.selectedProduct.locationId,
        name: self.selectedProduct.locationName as string,
        code: null,
      };

      const batchNumberOptions: SelectField = {
        value: self.selectedProduct.batchNumber,
        label: null,
      };

      const statusOptions: SelectField = {
        value: self.selectedProduct.status,
        label: null,
      };

      applySnapshot(self.batchNumberOptions, [batchNumberOptions]);
      applySnapshot(self.locationOptions, [locationOptions]);
      applySnapshot(self.statusOptions, [statusOptions]);
      applySnapshot(self.skuOptions, [skuOptions]);
    };

    const deleteProductItems = (rowIndex: number) => {
      self.productItems.splice(rowIndex, 1);
    };

    const resetProductItems = () => {
      applySnapshot(self.productItems, []);
    };

    const setSelectedProduct = (selectedProduct: InternalAdjItems) => {
      self.selectedProduct = selectedProduct as any;
    };

    const setSelectingProduct = (value: boolean) => {
      self.selectingProduct = value;
    };

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(WAREHOUSE_API.GET_WAREHOUSES);
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getDepartmentOptionsAsync = flow(function* () {
      const loadingId = load('Get Deparment Async');
      try {
        const response = yield http.get(DEPARTMENT_API.GET_DEPARTMENTS);
        applySnapshot(self.requestDepartmentOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getReasonCodeOptionsAsync = flow(function* () {
      const loadingId = load('Get Reason Code Async');
      try {
        const response = yield http.get(REASON_CODES_API.GET_REASON_CODES);

        applySnapshot(self.reasonCodeOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getSkuOptionsByWarehouseAsync = flow(function* (warehouseId?: string) {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(
          GOODS_API.GET_PRODUCT_INVENTORIES + `?warehouseId=${warehouseId}`
        );

        applySnapshot(
          self.skuOptions,
          response.data.map((el: any) => {
            return {
              id: el.goods.id,
              code: el.goods.code,
            };
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProductInformationByGoodsIdAsync = flow(function* (goodsId: string) {
      const loadingId = load('Get Product Detail By Goods Id Async');
      try {
        const response = yield http.get(`/goods/${goodsId}`);
        self.productInformation = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationOptionsByGoodsIdAsync = flow(function* (
      adjKind: string,
      warehouseId: string,
      goodsId: string
    ) {
      const loadingId = load('Get Location Async');
      try {
        const response = yield http.get(GOODS_API.GET_PRODUCT_INVENTORIES, {
          params: {
            warehouseId,
            goodsId: adjKind == AdjustmentKind.OUTWARD ? goodsId : '',
          },
        });
        const fetchLocationOptions = response.data
          .map((el: any) => {
            switch (adjKind) {
              case AdjustmentKind.INWARD:
                if (el.location.type != LocationType.PICKING) {
                  console.log(el.location);
                  return el.location;
                } else if (
                  el.location.type == LocationType.PICKING &&
                  el.location.pickingSku == goodsId
                ) {
                  return el.location;
                }
                break;

              default:
                return el.location;
            }
          })
          .filter((x: any) => x);
        applySnapshot(self.locationOptions, fetchLocationOptions);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBatchNumberOptionsAsync = flow(function* (
      goodsId: string,
      warehouseId?: string,
      locationId?: string
    ) {
      const loadingId = load('Get Batch Number Async');
      try {
        const response = yield http.get(GOODS_API.GET_PRODUCT_INVENTORIES, {
          params: {
            goodsId,
            warehouseId,
            locationId,
          },
        });
        applySnapshot(
          self.batchNumberOptions,
          response.data
            .map((el: any) => {
              if (el.batchNumber) {
                return {
                  value: el.batchNumber,
                };
              }
              return;
            })
            .filter((x: any) => x)
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getStatusOptionsAsync = flow(function* (
      warehouseId: string,
      goodsId: string,
      locationId: string,
      batchNumber: string
    ) {
      const loadingId = load('Get Batch Number Async');
      try {
        const response = yield http.get(GOODS_API.GET_PRODUCT_INVENTORIES, {
          params: {
            warehouseId,
            goodsId,
            locationId,
            batchNumber,
            type: ReferenceType.INTERNAL_ADJUSTMENT,
          },
        });
        applySnapshot(
          self.statusOptions,
          response.data
            .map((el: any) => {
              if (
                el.batchNumber &&
                el.status &&
                el.status !== StockStatus.BOOKING &&
                el.status !== StockStatus.COMMITTED &&
                el.status !== StockStatus.SOLD
              ) {
                return {
                  value: el.status,
                };
              }
              return;
            })
            .filter((x: any) => x)
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getStockQuantityAsync = flow(function* (
      warehouseId: string,
      goodsId: string,
      locationId: string,
      batchNumber: string,
      status: string
    ) {
      const loadingId = load('Get Stock Quantity Async');
      try {
        const response = yield http
          .get(GOODS_API.GET_PRODUCT_INVENTORIES, {
            params: {
              warehouseId,
              goodsId,
              locationId,
              batchNumber,
              status,
            },
          })
          .then((res) => res.data[0]);

        self.variableQuantity = response;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createInternalAdjustment = flow(function* (
      internalAdjustment: typeof DefaultInternalAdjValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Get Stock Quantity Async');
      try {
        yield http.post(INTERNAL_ADJUSTMENTS_API.CREATE_INTERNAL_ADJUSTMENTS, {
          ...internalAdjustment,
        });
        cb?.success && cb?.success();
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editInternalAdjustment = flow(function* (
      id: string,
      internalAdjustment: typeof DefaultInternalAdjValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Get Stock Quantity Async');

      try {
        yield http.put(generatePath(INTERNAL_ADJUSTMENTS_API.EDIT_INTERNAL_ADJUSTMENTS, { id }), {
          ...internalAdjustment,
        });
        cb?.success && cb?.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb?.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const checkDuplicateProduct = (productItem: CreateInternalAdjItems) => {
      const unique = {
        adjustmentKind: productItem.adjustmentKind,
        goodsCode: productItem.goodsCode,
        batchNumber: productItem.batchNumber,
        locationId: productItem.locationId,
        status: productItem.status,
      };

      const dataDuplicate = self.productItems.find(
        (el) => JSON.stringify(unique) === JSON.stringify(el.getUnique)
      );

      if (dataDuplicate) {
        if (!self.selectingProduct) {
          dataDuplicate.quantity += productItem.quantity;
        } else {
          self.selectingProduct = false;
        }

        dataDuplicate.reasonId = productItem.reasonId;
        dataDuplicate.reasonName = self.getReasonCodeOptions.find(
          (el) => el.value == productItem.reasonId
        )?.label as string;
        dataDuplicate.departmentId = productItem.departmentId;
        dataDuplicate.departmentName = self.getRequestDepartmentOptions.find(
          (el) => el.value == productItem.departmentId
        )?.label as string;
        dataDuplicate.remark = productItem.remark as string;
        return true;
      }

      return false;
    };

    return {
      setSelectedProduct,
      setProductItems,
      resetOptionsWhenEdit,
      setDuplicateProduct,
      deleteProductItems,
      resetProductItems,
      setSelectingProduct,
      getWarehouseOptionsAsync,
      getDepartmentOptionsAsync,
      getReasonCodeOptionsAsync,
      getSkuOptionsByWarehouseAsync,
      getProductInformationByGoodsIdAsync,
      getLocationOptionsByGoodsIdAsync,
      getBatchNumberOptionsAsync,
      getStatusOptionsAsync,
      getStockQuantityAsync,
      createInternalAdjustment,
      editInternalAdjustment,
      checkDuplicateProduct,
    };
  });

export default InternalAdjAddedStore;
