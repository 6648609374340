import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const LocationModel = types.model('Location Model', {
  id: types.identifier,
  code: types.string,
  name: types.maybeNull(types.string),
  warehouseId: types.string,
  type: types.string,
  createdAt: types.string,
  status: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  width: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  capacity: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  pickingSku: types.maybeNull(types.string),
  isUse: types.maybeNull(types.boolean),
  warehouse: types.model({
    id: types.string,
    name: types.string,
  }),
  goods: types.maybeNull(
    types.model({
      value: types.maybeNull(types.string),
      label: types.maybeNull(types.string),
      id: types.string,
      code: types.string,
    })
  ),
});

export const DefaultLocationValue: Partial<LocationInputModel> = {
  code: '',
  name: '',
  type: '',
  width: null,
  height: null,
  capacity: null,
  description: '',
  warehouseId: '',
  pickingSku: null,
};

export interface LocationInstance extends Instance<typeof LocationModel> {}
export interface LocationInputModel extends SnapshotIn<typeof LocationModel> {}
export interface Location extends SnapshotOut<typeof LocationModel> {}
