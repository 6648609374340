import { types, Instance, getEnv, flow, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { DashboardModel } from './models';
import { PURCHASE_ORDER_API } from 'api';
import { PurchaseOrderModel } from 'pages/po-management/stores/models';

export type DashboardStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const DashboardStore = types
  .model('Dashboard Store', {
    dashboards: types.array(DashboardModel),
    purchaseOrders: types.array(PurchaseOrderModel),
  })
  .views((self) => {
    return {
      get listPurchaseOrders() {
        return self.purchaseOrders ? getSnapshot(self.purchaseOrders) : [];
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<DashboardStoreEnv>(self);

    const getPurchaseOrdersAsync = flow(function* () {
      const loadingId = load('Get PurchaseOrder Async');
      const response = yield http.get(PURCHASE_ORDER_API.GET_PURCHASE_ORDER, {
        params: {
          page: 1,
          limit: 10,
        },
      });
      applySnapshot(self.purchaseOrders, response.data);
      loaded(loadingId);
    });

    return { getPurchaseOrdersAsync };
  });

export default DashboardStore;
export type DashboardStoreInstance = Instance<typeof DashboardStore>;
