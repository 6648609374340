import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useRootStore } from 'stores';
import { createStore, getEnv } from '@vklink/libs-state';

import {
  PurchaseOrderStore,
  PurchaseOrderStoreInstance,
  PurchaseOrderStoreProvider,
} from './stores';
import { PageLayout } from 'pages/shared/layout';

const PurchaseOrdersLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<PurchaseOrderStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(PurchaseOrderStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);
  return (
    <PurchaseOrderStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </PurchaseOrderStoreProvider>
  );
};

export default PurchaseOrdersLayout;
