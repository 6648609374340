import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { CategoryModel, DefaultCategoryValue } from './models/CategoryModel';
import { HttpInstance } from '@vklink/libs-http';

import {
  PaginationParamsModel,
  DefaultPaginationParams,
  PaginationModel,
  DefaultPaginationInfo,
} from 'pages/shared/models/pagination';
import { CategoryFilterParamsModel, DefaultCategoryFilterParams } from './models';

import dataDemo from '../../../../../../admin-db.json';

export type CategoryStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CategoryStore = types
  .model('Category Store', {
    categoryDetail: types.maybe(CategoryModel),
    items: types.optional(types.array(CategoryModel), []),
    filterParams: types.optional(CategoryFilterParamsModel, DefaultCategoryFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get itemList() {
        return getSnapshot(self.items);
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<CategoryStoreEnv>(self);

    const getCategoriesAsync = flow(function* () {
      const loadingId = load('Get Category Async');
      applySnapshot(self.items, dataDemo.categories);

      console.log(dataDemo.categories);

      loaded(loadingId);
    });

    const createCategory = function (category: typeof DefaultCategoryValue) {
      const loadingId = load('Create Category');
      http.post('/categories', { ...category, id: `${Math.random()}` });
      loaded(loadingId);
    };

    const editCategory = function (category: typeof DefaultCategoryValue, id: number) {
      const loadingId = load('Edit Category');
      http.put(`/categories/${id}`, { ...category, id });
      loaded(loadingId);
    };

    const getCategoryById = flow(function* (id: string) {
      const loadingId = load('Get Category By Id');
      const response = yield http.get(`/categories/${id}`);

      self.categoryDetail = response.data;

      loaded(loadingId);
    });

    return { getCategoriesAsync, createCategory, editCategory, getCategoryById };
  });

export default CategoryStore;
export type CategoryStoreInstance = Instance<typeof CategoryStore>;
