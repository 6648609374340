import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const OrderPackagesModel = types.model('Order Packages Model', {
  id: types.identifier,
  packageNumber: types.string,
  bolCode: types.maybeNull(types.string),
  weight: types.number,
});

export interface OrderPackagesInstance extends Instance<typeof OrderPackagesModel> {}
export interface OrderPackagesInputModel extends SnapshotIn<typeof OrderPackagesModel> {}
export interface OrderPackages extends SnapshotOut<typeof OrderPackagesModel> {}
