import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';

export const DeliveryAddressModel = types.model('Delivery Address Model', {
  id: types.identifier,
  name1: types.maybeNull(types.string),
  tel1: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  wardName: types.maybeNull(types.string),
  districtName: types.maybeNull(types.string),
  provinceName: types.maybeNull(types.string),
});

export interface DeliveryAddressInstance extends Instance<typeof DeliveryAddressModel> {}
export interface DeliveryAddressModel extends SnapshotIn<typeof DeliveryAddressModel> {}
export interface DeliveryAddress extends SnapshotOut<typeof DeliveryAddressModel> {}
