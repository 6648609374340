import { LabelMethod, PrintLabelType } from 'enums';

export const LabelMethodOptions: SelectOption[] = [
  {
    label: 'PO',
    value: LabelMethod.PO,
  },
  {
    label: 'TRN',
    value: LabelMethod.TRN,
  },
  {
    label: 'SKU',
    value: LabelMethod.SKU,
  },
  {
    label: 'Barcode',
    value: LabelMethod.BARCODE,
  },
];

export const PrintLabelTypeOptions: SelectOption[] = [
  {
    label: 'PO98',
    value: PrintLabelType.PO,
  },
];
