import { StockStatus } from 'enums';

export const StockStatusOptions: SelectOption[] = [
  { label: 'Available', value: StockStatus.AVAILABLE },
  { label: 'Booking', value: StockStatus.BOOKING },
  { label: 'Commited', value: StockStatus.COMMITTED },
  { label: 'Sold', value: StockStatus.SOLD },
  { label: 'Return', value: StockStatus.RETURN },
  { label: 'Internal', value: StockStatus.INTERNAL },
  { label: 'Unavailable', value: StockStatus.UNAVAILABLE },
  { label: 'Quarantine', value: StockStatus.QUARANTINE },
  { label: 'Damaged', value: StockStatus.DAMAGED },
  { label: 'Deliverd', value: StockStatus.DELIVERED },
];
