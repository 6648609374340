import { createStoreContext } from '@vklink/libs-state';
import { type ExportStockStoreInstance } from './ExportStockStore';

const [ExportStockStoreProvider, useExportStockStore] =
  createStoreContext<ExportStockStoreInstance>();

export { ExportStockStoreProvider, useExportStockStore };

export {
  default as ExportStockStore,
  type ExportStockStoreEnv,
  type ExportStockStoreInstance,
} from './ExportStockStore';
