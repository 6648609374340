export enum LocationType {
  PICKING = 'PICKING',
  HOLDING = 'HOLDING',
  NTBU = 'NTBU',
  ORDERLOCATION = 'ORDERLOCATION',
  DOCK = 'DOCK',
}
export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
