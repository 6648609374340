import { types, Instance, flow, getEnv, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { InternalAdjModel } from './models/InternalAdjModel';
import {
  AddInternalAdjustmentItems,
  DefaultInternalAdjFilterParams,
  InternalAdjDetailModel,
  InternalAdjFilterParams,
  InternalAdjFilterParamsModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { INTERNAL_ADJUSTMENTS_API } from 'api';
import InternalAdjAddedStore from './InternalAdjAddedStore';
import { generatePath } from 'react-router';
import { removeEmptyInObject } from 'pages/shared/utils';
import { AdjustmentStatus, InfoMsg } from 'enums';

export type InternalAdjStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const InternalAdjStore = types
  .compose(
    InternalAdjAddedStore,
    types.model('InternalAdj Store', {
      internalAdjs: types.array(InternalAdjModel),
      filterParams: types.optional(InternalAdjFilterParamsModel, DefaultInternalAdjFilterParams),
      paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
      pagination: types.optional(PaginationModel, DefaultPaginationInfo),
      internalAdjDetail: types.maybe(InternalAdjDetailModel),
      confirmMessage: types.maybeNull(types.string),
      flag: types.maybeNull(types.string),
    })
  )

  .views((self) => {
    return {
      get listInternalAdj() {
        return getSnapshot(self.internalAdjs);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getInternalAdjDetail() {
        return self.internalAdjDetail;
      },
      get isApproved() {
        return self.internalAdjDetail?.status === AdjustmentStatus.APPROVED;
      },
      get getAdjustmentVoucherItems() {
        return self.internalAdjDetail?.adjustmentVoucherItems
          ? getSnapshot(self.internalAdjDetail?.adjustmentVoucherItems)
          : [];
      },
      get getConfirmMessage() {
        return self.confirmMessage;
      },
      get getFlag() {
        return self.flag;
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<InternalAdjStoreEnv>(self);

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setQueryParams = (filterParams: InternalAdjFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultInternalAdjFilterParams, ...filterParams });
    };

    const setDefaultProductItems = (productItems: Array<AddInternalAdjustmentItems>) => {
      applySnapshot(self.productItems, productItems);
    };

    const getInternalAdjsAsync = flow(function* () {
      const loadingId = load('Get Internal Adjustment Async');
      try {
        const response = yield http.get(INTERNAL_ADJUSTMENTS_API.GET_LIST_INTERNAL_ADJUSTMENTS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.internalAdjs, []);
        applySnapshot(self.internalAdjs, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getInternalAdjByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Internal Adjustment By Id');
      const response = yield http.get(
        generatePath(INTERNAL_ADJUSTMENTS_API.GET_INTERNAL_ADJUSTMENT_BY_ID, { id: id })
      );
      self.internalAdjDetail = response.data;

      setDefaultProductItems(response.data.adjustmentVoucherItems);
      loaded(loadingId);
    });

    const updateStatusInternalAdjAsync = flow(function* (
      adjustmentId: string,
      status: string,
      reason?: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Status Internal Adjustment');
      try {
        const url = generatePath(INTERNAL_ADJUSTMENTS_API.UPDATE_STATUS_INTERNAL_ADJUSTMENT, {
          id: adjustmentId,
        });

        const reqBody: any = {
          adjustmentId,
          status,
        };

        if (reason) reqBody.reason = reason;
        yield http.put(url, reqBody);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const printBarcodeAsync = flow(function* (id: string, itemId: string) {
      const loadingId = load('Print Barcode');
      const response = yield http.get(
        generatePath(INTERNAL_ADJUSTMENTS_API.PRINT_BARCODE, { id: id, itemId: itemId })
      );
      const res = (response.data as []).sort((a: any, b: any) => a.sequence - b.sequence) as any[];
      const lastSerial = res[res.length - 1].barcode;
      self.internalAdjDetail?.adjustmentVoucherItems.forEach((el) => {
        if (el.id === itemId) {
          el.lastSerial = lastSerial;
        }
      });
      loaded(loadingId);
    });

    const setConfirmMessage = (type: string, adjCode: string) => {
      let msg = '';
      if (type == AdjustmentStatus.IN_PROCESS) {
        msg = InfoMsg.CONFIRM_INFO.replace('{$1}', 'confirm').replace('{$2}', adjCode);
      } else {
        msg = InfoMsg.CONFIRM_INFO.replace('{$1}', type.toLowerCase()).replace('{$2}', adjCode);
      }

      self.confirmMessage = msg;
    };

    const setConfirmRemainingQtyMessage = (goodsCode: string, quantity: string) => {
      const msg = InfoMsg.CONFIRM_ADJ_REMAINING_QTY.replace('{$1}', goodsCode).replace(
        '{$2}',
        quantity
      );

      self.confirmMessage = msg;
    };

    const setGoodsCodeInvalidMessage = (goodsCodes: string[]) => {
      const msg = InfoMsg.GOODS_CODE_OUT_OF_STOCK_QTY.replace('{$1}', goodsCodes.join(', '));

      self.confirmMessage = msg;
    };

    const setFlag = (type: string) => {
      self.flag = type;
    };

    const checkStockAsync = flow(function* (id: string) {
      const loadingId = load('Check Stock');
      const response = yield http.get(
        generatePath(INTERNAL_ADJUSTMENTS_API.CHECK_STOCK, { id: id })
      );
      loaded(loadingId);
      return response.data;
    });

    return {
      setPaginationParams,
      setQueryParams,
      setDefaultProductItems,
      getInternalAdjsAsync,
      getInternalAdjByIdAsync,
      updateStatusInternalAdjAsync,
      printBarcodeAsync,
      setConfirmMessage,
      setFlag,
      setConfirmRemainingQtyMessage,
      setGoodsCodeInvalidMessage,
      checkStockAsync,
    };
  });

export default InternalAdjStore;
export type InternalAdjStoreInstance = Instance<typeof InternalAdjStore>;
