import { SnapshotIn, types } from 'mobx-state-tree';

export const LocationFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  warehouseId: types.optional(types.string, ''),
  pickingSku: types.optional(types.string, ''),
});

export interface LocationFilterParams extends SnapshotIn<typeof LocationFilterParamsModel> {}
export const DefaultLocationFilterParams: LocationFilterParams = {
  keyword: '',
  type: '',
  status: '',
  warehouseId: '',
  pickingSku: '',
};
