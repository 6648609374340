import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { LocationOptionsModel } from 'pages/shared/models/common-options';

export const TransferNoteDetailModel = types
  .model('TransferNote Model', {
    goodsCode: types.string,
    goodsName: types.string,
    batchNumber: types.string,
    inputQuantity: types.number,
    exportStockLocationId: types.string,
    status: types.string,
    exportLocation: types.maybeNull(LocationOptionsModel),
    exportStockLocationName: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    realStockQuantity: types.maybeNull(types.number),
    totalBCSQuantity: types.maybeNull(types.string),
    stockQuantity: types.maybeNull(types.number),
  })
  .views((self) => ({
    get getUnique() {
      return {
        goodsCode: self.goodsCode,
        batchNumber: self.batchNumber,
        exportStockLocationId: self.exportStockLocationId,
        status: self.status,
      };
    },
  }));

export const DefaultTransferNoteItemValue: Partial<TransferNoteDetailInputModel> = {
  goodsCode: '',
  goodsName: '',
  batchNumber: '',
  inputQuantity: 0,
  exportStockLocationId: '',
  exportStockLocationName: '',
  status: '',
  id: '',
  realStockQuantity: 0,
  totalBCSQuantity: '',
  stockQuantity: 0,
};

export interface TransferNoteDetailInstance extends Instance<typeof TransferNoteDetailModel> {}
export interface TransferNoteDetailInputModel extends SnapshotIn<typeof TransferNoteDetailModel> {}
export interface TransferNoteDetail extends SnapshotOut<typeof TransferNoteDetailModel> {}
