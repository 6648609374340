import { LocationStatus, LocationType } from 'enums';
export const LocationTypeOptions: SelectOption[] = [
  {
    label: 'Picking',
    value: LocationType.PICKING,
  },
  {
    label: 'Holding',
    value: LocationType.HOLDING,
  },
  {
    label: 'NTBU',
    value: LocationType.NTBU,
  },
  {
    label: 'Order Location',
    value: LocationType.ORDERLOCATION,
  },
  {
    label: 'Dock',
    value: LocationType.DOCK,
  },
];

export const LocationStatusOptions: SelectOption[] = [
  {
    label: 'Active',
    value: LocationStatus.ACTIVE,
  },
  {
    label: 'Inactive',
    value: LocationStatus.INACTIVE,
  },
];
