import '@fontsource/roboto';

import { StrictMode, Suspense, useEffect, useMemo } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import { AuthStoreProvider, createAuthStore } from '@vklink/libs-auth';
import { I18nProvider } from '@vklink/libs-i18n';
import { ErrorBoundary, ThemeProvider, TopLoading } from '@vklink/components-shared';

import i18n from 'i18n';
import { RootStoreProvider, initialRootState } from 'stores';

import theme from './themes';
import Routes from 'pages/shared/Routes';
import env from './env';
import { ToastMessageProvider } from '@vklink/components-toast-message';
import { getEnv } from '@vklink/libs-state';
import {
  applyBearerTokenInterceptor,
  applyCustomHeaderInterceptor,
  applyRefreshBearerTokenInterceptor,
  applyTransformResponseInterceptor,
} from '@vklink/libs-http';

const App = () => {
  const initAuthState = useMemo(
    () =>
      createAuthStore({
        authority: env.authConfig.authority,
        clientId: env.authConfig.clientId,
        clientSecret: env.authConfig.clientSecret,
        redirectUrl: env.authConfig.redirectUri,
        silentRedirectUrl: env.authConfig.silentRedirectUri,
        postLogoutUrl: env.authConfig.postLogoutRedirectUri,
        scopes: env.authConfig.scopes,
        storePrefix: env.authConfig.storePrefix,
      }),
    []
  );
  useEffect(() => {
    const { http } = getEnv(initialRootState);

    applyCustomHeaderInterceptor(http, async () =>
      Promise.resolve({
        'Content-Type': 'application/json',
        'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Accept-Language': Intl.DateTimeFormat().resolvedOptions().locale,
      })
    );

    applyTransformResponseInterceptor(http);

    applyBearerTokenInterceptor(http, async () => Promise.resolve(initAuthState.accessToken));

    applyRefreshBearerTokenInterceptor(http, async () => {
      await initAuthState.signinSilentAsync();

      return Promise.resolve(initAuthState.accessToken);
    });
  }, [initAuthState]);

  return (
    <StrictMode>
      <AuthStoreProvider value={initAuthState}>
        <I18nProvider i18n={i18n}>
          <RootStoreProvider value={initialRootState}>
            <ThemeProvider theme={theme}>
              <ToastMessageProvider>
                <StyledEngineProvider injectFirst>
                  <CssBaseline />
                  <ErrorBoundary>
                    <Suspense fallback={<TopLoading />}>
                      <Router>
                        <Routes />
                      </Router>
                    </Suspense>
                  </ErrorBoundary>
                </StyledEngineProvider>
              </ToastMessageProvider>
            </ThemeProvider>
          </RootStoreProvider>
        </I18nProvider>
      </AuthStoreProvider>
    </StrictMode>
  );
};

export default App;
