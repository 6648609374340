import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const InventoryListModel = types.model({
  goodsId: types.string,
  goodsName: types.string,
  goodsCode: types.string,
  initQty: types.optional(types.maybeNull(types.string), '0'),
  importQty: types.optional(types.maybeNull(types.string), '0'),
  exportQty: types.optional(types.maybeNull(types.string), '0'),
  finalQty: types.optional(types.maybeNull(types.string), '0'),
  statusIT: types.optional(types.maybeNull(types.string), '0'),
  statusA: types.optional(types.maybeNull(types.string), '0'),
  statusB: types.optional(types.maybeNull(types.string), '0'),
  statusC: types.optional(types.maybeNull(types.string), '0'),
  statusS: types.optional(types.maybeNull(types.string), '0'),
  statusQ: types.optional(types.maybeNull(types.string), '0'),
  statusR: types.optional(types.maybeNull(types.string), '0'),
  statusI: types.optional(types.maybeNull(types.string), '0'),
  statusU: types.optional(types.maybeNull(types.string), '0'),
});

export interface InventoryListInstance extends Instance<typeof InventoryListModel> {}
export interface InventoryListInputModel extends SnapshotIn<typeof InventoryListModel> {}
export interface InventoryList extends SnapshotOut<typeof InventoryListModel> {}
