import { SnapshotIn, types } from 'mobx-state-tree';

export const RefundOrderFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  fromDate: types.optional(types.string, ''),
  toDate: types.optional(types.string, ''),
});

export interface RefundOrderFilterParams extends SnapshotIn<typeof RefundOrderFilterParamsModel> {}
export const DefaultRefundOrderFilterParams: RefundOrderFilterParams = {
  keyword: '',
  fromDate: '',
  toDate: '',
};
