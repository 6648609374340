import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';
import { StockStatus } from 'enums';

export const CMoveChangeStatusModel = types
  .model('Department Model', {
    id: types.identifier,
    batchNumber: types.maybeNull(types.string),
    status: types.enumeration(Object.values(StockStatus)),
    stockQuantity: types.integer,
    goods: types.model({
      code: types.string,
      name: types.string,
    }),
    location: types.model({
      code: types.string,
      name: types.string,
    }),
    warehouse: types.model({
      code: types.string,
      name: types.string,
    }),
  })
  .views((self) => ({
    get isDisableCMove() {
      return (
        !self.batchNumber ||
        self.status == StockStatus.BOOKING ||
        self.status == StockStatus.COMMITTED ||
        self.status == StockStatus.SOLD
      );
    },
  }));

export const DefaultCMoveChangeStatusValue: Partial<CMoveChangeStatusInputModel> = {
  status: StockStatus.OTHER,
  batchNumber: '',
  stockQuantity: 0,
  goods: {
    code: '',
    name: '',
  },
  location: {
    code: '',
    name: '',
  },
  warehouse: {
    code: '',
    name: '',
  },
};

export interface CMoveChangeStatusInstance extends Instance<typeof CMoveChangeStatusModel> {}
export interface CMoveChangeStatusInputModel extends SnapshotIn<typeof CMoveChangeStatusModel> {}
export interface CMoveChangeStatus extends SnapshotOut<typeof CMoveChangeStatusModel> {}
