import { RouteObject, useRoutes } from 'react-router-dom';

import { observer } from '@vklink/libs-state';
import { lazyLoad } from '@vklink/components-shared';
import { SigninCallbackPage, SilentCallbackPage, useAuthStore } from '@vklink/libs-auth';

import {
  LocationRoutes,
  PurchaseOrderRoutes,
  WarehouseRoutes,
  Category,
  ProductRoutes,
  PrintLabel,
  ImportStockRoutes,
  ExportStockRoutes,
  TransferNoteRoutes,
  OrderRoutes,
  Report,
  PickupGoods,
  ReturnOrderRoutes,
  ReplaceOrderRoutes,
  RefundOrderRoutes,
  CMoveChangeStatusRoutes,
  InternalADJRoutes,
} from './models';
import WelcomePage from 'pages/shared/WelcomePage';
import MasterLayout from 'pages/shared/MasterLayout';
import NotFoundPage from 'pages/shared/NotFoundPage';
import CategoryLayout from 'pages/category/CategoryLayout';
import WarehouseLayout from 'pages/warehouse-management/WarehouseLayout';
import LocationLayout from 'pages/location-management/LocationLayout';
import PurchaseOrdersLayout from 'pages/po-management/PurchaseOrdersLayout';
import ProductLayout from 'pages/product-management/ProductLayout';
import ImportStockLayout from 'pages/import-stock/ImportStockLayout';
import ExportStockLayout from 'pages/export-stock/ExportStockLayout';
import PrintLabelLayout from 'pages/print-label/PrintLabelLayout';
import DashboardLayout from 'pages/dashboard/DashboardLayout';
import TransferNoteLayout from 'pages/transfer-note/TransferNoteLayout';
import OrderLayout from 'pages/order-management/OrderLayout';
import ReportLayout from 'pages/report-management/Layout';
import PickupGoodsLayout from 'pages/pickup-goods/PickupGoodsLayout';
import CMoveChangeStatusLayout from 'pages/c-move-and-change-status/CMoveChangeStatusLayout';
import ReturnOrderLayout from 'pages/return-order/ReturnOrderLayout';
import InternalAdjLayout from 'pages/internal-adj-management/InternalAdjLayout';
import ReplaceOrderLayout from 'pages/replace-order/ReplaceOrderLayout';
import { StockTakeVoucherRoutes } from './models/routes/StockTakeVoucher';
import StockTakeVoucherLayout from 'pages/stock-take-voucher-management/StockTakeVoucherLayout';
import RefundOrderLayout from 'pages/refund-order/RefundOrderLayout';
import { DepartmentRoutes } from './models/routes/Department';
import DepartmentLayout from 'pages/department-management/DepartmentLayout';
import { ReasonCodeRoutes } from './models/routes/ReasonCode';
import ReasonCodeLayout from 'pages/reason-code-management/ReasonCodeLayout';

const WarehouseListPage = lazyLoad(() => import('pages/warehouse-management/WarehouseListPage'));
const WarehouseAddedPage = lazyLoad(() => import('pages/warehouse-management/WarehouseAddedPage'));
const WarehouseDetailsPage = lazyLoad(
  () => import('pages/warehouse-management/WarehouseDetailsPage')
);

const CategoryListPage = lazyLoad(() => import('pages/category/CategoryListPage'));
const CategoryAddedPage = lazyLoad(() => import('pages/category/CategoryAddedPage'));
const CategoryDetailsPage = lazyLoad(() => import('pages/category/CategoryDetailsPage'));

const LocationListPage = lazyLoad(() => import('pages/location-management/LocationListPage'));
const LocationDetailsPage = lazyLoad(() => import('pages/location-management/LocationDetailsPage'));
const LocationAddedPage = lazyLoad(() => import('pages/location-management/LocationAddedPage'));

const ProductListPage = lazyLoad(() => import('pages/product-management/ProductListPage'));
const ProductAddedPage = lazyLoad(() => import('pages/product-management/ProductAddedPage'));
const ProductDetailsPage = lazyLoad(() => import('pages/product-management/ProductDetailsPage'));

const DashboardListPage = lazyLoad(() => import('pages/dashboard/DashboardListPage'));

const PurchaseOrdersManagementPage = lazyLoad(
  () => import('pages/po-management/PurchaseOrdersListPage')
);
const PurchaseOrdersDetailsPage = lazyLoad(
  () => import('pages/po-management/PurchaseOrdersDetailsPage')
);

const ImportStockListPage = lazyLoad(() => import('pages/import-stock/ImportStockListPage'));
const ImportStockDetailsPage = lazyLoad(() => import('pages/import-stock/ImportStockDetailsPage'));
const ImportStocksPage = lazyLoad(() => import('pages/import-stock/ImportStockPage'));

const ExportStockListPage = lazyLoad(() => import('pages/export-stock/ExportStockListPage'));

const PrintLabelPage = lazyLoad(() => import('pages/print-label/PrintLabelPage'));

const TransferNoteListPage = lazyLoad(() => import('pages/transfer-note/TransferNoteListPage'));
const TransferNoteAddedPage = lazyLoad(() => import('pages/transfer-note/TransferNoteAddedPage'));
const TransferNoteDetailsPage = lazyLoad(
  () => import('pages/transfer-note/TransferNoteDetailsPage')
);

const DepartmentListPage = lazyLoad(() => import('pages/department-management/DepartmentListPage'));
const DepartmentAddedPage = lazyLoad(
  () => import('pages/department-management/DepartmentAddedPage')
);
const DepartmentDetailsPage = lazyLoad(
  () => import('pages/department-management/DepartmentDetailsPage')
);

const ReasonCodeListPage = lazyLoad(
  () => import('pages/reason-code-management/ReasonCodeListPage')
);
const ReasonCodeAddedPage = lazyLoad(
  () => import('pages/reason-code-management/ReasonCodeAddedPage')
);
const ReasonCodeDetailsPage = lazyLoad(
  () => import('pages/reason-code-management/ReasonCodeDetailsPage')
);

const InternalAdjListPage = lazyLoad(
  () => import('pages/internal-adj-management/InternalAdjListPage')
);
const InternalAdjAddedPage = lazyLoad(
  () => import('pages/internal-adj-management/InternalAdjAddedPage')
);
const InternalAdjDetailsPage = lazyLoad(
  () => import('pages/internal-adj-management/InternalAdjDetailPage')
);

const StockTakeVoucherListPage = lazyLoad(
  () => import('pages/stock-take-voucher-management/StockTakeVoucherListPage')
);
const StockTakeVoucherAddedPage = lazyLoad(
  () => import('pages/stock-take-voucher-management/StockTakeVoucherAddedPage')
);
const StockTakeVoucherDetailsPage = lazyLoad(
  () => import('pages/stock-take-voucher-management/StockTakeVoucherDetailsPage')
);
const StockTakeVoucherEnterActualDataPage = lazyLoad(
  () => import('pages/stock-take-voucher-management/StockTakeVoucherEnterActualDataPage')
);
const StockTakeAdjustmentDetailsPage = lazyLoad(
  () => import('pages/stock-take-voucher-management/StockTakeAdjustmentDetailsPage')
);

const CMoveChangeStatusListPage = lazyLoad(
  () => import('pages/c-move-and-change-status/CMoveChangeStatusListPage')
);
const CMoveDetailsPage = lazyLoad(() => import('pages/c-move-and-change-status/CMoveDetailsPage'));
const ChangeStatusDetailsPage = lazyLoad(
  () => import('pages/c-move-and-change-status/ChangeStatusDetailsPage')
);

const OrderListPage = lazyLoad(() => import('pages/order-management/OrderListPage'));
const OrderAddedPage = lazyLoad(() => import('pages/order-management/OrderAddedPage'));
const OrderDetailsPage = lazyLoad(() => import('pages/order-management/OrderDetailsPage'));

const PickupGoodsPage = lazyLoad(() => import('pages/pickup-goods/PickupGoodsPage'));

const ImportStockReportPage = lazyLoad(() => import('pages/report-management/ImportStockListPage'));
const ExportStockReportPage = lazyLoad(() => import('pages/report-management/ExportStockListPage'));
const OrderStatusListPage = lazyLoad(() => import('pages/report-management/OrderStatusListPage'));
const ReturnReplacementListPage = lazyLoad(
  () => import('pages/report-management/ReturnReplacementListPage')
);
const SkuListPage = lazyLoad(() => import('pages/report-management/SkuListPage'));
const StockTakeListPage = lazyLoad(() => import('pages/report-management/StockTakeListPage'));
const InventoryListPage = lazyLoad(() => import('pages/report-management/InventoryListPage'));
const InventoryDetailPage = lazyLoad(() => import('pages/report-management/InventoryDetailPage'));
const CMoveListPage = lazyLoad(() => import('pages/report-management/CMoveListPage'));
const ChangeStatusListPage = lazyLoad(() => import('pages/report-management/ChangeStatusListPage'));

const ReturnOrderListPage = lazyLoad(() => import('pages/return-order/ReturnOrderListPage'));
const ReturnOrderDetailsPage = lazyLoad(() => import('pages/return-order/ReturnOrderDetailsPage'));
const ReturnFullOrderDetailsPage = lazyLoad(
  () => import('pages/return-order/ReturnFullOrderDetailsPage')
);
const ReturnPartialDetailsPage = lazyLoad(
  () => import('pages/return-order/ReturnPartialDetailsPage')
);

const ReplaceOrderListPage = lazyLoad(() => import('pages/replace-order/ReplaceOrderListPage'));
const ReplaceOrderDetailsPage = lazyLoad(
  () => import('pages/replace-order/ReplaceOrderDetailsPage')
);

const RefundOrderListPage = lazyLoad(() => import('pages/refund-order/RefundOrderListPage'));
const RefundOrderDetailsPage = lazyLoad(() => import('pages/refund-order/RefundOrderDetailsPage'));

const ROUTE_PATHS = {
  root: '/',
  notFound: '*',
};

const getRoutes = (): RouteObject[] => [
  {
    path: ROUTE_PATHS.root,
    element: <MasterLayout />,
    children: [
      {
        path: ROUTE_PATHS.root,
        element: <DashboardLayout />,
        children: [{ index: true, element: <DashboardListPage /> }],
      },
      {
        path: WarehouseRoutes.LIST,
        element: <WarehouseLayout />,
        children: [
          {
            index: true,
            element: <WarehouseListPage />,
          },
          {
            path: WarehouseRoutes.CREATE,
            element: <WarehouseAddedPage />,
          },
          {
            path: WarehouseRoutes.EDIT,
            element: <WarehouseAddedPage />,
          },
          {
            path: WarehouseRoutes.DETAIL,
            element: <WarehouseDetailsPage />,
          },
        ],
      },
      {
        path: LocationRoutes.LIST,
        element: <LocationLayout />,
        children: [
          {
            index: true,
            element: <LocationListPage />,
          },
          {
            path: LocationRoutes.DETAIL,
            element: <LocationDetailsPage />,
          },
          {
            path: LocationRoutes.CREATE,
            element: <LocationAddedPage />,
          },
          {
            path: LocationRoutes.EDIT,
            element: <LocationAddedPage />,
          },
        ],
      },
      {
        path: PurchaseOrderRoutes.LIST,
        element: <PurchaseOrdersLayout />,
        children: [
          {
            index: true,
            element: <PurchaseOrdersManagementPage />,
          },
          {
            path: PurchaseOrderRoutes.DETAIL,
            element: <PurchaseOrdersDetailsPage />,
          },
        ],
      },
      {
        path: Category.LIST,
        element: <CategoryLayout />,
        children: [
          {
            index: true,
            element: <CategoryListPage />,
          },
          {
            path: Category.CREATE,
            element: <CategoryAddedPage />,
          },
          {
            path: Category.EDIT,
            element: <CategoryAddedPage />,
          },
          {
            path: Category.DETAIL,
            element: <CategoryDetailsPage />,
          },
        ],
      },
      {
        path: ProductRoutes.LIST,
        element: <ProductLayout />,
        children: [
          {
            index: true,
            element: <ProductListPage />,
          },
          {
            path: ProductRoutes.CREATE,
            element: <ProductAddedPage />,
          },
          {
            path: ProductRoutes.EDIT,
            element: <ProductAddedPage />,
          },
          {
            path: ProductRoutes.DETAIL,
            element: <ProductDetailsPage />,
          },
        ],
      },
      {
        path: PrintLabel.LIST,
        element: <PrintLabelLayout />,
        children: [
          {
            index: true,
            element: <PrintLabelPage />,
          },
        ],
      },
      {
        path: ImportStockRoutes.LIST,
        element: <ImportStockLayout />,
        children: [
          {
            index: true,
            element: <ImportStockListPage />,
          },
          {
            path: ImportStockRoutes.DETAIL,
            element: <ImportStockDetailsPage />,
          },
          {
            path: ImportStockRoutes.IMPORT,
            element: <ImportStocksPage />,
          },
        ],
      },
      {
        path: ExportStockRoutes.LIST,
        element: <ExportStockLayout />,
        children: [
          {
            index: true,
            element: <ExportStockListPage />,
          },
        ],
      },
      {
        path: TransferNoteRoutes.LIST,
        element: <TransferNoteLayout />,
        children: [
          {
            index: true,
            element: <TransferNoteListPage />,
          },
          {
            path: TransferNoteRoutes.VIEW,
            element: <TransferNoteDetailsPage />,
          },
          {
            path: TransferNoteRoutes.CREATE,
            element: <TransferNoteAddedPage />,
          },
          {
            path: TransferNoteRoutes.EDIT,
            element: <TransferNoteAddedPage />,
          },
        ],
      },
      {
        path: CMoveChangeStatusRoutes.LIST,
        element: <CMoveChangeStatusLayout />,
        children: [
          {
            index: true,
            element: <CMoveChangeStatusListPage />,
          },
          {
            path: CMoveChangeStatusRoutes.CMOVE_DETAIL,
            element: <CMoveDetailsPage />,
          },
          {
            path: CMoveChangeStatusRoutes.CHANGE_STATUS_DETAIL,
            element: <ChangeStatusDetailsPage />,
          },
        ],
      },
      {
        path: OrderRoutes.LIST,
        element: <OrderLayout />,
        children: [
          {
            index: true,
            element: <OrderListPage />,
          },
          {
            path: OrderRoutes.DETAIL,
            element: <OrderDetailsPage />,
          },
          {
            path: OrderRoutes.CREATE,
            element: <OrderAddedPage />,
          },
        ],
      },
      {
        path: PickupGoods.LIST,
        element: <PickupGoodsLayout />,
        children: [
          {
            index: true,
            element: <PickupGoodsPage />,
          },
        ],
      },
      {
        path: ReturnOrderRoutes.LIST,
        element: <ReturnOrderLayout />,
        children: [
          {
            index: true,
            element: <ReturnOrderListPage />,
          },
          {
            path: ReturnOrderRoutes.DETAIL,
            element: <ReturnOrderDetailsPage />,
          },
          {
            path: ReturnOrderRoutes.RETURN_FULL_DETAIL,
            element: <ReturnFullOrderDetailsPage />,
          },
          {
            path: ReturnOrderRoutes.RETURN_PART_DETAIL,
            element: <ReturnPartialDetailsPage />,
          },
        ],
      },
      {
        path: ReplaceOrderRoutes.LIST,
        element: <ReplaceOrderLayout />,
        children: [
          {
            index: true,
            element: <ReplaceOrderListPage />,
          },
          {
            path: ReplaceOrderRoutes.DETAIL,
            element: <ReplaceOrderDetailsPage />,
          },
        ],
      },
      {
        path: RefundOrderRoutes.LIST,
        element: <RefundOrderLayout />,
        children: [
          {
            index: true,
            element: <RefundOrderListPage />,
          },
          {
            path: RefundOrderRoutes.DETAIL,
            element: <RefundOrderDetailsPage />,
          },
        ],
      },
      {
        path: DepartmentRoutes.LIST,
        element: <DepartmentLayout />,
        children: [
          {
            index: true,
            element: <DepartmentListPage />,
          },
          {
            path: DepartmentRoutes.CREATE,
            element: <DepartmentAddedPage />,
          },
          {
            path: DepartmentRoutes.VIEW,
            element: <DepartmentDetailsPage />,
          },
          {
            path: DepartmentRoutes.EDIT,
            element: <DepartmentAddedPage />,
          },
        ],
      },
      {
        path: ReasonCodeRoutes.LIST,
        element: <ReasonCodeLayout />,
        children: [
          {
            index: true,
            element: <ReasonCodeListPage />,
          },
          {
            path: ReasonCodeRoutes.CREATE,
            element: <ReasonCodeAddedPage />,
          },
          {
            path: ReasonCodeRoutes.VIEW,
            element: <ReasonCodeDetailsPage />,
          },
          {
            path: ReasonCodeRoutes.EDIT,
            element: <ReasonCodeAddedPage />,
          },
        ],
      },
      {
        path: InternalADJRoutes.LIST,
        element: <InternalAdjLayout />,
        children: [
          {
            index: true,
            element: <InternalAdjListPage />,
          },
          {
            path: InternalADJRoutes.CREATE,
            element: <InternalAdjAddedPage />,
          },
          {
            path: InternalADJRoutes.VIEW,
            element: <InternalAdjDetailsPage />,
          },
          {
            path: InternalADJRoutes.EDIT,
            element: <InternalAdjAddedPage />,
          },
        ],
      },
      {
        path: StockTakeVoucherRoutes.LIST,
        element: <StockTakeVoucherLayout />,
        children: [
          {
            index: true,
            element: <StockTakeVoucherListPage />,
          },
          {
            path: StockTakeVoucherRoutes.CREATE,
            element: <StockTakeVoucherAddedPage />,
          },
          {
            path: StockTakeVoucherRoutes.VIEW,
            element: <StockTakeVoucherDetailsPage />,
          },
          {
            path: StockTakeVoucherRoutes.ADJUSTMENT,
            element: <StockTakeAdjustmentDetailsPage />,
          },
          {
            path: StockTakeVoucherRoutes.ENTER_ACTUAL_DATA,
            element: <StockTakeVoucherEnterActualDataPage />,
          },
        ],
      },
      {
        path: Report.Base,
        element: <ReportLayout />,
        children: [
          {
            path: Report.ImportStock,
            element: <ImportStockReportPage />,
          },
          {
            path: Report.ExportStock,
            element: <ExportStockReportPage />,
          },
          {
            path: Report.OrderStatus,
            element: <OrderStatusListPage />,
          },
          {
            path: Report.ReturnReplacement,
            element: <ReturnReplacementListPage />,
          },
          {
            path: Report.Sku,
            element: <SkuListPage />,
          },
          {
            path: Report.StockTake,
            element: <StockTakeListPage />,
          },
          {
            path: Report.Inventory,
            element: <InventoryListPage />,
          },
          {
            path: Report.InventoryDetail,
            element: <InventoryDetailPage />,
          },
          {
            path: Report.CMove,
            element: <CMoveListPage />,
          },
          {
            path: Report.ChangeStatus,
            element: <ChangeStatusListPage />,
          },
        ],
      },
    ],
  },
  { path: ROUTE_PATHS.notFound, element: <NotFoundPage /> },
];

const Routes = observer(() => {
  const { isAuthenticated } = useAuthStore();
  let routes: Array<RouteObject> = [];

  if (isAuthenticated) routes = getRoutes();
  else {
    routes = [
      {
        path: '/',
        element: <WelcomePage />,
      },
      {
        path: '/auth/signin-callback',
        element: <SigninCallbackPage />,
      },
      {
        path: '/auth/silent-callback',
        element: <SilentCallbackPage />,
      },
    ];
  }

  return useRoutes(routes);
});

export default Routes;
