import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { TransferNoteDetailModel } from './TransferNoteDetailModel';

export const TransferNoteModel = types.model('TransferNote Model', {
  id: types.identifier,
  code: types.string,
  enteredDate: types.string,
  issuedDate: types.maybeNull(types.string),
  shippingMethod: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  status: types.string,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  exportWarehouse: types.model({
    id: types.string,
    name: types.string,
  }),
  importWarehouse: types.model({
    id: types.string,
    name: types.string,
  }),

  exportWarehouseId: types.maybeNull(types.string),
  importWarehouseId: types.maybeNull(types.string),
  trnItems: types.array(TransferNoteDetailModel),
  transferNoteItems: types.optional(types.array(TransferNoteDetailModel), []),
});

export interface TransferNoteInstance extends Instance<typeof TransferNoteModel> {}
export interface TransferNoteInputModel extends SnapshotIn<typeof TransferNoteModel> {}
export interface TransferNote extends SnapshotOut<typeof TransferNoteModel> {}
