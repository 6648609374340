import { InventoryReportType } from 'enums';
import { SnapshotIn, types } from 'mobx-state-tree';
import { getYesterday } from 'pages/shared/utils';

export const InventoryReportFilterParamsModel = types
  .model({
    keyword: types.optional(types.string, ''),
    fromDate: types.maybeNull(types.Date),
    toDate: types.maybeNull(types.Date),
    goodsId: types.optional(types.string, ''),
    locationId: types.optional(types.string, ''),
    warehouseId: types.optional(types.string, ''),
    batchNumber: types.optional(types.string, ''),
    status: types.optional(types.string, ''),
    dateFilter: types.optional(types.boolean, false),
    warehouseFilter: types.optional(types.boolean, false),
    locationFilter: types.optional(types.boolean, false),
    statusFilter: types.optional(types.boolean, false),
    type: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get getDisplayDateFilter() {
        return self.dateFilter === true ? 'block' : 'none';
      },
      get getDisplayWarehouseFilter() {
        return self.warehouseFilter === true ? 'block' : 'none';
      },
      get getDisplayLocationFilter() {
        return self.locationFilter === true ? 'block' : 'none';
      },
      get getDisplayStatusFilter() {
        return self.statusFilter === true ? 'block' : 'none';
      },
    };
  })
  .actions((self: any) => {
    const setQueryParamsValue = (name: string, value: any) => {
      self[name] = value;
    };

    return { setQueryParamsValue };
  });

export interface InventoryReportFilterParams
  extends SnapshotIn<typeof InventoryReportFilterParamsModel> {}
export const DefaultInventoryReportFilterParams: InventoryReportFilterParams = {
  keyword: '',
  fromDate: getYesterday(),
  toDate: new Date(),
  locationId: '',
  warehouseId: '',
  goodsId: '',
  batchNumber: '',
  status: '',
  dateFilter: true,
  statusFilter: false,
  warehouseFilter: false,
  locationFilter: false,
  type: InventoryReportType.INIT_QTY,
};
