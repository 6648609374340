export enum AdjustmentStatus {
  INIT = 'INIT',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export enum AdjustmentKind {
  INWARD = 'INWARD',
  OUTWARD = 'OUTWARD',
}
