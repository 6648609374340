import { TransferNoteStatus } from 'enums';

export const TransferNoteStatusOptions: SelectOption[] = [
  {
    label: 'Initial',
    value: TransferNoteStatus.INITIAL,
  },
  {
    label: 'In Transit',
    value: TransferNoteStatus.INTRANSIT,
  },
  {
    label: 'Completed (Abnormal)',
    value: TransferNoteStatus.COMPLETED_ABNORMAL,
  },
  {
    label: 'Completed (Partial)',
    value: TransferNoteStatus.INPUT_PARTIAL,
  },
  {
    label: 'Completed',
    value: TransferNoteStatus.COMPLETED,
  },
  {
    label: 'Cancelled',
    value: TransferNoteStatus.CANCEL,
  },
];
