import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultReasonCodeFilterParams,
  ReasonCodeFilterParams,
  ReasonCodeFilterParamsModel,
  ReasonCodeModel,
  ReasonCode,
  DefaultReasonCodeValue,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';

export type ReasonCodeStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

import { removeEmptyInObject } from 'pages/shared/utils';
import { REASON_CODES_API } from 'api';

const ReasonCodeStore = types
  .model('ReasonCode Store', {
    reasonCodes: types.array(ReasonCodeModel),
    reasonCodeDetail: types.maybe(ReasonCodeModel),
    filterParams: types.optional(ReasonCodeFilterParamsModel, DefaultReasonCodeFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get ReasonCodeList() {
        return self.reasonCodes;
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ReasonCodeStoreEnv>(self);

    const setQueryParams = (filterParams: ReasonCodeFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultReasonCodeFilterParams, ...filterParams });
    };

    const getReasonCodesAsync = flow(function* () {
      const loadingId = load('Get ReasonCode Async');
      const response = yield http.get(REASON_CODES_API.GET_REASON_CODES, {
        params: self.getQueryParams,
      });
      applySnapshot(self.reasonCodes, [...response.data.results]);
      applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const createReasonCode = flow(function* (
      ReasonCode?: typeof DefaultReasonCodeValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create ReasonCode');
      try {
        yield http.post('/ReasonCodes', ReasonCode);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editReasonCode = flow(function* (
      ReasonCode?: typeof DefaultReasonCodeValue,
      id?: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Edit ReasonCode');
      try {
        yield http.put(`/ReasonCodes/${id}`, { ...ReasonCode, id });
        applySnapshot(self.reasonCodes, []); //Re-define memory of list ReasonCodes
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getReasonCodeById = flow(function* (id: string) {
      const loadingId = load('Get ReasonCode By Id');
      const response = yield http.get(`/ReasonCodes/${id}`);
      self.reasonCodeDetail = response.data;
      loaded(loadingId);
    });

    const setReasonCodeDetail = (ReasonCode?: ReasonCode) => {
      const ReasonCodeDetail: any = ReasonCode;
      self.reasonCodeDetail = { ...ReasonCodeDetail };
    };

    const deleteReasonCode = flow(function* (id: number) {
      const loadingId = load('Delete ReasonCode');
      try {
        yield http.delete(`/ReasonCodes/${id}`);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getReasonCodesAsync,
      createReasonCode,
      editReasonCode,
      getReasonCodeById,
      deleteReasonCode,
      setQueryParams,
      setReasonCodeDetail,
    };
  });

export default ReasonCodeStore;
export type ReasonCodeStoreInstance = Instance<typeof ReasonCodeStore>;
