import { types, SnapshotOut } from '@vklink/libs-state';

export const PurchaseOrderDetailModel = types.model('PurchaseOrder Detail Model', {
  goodsCode: types.string,
  goodsName: types.string,
  goodsUnit: types.maybeNull(types.string),
  goodsPrice: types.string,
  orderedQuantity: types.number,
});

export interface PurchaseOrderDetail extends SnapshotOut<typeof PurchaseOrderDetailModel> {}
