import dayjs from 'dayjs';
import { FormatDate, OrderStatus } from 'enums';

export const removeEmptyInObject = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null && v !== ''));
};

export const formatCurrency = (currency: number | string, n: number, x: number) => {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return Number(currency)
    .toFixed(Math.max(0, ~~n))
    .replace(new RegExp(re, 'g'), '$&,');
};

export const onlyUniqueForOptions = (array: any) => {
  const result: any[] = [];
  array.filter((item: any) => {
    const i = result.findIndex((x) => x.label == item.label);
    if (i <= -1) {
      result.push({ value: item.value, label: item.label });
    }
    return null;
  });
  return result;
};

export const minusTwoDate = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24)) - 1;
};

export const getYesterday = () => {
  const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  return yesterday;
};

export const formatDate = (date: string | Date | null) => {
  if (date) {
    return dayjs(date).format(FormatDate.DATE_TIME);
  } else {
    return '';
  }
};

export const getColorByStatus = (status: string) => {
  switch (status) {
    case OrderStatus.WAITING_ORDER:
      return '#2E4576';
    case OrderStatus.CONFIRMED_ORDER:
      return '#B196DB';
    case OrderStatus.PICKED_UP_ORDER:
      return '#0288D1';
    case OrderStatus.EXPORTED_ORDER:
      return '#FFBD33';
    case OrderStatus.RECEIVED_ORDER:
      return '#ED6C02';
    case OrderStatus.RECEIVED_ORDER:
      return '#0AB39C';
    case OrderStatus.FULL_RETURN_ORDER:
      return 'primary.main';
    case OrderStatus.PARTIAL_RETURN_ORDER:
      return 'secondary.main';
    case OrderStatus.REPLACEMENT_ORDER:
      return 'primary.dark';
    case OrderStatus.CANCEL_ORDER:
      return '#D32F2F';
    default:
      return '#fff';
  }
};
