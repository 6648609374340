import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const ProductItemModel = types.model({
  goodsSetCode: types.maybeNull(types.string),
  goodsSetName: types.maybeNull(types.string),
  goodsCode: types.string,
  goodsName: types.string,
  barcode: types.string,
  quantity: types.maybeNull(types.number),
  reason: types.maybeNull(types.string),
  deductPercent: types.maybeNull(types.number),
});

export const DefaultProductItem: Partial<ProductItemModelInputModel> = {
  quantity: 0,
};

export interface ProductItemModelInstance extends Instance<typeof ProductItemModel> {}
export interface ProductItemModelInputModel extends SnapshotIn<typeof ProductItemModel> {}
export interface ProductItem extends SnapshotOut<typeof ProductItemModel> {}
