import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { DummyDataModel } from 'stores/models';
import { ReportStoreEnv } from '../../Store';
import {
  DefaultImportStockReportFilterParams,
  ImportStockReportFilterParamsModel,
} from './ImportStockReportFilterParamsModel';

import dataDemo from '../../../../../../../../admin-db.json';

export const ImportStockListModel = types
  .model({
    items: types.optional(types.array(DummyDataModel), []),
    filterParams: types.optional(
      ImportStockReportFilterParamsModel,
      DefaultImportStockReportFilterParams
    ),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get itemList() {
        return getSnapshot(self.items);
      },
    };
  })
  .actions((self) => {
    const { load, loaded } = getEnv<ReportStoreEnv>(self);

    const getAsync = flow(function* () {
      const loadingId = load('Get Import Stock Report List Async');
      applySnapshot(self.items, new Array(10).fill(dataDemo.importStockReportItem));
      loaded(loadingId);
    });

    return { getAsync };
  });

export type ImportStockReportStoreInstance = Instance<typeof ImportStockListModel>;
