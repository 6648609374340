import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { Highlight } from 'enums';

export const ProductItemModel = types.model('Product Item Model', {
  id: types.identifier,
  goodsId: types.string,
  goodsName: types.string,
  goodsCode: types.string,
  goodsSetId: types.string,
  goodsSetName: types.string,
  goodsSetCode: types.string,
  quantity: types.integer,
  isHighlight: types.maybeNull(
    types.enumeration('Is Highlight', [Highlight.FULL, Highlight.PARTIAL])
  ),
  salesItemDetailId: types.maybeNull(types.string),
  locationId: types.maybeNull(types.string),
  locationName: types.maybeNull(types.string),
  locationCode: types.maybeNull(types.string),
});

export interface ProductItemInstance extends Instance<typeof ProductItemModel> {}
export interface ProductItemInputModel extends SnapshotIn<typeof ProductItemModel> {}
export interface ProductItem extends SnapshotOut<typeof ProductItemModel> {}
