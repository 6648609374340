import { types, Instance, SnapshotIn, SnapshotOut, applySnapshot } from '@vklink/libs-state';
import { PaymentStatus } from 'enums';
import { CartonInformation, CartonInformationModel } from './CartonInformationModel';
import { DeliveryInfoModel } from './DeliveryInfoModel';
import { OrderDeliveryModel } from './OrderDeliveryModel';
import { PickupDetailModel, PickupTempModel } from './PickupDetailModel';
import { ProductItemModel } from './ProductItemModel';

export const OrderPickupDetailModel = types
  .model('Order Pickup Detail Model', {
    mbid2: types.maybeNull(types.string),
    customerName: types.maybeNull(types.string),
    orderNumber: types.maybeNull(types.string),
    orderDate: types.maybeNull(types.string),
    orderDateFormatted: types.maybeNull(types.string),
    locationId: types.maybeNull(types.string),
    locationName: types.maybeNull(types.string),
    warehouseId: types.maybeNull(types.string),
    warehouseName: types.maybeNull(types.string),
    paymentStatus: types.maybeNull(
      types.enumeration<PaymentStatus>([PaymentStatus.PAID, PaymentStatus.UNPAID])
    ),
    orderStatus: types.maybeNull(types.string),
    deliveryAddress: types.maybeNull(DeliveryInfoModel),
    items: types.array(types.late(() => ProductItemModel)),
    orderPickups: types.array(types.late(() => PickupDetailModel)),
    orderPickupTemps: types.array(types.late(() => PickupTempModel)),
    orderPackages: types.array(types.late(() => CartonInformationModel)),
    orderDelivery: types.maybeNull(OrderDeliveryModel),
    barcodeNumbers: types.maybeNull(types.array(types.string)),
  })
  .actions((self) => {
    const addCartonInfo = (cartonInfo: CartonInformation) => {
      if (!self.orderPackages) applySnapshot(self.orderPackages, []);
      self.orderPackages.push(cartonInfo);
    };

    return { addCartonInfo };
  });

export interface OrderPickupDetailInstance extends Instance<typeof OrderPickupDetailModel> {}
export interface OrderPickupDetailInputModel extends SnapshotIn<typeof OrderPickupDetailModel> {}
export interface OrderPickupDetail extends SnapshotOut<typeof OrderPickupDetailModel> {}

export const DefaultOrderPickupDetailValue: OrderPickupDetail = {
  orderNumber: null,
  mbid2: null,
  customerName: null,
  orderDate: null,
  orderDateFormatted: null,
  locationId: null,
  locationName: null,
  warehouseId: null,
  warehouseName: null,
  paymentStatus: null,
  orderStatus: null,
  deliveryAddress: null,
  items: [],
  orderPickups: [],
  orderPickupTemps: [],
  orderPackages: [],
  barcodeNumbers: [],
  orderDelivery: null,
};
