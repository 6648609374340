import { SnapshotIn, types } from 'mobx-state-tree';

export const TransferNoteFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  exportWarehouseId: types.optional(types.string, ''),
  importWarehouseId: types.optional(types.string, ''),
});

export interface TransferNoteFilterParams
  extends SnapshotIn<typeof TransferNoteFilterParamsModel> {}

export const DefaultTransferNoteFilterParams: TransferNoteFilterParams = {
  keyword: '',
  status: '',
  exportWarehouseId: '',
  importWarehouseId: '',
};
