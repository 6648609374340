import { SnapshotIn, types } from 'mobx-state-tree';
import { getYesterday } from 'pages/shared/utils';

export const StockTakeVoucherFilterParamsModel = types.model({
  keyword: types.maybeNull(types.string),
  warehouseId: types.maybeNull(types.string),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
  status: types.maybeNull(types.string),
});

export interface StockTakeVoucherFilterParams
  extends SnapshotIn<typeof StockTakeVoucherFilterParamsModel> {}
export const DefaultStockTakeVoucherFilterParams: StockTakeVoucherFilterParams = {
  keyword: null,
  warehouseId: null,
  status: null,
  fromDate: getYesterday(),
  toDate: new Date(),
};
