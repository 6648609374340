import { generatePath } from 'react-router-dom';

import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultOrderFilterParams,
  OrderItemModel,
  OrderFilterParams,
  OrderFilterParamsModel,
  OrderModel,
  OrderToltalQuantityModel,
  OrderTrackingModel,
  OrderDetailModel,
} from './models';

import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import { ORDER_API } from 'api';

export type OrderStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const OrderStore = types
  .model('Order Store', {
    orders: types.array(OrderModel),
    orderDetail: types.maybe(OrderDetailModel),
    orderTracking: types.array(OrderTrackingModel),
    detailProduct: types.optional(types.array(OrderItemModel), []),
    filterParams: types.optional(OrderFilterParamsModel, DefaultOrderFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    orderNumber: types.maybeNull(types.string),
    bolCode: types.maybeNull(types.string),
    orderTotalQuantity: types.array(OrderToltalQuantityModel),
  })
  .views((self) => {
    return {
      get orderList() {
        return self.orders;
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getOrderNumber() {
        return self.orderNumber;
      },
      get getBolCode() {
        return self.bolCode;
      },
      get getOrderTotalQuantity() {
        return getSnapshot(self.orderTotalQuantity).map((el) => {
          return {
            ...el,
            total: parseInt(el.total),
          };
        });
      },
      get getOrderDetail() {
        return self.orderDetail;
      },
      get getListProductItems() {
        return self.orderDetail ? self.orderDetail?.items : [];
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<OrderStoreEnv>(self);

    const setQueryParams = (filterParams: OrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultOrderFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setOrderNumber = (orderNumber: string) => {
      self.orderNumber = orderNumber;
    };

    const getOrdersAsync = flow(function* () {
      const loadingId = load('Get Order Async');
      try {
        const response = yield http.get('/orders', {
          params: self.getQueryParams,
        });
        applySnapshot(self.orders, []);
        applySnapshot(self.orders, [...response.data]);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderByIdAsync = flow(function* (id?: string) {
      const loadingId = load('Get Order By Id');

      const response = yield http.get(`/orders/${id}`);

      self.orderDetail = response.data;

      loaded(loadingId);
    });

    const getOrderTrackingAsync = flow(function* (id: string, bolCode: string) {
      const loadingId = load('Get Order Tracking');
      const response = yield http.get(`/orders/${id}/${bolCode}`);
      const dataTracking = JSON.parse(response.data.trackAndTrace);
      self.orderNumber = id;
      self.bolCode = bolCode;
      applySnapshot(self.orderTracking, dataTracking);
      loaded(loadingId);
    });

    const getOrderTotalQuantityAsync = flow(function* () {
      const loadingId = load('Get Order Toltal Quantity');
      try {
        const response = yield http.get(`orders/quantity`, {
          params: self.getQueryParams,
        });

        applySnapshot(self.orderTotalQuantity, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCancelDelayPaymentOrderAsync = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Get Cancel Delay Payment Order');
      try {
        const url = generatePath(ORDER_API.GET_CANCEL_DELAY_PAYMENT_ORDER, {
          orderNumber: self.orderNumber || '',
        });

        yield http.get(url);
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      setPaginationParams,
      setOrderNumber,
      getOrdersAsync,
      getOrderByIdAsync,
      getOrderTrackingAsync,
      getOrderTotalQuantityAsync,
      getCancelDelayPaymentOrderAsync,
    };
  });

export default OrderStore;
export type OrderStoreInstance = Instance<typeof OrderStore>;
