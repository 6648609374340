import { types } from '@vklink/libs-state';

export const BranchOptionsModel = types.model('Branch Options Model', {
  id: types.identifier,
  name: types.string,
});

export const BranchDetailModel = types.model('Address By Branch Id Model', {
  id: types.identifier,
  fullAddress: types.string,
});
