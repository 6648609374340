export enum ProductCategory {
  HEALTH = 'HEALTH',
  SPORT = 'SPORT',
  TECH = 'TECH',
  HOME = 'HOME',
  OTHER = 'OTHER',
}

export enum UnitProduct {
  PACK = 'PACK',
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
