import { createStoreContext } from '@vklink/libs-state';

import { type CategoryStoreInstance } from './CategoryStore';

const [CategoryStoreProvider, useCategoryStore] = createStoreContext<CategoryStoreInstance>();

export { CategoryStoreProvider, useCategoryStore };

export {
  default as CategoryStore,
  type CategoryStoreEnv,
  type CategoryStoreInstance,
} from './CategoryStore';
