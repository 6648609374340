import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ReplaceOrderModel = types.model('Replace Order Model', {
  id: types.identifier,
  orderNumber: types.maybeNull(types.string),
  sellDate: types.maybeNull(types.string),
  returnTf: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  receivedDate: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  allowReplacement: types.maybeNull(types.boolean),
  allowReturnFull: types.maybeNull(types.boolean),
  allowReturnPart: types.maybeNull(types.boolean),
});

export const DefaultOrderValue: Partial<ReplaceOrderInputModel> = {
  orderNumber: '',
  sellDate: '',
  returnTf: '',
};

export interface ReplaceOrderInstance extends Instance<typeof ReplaceOrderModel> {}
export interface ReplaceOrderInputModel extends SnapshotIn<typeof ReplaceOrderModel> {}
export interface ReplaceOrder extends SnapshotOut<typeof ReplaceOrderModel> {}
