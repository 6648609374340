import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { AdjustmentKind } from 'enums';

export const InternalAdjItemsModel = types
  .model('Internal Adjustment Items Model', {
    id: types.maybeNull(types.string),
    goodsCode: types.string,
    goodsId: types.string,
    adjustmentKind: types.enumeration([AdjustmentKind.INWARD, AdjustmentKind.OUTWARD]),
    batchNumber: types.string,
    quantity: types.number,
    locationId: types.string,
    locationName: types.maybeNull(types.string),
    status: types.string,
    reasonId: types.string,
    reasonName: types.string,
    departmentId: types.string,
    departmentName: types.string,
    remark: types.maybeNull(types.string),
    goodsName: types.string,
    stockQuantity: types.maybeNull(types.number),
    realStockQuantity: types.maybeNull(types.number),
    goodsPrice: types.maybeNull(types.number),
    goodsTotalPrice: types.maybeNull(types.number),
    lastSerial: types.maybeNull(types.string),
  })
  .views((self) => ({
    get getUnique() {
      return {
        adjustmentKind: self.adjustmentKind,
        goodsCode: self.goodsCode,
        batchNumber: self.batchNumber,
        locationId: self.locationId,
        status: self.status,
      };
    },
  }));

export const DefaultInternalAdjItemsValue: Partial<InternalAdjItemsInputModel> = {
  goodsCode: '',
  adjustmentKind: undefined,
  batchNumber: '',
  quantity: 0,
  locationId: '',
  status: '',
  reasonId: '',
  departmentId: '',
  remark: '',
  goodsName: '',
  stockQuantity: 0,
};

export type CreateInternalAdjItems = Pick<
  InternalAdjItemsInputModel,
  | 'goodsCode'
  | 'adjustmentKind'
  | 'batchNumber'
  | 'quantity'
  | 'locationId'
  | 'status'
  | 'reasonId'
  | 'departmentId'
  | 'remark'
  | 'goodsName'
  | 'stockQuantity'
  | 'realStockQuantity'
>;

export type AddInternalAdjustmentItems = Omit<InternalAdjItems, 'goodsPrice' | 'goodsTotalPrice'>;

export interface InternalAdjItemsInstance extends Instance<typeof InternalAdjItemsModel> {}
export interface InternalAdjItemsInputModel extends SnapshotIn<typeof InternalAdjItemsModel> {}
export interface InternalAdjItems extends SnapshotOut<typeof InternalAdjItemsModel> {}
