export enum LabelMethod {
  PO = 'Purchase Order',
  TRN = 'Transfer Note',
  SKU = 'SKU',
  BARCODE = 'Barcode',
}

export enum PrintLabelType {
  PO = 'PO',
}
