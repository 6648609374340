import { createStoreContext } from '@vklink/libs-state';

import { type WarehouseStoreInstance } from './WarehouseStore';

const [WarehouseStoreProvider, useWarehouseStore] = createStoreContext<WarehouseStoreInstance>();

export { WarehouseStoreProvider, useWarehouseStore };

export {
  default as WarehouseStore,
  type WarehouseStoreEnv,
  type WarehouseStoreInstance,
} from './WarehouseStore';
