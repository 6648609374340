import { PurchaseOrderStatus } from 'enums';

export const PurchaseOrderStatusOptions: SelectOption[] = [
  {
    label: 'In Transit',
    value: PurchaseOrderStatus.INTRANSIT,
  },
  {
    label: 'Completed (Abnormal)',
    value: PurchaseOrderStatus.COMPLETED_ABNORMAL,
  },
  {
    label: 'Completed',
    value: PurchaseOrderStatus.COMPLETED,
  },
  {
    label: 'Cancelled',
    value: PurchaseOrderStatus.CANCEL,
  },
  {
    label: 'Completed (Partial)',
    value: PurchaseOrderStatus.INPUT_PARTIAL,
  },
];
