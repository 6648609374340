import { createHttpInstance, type HttpInstance } from '@vklink/libs-http';
import { types, LoadingStore, Instance, createStore, createStoreContext } from '@vklink/libs-state';
import env from 'env';

const RootStore = types.model('Root Store', {
  loadingStore: LoadingStore,
});

type RootStoreInstance = Instance<typeof RootStore>;
type RootStoreEnv = {
  http: HttpInstance;
};

const http = createHttpInstance(env.baseApiUrl);

const rootStoreEnv: RootStoreEnv = {
  http,
};

const initialRootState = createStore(
  RootStore,
  {
    loadingStore: LoadingStore.create(),
  },
  rootStoreEnv
);

const [RootStoreProvider, useRootStore] = createStoreContext<RootStoreInstance>();

export {
  type RootStoreInstance,
  type RootStoreEnv,
  initialRootState,
  RootStoreProvider,
  useRootStore,
};
