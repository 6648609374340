import { CategoryType } from 'enums';

export const CategoryStatusOptions: SelectOption[] = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Invalid',
    value: 'Invalid',
  },
];

export const CategoryTypeOptions: SelectOption[] = [
  {
    label: 'Product Online',
    value: CategoryType.PRODUCT_ONLINE,
  },
  {
    label: 'Product Offline',
    value: CategoryType.PRODUCT_OFFLINE,
  },
];
