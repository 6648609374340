import { createStoreContext } from '@vklink/libs-state';

import { type ReturnOrderStoreInstance } from './ReturnOrderStore';

const [ReturnOrderStoreProvider, useReturnOrderStore] =
  createStoreContext<ReturnOrderStoreInstance>();

export { ReturnOrderStoreProvider, useReturnOrderStore };

export {
  default as ReturnOrderStore,
  type ReturnOrderStoreEnv,
  type ReturnOrderStoreInstance,
} from './ReturnOrderStore';
