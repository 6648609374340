import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { InternalAdjItemsModel } from './InternalAdjItemsModel';

export const InternalAdjModel = types.model('InternalADJ Model', {
  id: types.identifier,
  code: types.string,
  status: types.string,
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  warehouseId: types.maybeNull(types.string),
  warehouseCode: types.maybeNull(types.string),
  warehouseName: types.maybeNull(types.string),
  approver: types.maybeNull(types.string),
  approvedDate: types.maybeNull(types.string),
  remark: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  importQuantity: types.maybeNull(types.number),
  exportQuantity: types.maybeNull(types.number),

  items: types.array(InternalAdjItemsModel),
});

export const DefaultInternalAdjValue: Partial<InternalAdj> = {
  warehouseId: '',
  remark: '',
  items: [],
};

export type CreateInternalAdj = Pick<InternalAdjInputModel, 'warehouseId' | 'remark'>;

export interface InternalAdjInstance extends Instance<typeof InternalAdjModel> {}
export interface InternalAdjInputModel extends SnapshotIn<typeof InternalAdjModel> {}
export interface InternalAdj extends SnapshotOut<typeof InternalAdjModel> {}
