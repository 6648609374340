import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const PickupDetailModel = types.model('Pickup Detail Model', {
  id: types.identifier,
  orderNumber: types.string,
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  goodsSetId: types.maybeNull(types.string),
  goodsSetCode: types.maybeNull(types.string),
  goodsSetName: types.maybeNull(types.string),
  warehouseId: types.string,
  warehouseCode: types.maybeNull(types.string),
  warehouseName: types.string,
  locationId: types.string,
  locationCode: types.maybeNull(types.string),
  locationName: types.string,
  batchNumber: types.string,
  barcodeNumbers: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.string),
  stockStatus: types.maybeNull(types.string),
  quantity: types.number,
  orderPackageId: types.maybeNull(types.string),
  salesItemDetailId: types.maybeNull(types.string),
});

export interface PickupDetailInstance extends Instance<typeof PickupDetailModel> {}
export interface PickupDetailInputModel extends SnapshotIn<typeof PickupDetailModel> {}
export interface PickupDetail extends SnapshotOut<typeof PickupDetailModel> {}

export const PickupTempModel = types.model('Pickup Temp Model', {
  id: types.identifier,
  orderNumber: types.string,
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  goodsSetId: types.string,
  goodsSetCode: types.string,
  goodsSetName: types.string,
  warehouseId: types.string,
  warehouseCode: types.string,
  warehouseName: types.string,
  locationId: types.string,
  locationCode: types.string,
  locationName: types.string,
  batchNumber: types.string,
  barcodeNumbers: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
});

export interface PickupTemp extends SnapshotOut<typeof PickupTempModel> {}
