import { createStoreContext } from '@vklink/libs-state';

import { type PurchaseOrderStoreInstance } from './PurchaseOrderStore';

const [PurchaseOrderStoreProvider, usePurchaseOrderStore] =
  createStoreContext<PurchaseOrderStoreInstance>();

export { PurchaseOrderStoreProvider, usePurchaseOrderStore };

export {
  default as PurchaseOrderStore,
  type PurchaseOrderStoreEnv,
  type PurchaseOrderStoreInstance,
} from './PurchaseOrderStore';
