import { createStoreContext } from '@vklink/libs-state';

import { type PickupGoodsStoreInstance } from './PickupGoodsStore';

const [PickupGoodsStoreProvider, usePickupGoodsStore] =
  createStoreContext<PickupGoodsStoreInstance>();

export { PickupGoodsStoreProvider, usePickupGoodsStore };

export {
  default as PickupGoodsStore,
  type PickupGoodsStoreEnv,
  type PickupGoodsStoreInstance,
} from './PickupGoodsStore';
