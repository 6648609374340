import { SnapshotIn, types } from 'mobx-state-tree';

export const ImportHistoryFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface ImportHistoryFilterParams
  extends SnapshotIn<typeof ImportHistoryFilterParamsModel> {}

export const DefaultImportHistoryFilterParams: ImportHistoryFilterParams = {
  keyword: '',
};
