export enum WarehouseType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  NTBU = 'NTBU',
}

export enum WarehouseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
