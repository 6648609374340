import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { ImportStockPart, ImportStockStatus, ImportStockType } from 'enums';
import { ProductListModel } from './ProductList';

export const ImportStockModel = types.model('ImportStock List Model', {
  id: types.identifier,
  warehousingtype: types.enumeration<ImportStockType>([
    ImportStockType.PURCHASE_ORDER,
    ImportStockType.TRANSFER_NOTE,
  ]),
  warehousingNumber: types.string,
  exportWarehouseId: types.maybeNull(types.string),
  exportWarehouseName: types.maybeNull(types.string),
  importWarehouseId: types.maybeNull(types.string),
  importWarehouseName: types.maybeNull(types.string),
  status: types.enumeration<ImportStockStatus>([
    ImportStockStatus.INTRANSIT,
    ImportStockStatus.COMPLETED_ABNORMAL,
    ImportStockStatus.INPUT_PARTIAL,
    ImportStockStatus.COMPLETED,
    ImportStockStatus.CANCELLED,
  ]),
  receivedDate: types.maybeNull(types.maybe(types.string)),
  transferDate: types.maybeNull(types.string),
  transferDateFormatted: types.maybeNull(types.Date),
  shippingMethod: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  importStockType: types.maybeNull(
    types.enumeration<ImportStockPart>([ImportStockPart.FULL, ImportStockPart.PARTIAL])
  ),
  items: types.array(ProductListModel),
  exportWarehouse: types.maybeNull(
    types.model({
      name: types.maybeNull(types.string),
      id: types.maybeNull(types.string),
      code: types.maybeNull(types.string),
    })
  ),
  importWarehouse: types.maybeNull(
    types.model({
      name: types.maybeNull(types.string),
      id: types.string,
      code: types.string,
    })
  ),
  mappingStatus: types.maybeNull(types.string),
});

export const DefaultImportStockPosition: Partial<any> = {
  type: '',
  importStock: '',
  importDate: '',
  exportDate: '',
  importStockPart: ImportStockPart.FULL,
  transport: '',
};

export interface ImportStockInstance extends Instance<typeof ImportStockModel> {}
export interface ImportStockInputModel extends SnapshotIn<typeof ImportStockModel> {}
export interface ImportStock extends SnapshotOut<typeof ImportStockModel> {}
