import { SnapshotIn, types } from 'mobx-state-tree';

export const ProductInventoryFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  locationId: types.optional(types.string, ''),
  warehouseId: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
});

export interface ProductInventoryFilterParams
  extends SnapshotIn<typeof ProductInventoryFilterParamsModel> {}
export const DefaultProductInventoryFilterParams: ProductInventoryFilterParams = {
  keyword: '',
  locationId: '',
  warehouseId: '',
  status: '',
};
