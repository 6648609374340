import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderItemModel = types.model('Order Item Model', {
  goodsSetCode: types.maybeNull(types.string),
  goodsSetName: types.maybeNull(types.string),
  goodsCode: types.string,
  goodsName: types.string,
  quantity: types.number,
  remainingQty: types.maybeNull(types.number),
});

export interface OrderItem extends SnapshotOut<typeof OrderItemModel> {}
