// const API_V1 = 'api/v1';

const API_V1 = '';

export const WAREHOUSE_API = {
  GET_WAREHOUSES: `${API_V1}/warehouses`,
  GET_WAREHOUSE_BY_ID: `${API_V1}/warehouses/:id`,
  POST_WAREHOUSE: `${API_V1}/warehouses`,
  PUT_WAREHOUSE: `${API_V1}/warehouses/:id`,
  PUT_STATUS_WAREHOUSE: `${API_V1}/warehouses/:id`,
  GET_BRANCHES: `${API_V1}/branches`,
  GET_BRANCH_BY_ID: `${API_V1}/branches/:id`,
};
