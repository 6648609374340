import { SnapshotOut, types } from '@vklink/libs-state';
import { DeliveryAddressModel } from './DeliveryAddressModel';
import { OrderItemModel } from './OrderItemModel';
import { OrderPackageModel } from './OrderPackageModel';
import { OrderPickupModel } from './OrderPickupModel';
import { ProductItemModel } from './ProductItemModel';

export const ReturnOrderDetailModel = types.model('Return Order Detail Model', {
  oldItems: types.maybeNull(
    types.array(
      types.model({
        reason: types.string,
        deductPercent: types.number,
      })
    )
  ),

  id: types.identifier,
  orderNumber: types.string,
  mbid2: types.string,
  customerName: types.maybeNull(types.string),
  orderDate: types.string,
  paymentStatus: types.string,
  orderStatus: types.string,
  receivedDate: types.maybeNull(types.string),
  items: types.array(OrderItemModel),
  orderPackages: types.array(OrderPackageModel),
  orderPickups: types.array(OrderPickupModel),
  deliveryAddress: types.maybe(DeliveryAddressModel),
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deductionPercentage: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  returnDate: types.maybeNull(types.string),
  actionType: types.maybeNull(types.string),

  other: types.maybeNull(types.number),
  oldProductItems: types.maybeNull(types.array(ProductItemModel)),
});

export interface ReturnOrderDetail extends SnapshotOut<typeof ReturnOrderDetailModel> {}
