import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const WarehouseModel = types.model('Warehouse Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
  type: types.string,
  address: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  branchId: types.maybeNull(types.string),
  isUse: types.maybeNull(types.boolean),
  branch: types.maybeNull(
    types.model({
      id: types.string,
      name: types.string,
    })
  ),
});

export const DefaultWarehouseValue: Partial<WarehouseInputModel> = {
  name: '',
  type: '',
  address: '',
  description: '',
  branchId: '',
};

export type CreateWarehouse = Pick<
  WarehouseInputModel,
  'name' | 'type' | 'address' | 'description' | 'branchId'
>;

export interface WarehouseInstance extends Instance<typeof WarehouseModel> {}
export interface WarehouseInputModel extends SnapshotIn<typeof WarehouseModel> {}
export interface Warehouse extends SnapshotOut<typeof WarehouseModel> {}
