import { SnapshotOut, types } from 'mobx-state-tree';

export const PaginationModel = types.model({
  totalPages: types.optional(types.number, 0),
  totalItems: types.optional(types.number, 0),
  page: types.optional(types.number, 1),
  limit: types.optional(types.number, 10),
});

export const DefaultPaginationInfo: Pagination = {
  totalItems: 0,
  totalPages: 0,
  page: 1,
  limit: 10,
};

export interface Pagination extends SnapshotOut<typeof PaginationModel> {}
