import { toJS } from 'mobx';
import { types, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultTransferNoteValue,
  TransferNoteDetail,
  TransferNoteDetailModel,
  VariableQuantityModel,
} from './models';
import {
  LocationOptionsModel,
  SkuOptionsModel,
  WarehouseOptionsModel,
} from 'pages/shared/models/common-options';
import { ProductModel } from 'pages/product-management/stores/models';
import { SelectFieldModel } from 'stores/models';
import { StockStatusOptions } from 'constant';
import { onlyUniqueForOptions } from 'pages/shared/utils';
import { StockStatus } from 'enums';

export type TransferNoteStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const TransferNoteAddedStore = types
  .model('Transfer Note Added Store', {
    productItemsByTransferNote: types.array(TransferNoteDetailModel),
    warehouseOptions: types.array(WarehouseOptionsModel),
    skuOptions: types.array(SkuOptionsModel),
    productInformation: types.maybe(ProductModel),
    locationOptions: types.array(LocationOptionsModel),
    batchNumberOptions: types.array(SelectFieldModel),
    statusOptions: types.array(SelectFieldModel),
    variableQuantity: types.maybe(VariableQuantityModel),
  })
  .views((self) => {
    return {
      get getProductItems() {
        return getSnapshot(self.productItemsByTransferNote);
      },
      get getSkuOptions() {
        const result = self.skuOptions.map((el) => {
          return { value: el.id, label: el.code };
        });
        return onlyUniqueForOptions(result);
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getProductInformation() {
        return self.productInformation;
      },
      get getLocationOptions() {
        const result = self.locationOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
        return onlyUniqueForOptions(result);
      },
      get getBatchNumberOptions() {
        const result = self.batchNumberOptions.map((el) => {
          return { value: el.value, label: el.value };
        });
        return onlyUniqueForOptions(result);
      },
      get getStatusOptions() {
        const result = self.statusOptions.map((el) => {
          return {
            value: el.value,
            label: StockStatusOptions.filter((item) => item.value === el.value)[0].label,
          };
        });
        return onlyUniqueForOptions(result);
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<TransferNoteStoreEnv>(self);

    const setProductItems = (productItem: TransferNoteDetail) => {
      applySnapshot(self.productItemsByTransferNote, [
        ...self.productItemsByTransferNote,
        productItem,
      ]);
    };

    const setDefaultProductItems = (productItems: Array<TransferNoteDetail>) => {
      applySnapshot(self.productItemsByTransferNote, toJS(productItems));
    };

    const setDuplicateProduct = (index: number, value: number) => {
      self.productItemsByTransferNote[index].inputQuantity = value;
    };

    const deleteProductItems = (rowIndex: number) => {
      self.productItemsByTransferNote.splice(rowIndex, 1);
    };

    const getSkuOptionsByWarehouseAsync = flow(function* (warehouseId: string) {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(
          `warehouses/inventories/goods-batches?warehouseId=${warehouseId}`
        );
        applySnapshot(
          self.skuOptions,
          response.data.map((el: any) => {
            return { id: el.goods.id, code: el.goods.code };
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses');
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProductInformationByGoodsIdAsync = flow(function* (goodsId: string) {
      const loadingId = load('Get Product Detail By Goods Id Async');
      try {
        const response = yield http.get(`/goods/${goodsId}`);
        self.productInformation = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationOptionsByGoodsIdAsync = flow(function* (warehouseId: string, goodsId: string) {
      const loadingId = load('Get Location Async');
      try {
        const response = yield http.get('/warehouses/inventories/goods-batches', {
          params: {
            warehouseId,
            goodsId,
          },
        });

        const fetchLocationOptions = response.data.map((el: any) => el.location);
        applySnapshot(self.locationOptions, fetchLocationOptions);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBatchNumberOptionsAsync = flow(function* (
      warehouseId: string,
      goodsId: string,
      locationId: string
    ) {
      const loadingId = load('Get Batch Number Async');
      try {
        const response = yield http.get('/warehouses/inventories/goods-batches', {
          params: {
            warehouseId,
            goodsId,
            locationId,
          },
        });
        applySnapshot(
          self.batchNumberOptions,
          response.data
            .map((el: any) => {
              if (el.batchNumber) {
                return {
                  value: el.batchNumber,
                };
              }
              return;
            })
            .filter((x: any) => x)
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getStatusOptionsAsync = flow(function* (
      warehouseId: string,
      goodsId: string,
      locationId: string,
      batchNumber: string
    ) {
      const loadingId = load('Get Batch Number Async');
      try {
        const response = yield http.get('/warehouses/inventories/goods-batches', {
          params: {
            warehouseId,
            goodsId,
            locationId,
            batchNumber,
          },
        });
        applySnapshot(
          self.statusOptions,
          response.data
            .map((el: any) => {
              if (
                el.batchNumber &&
                el.status &&
                el.status !== StockStatus.BOOKING &&
                el.status !== StockStatus.COMMITTED &&
                el.status !== StockStatus.SOLD
              ) {
                return {
                  value: el.status,
                };
              }
              return;
            })
            .filter((x: any) => x)
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getStockQuantityAsync = flow(function* (
      warehouseId: string,
      goodsId: string,
      locationId: string,
      batchNumber: string,
      status: string
    ) {
      const loadingId = load('Get Stock Quantity Async');
      try {
        const response = yield http
          .get('/warehouses/inventories/goods-batches', {
            params: {
              warehouseId,
              goodsId,
              locationId,
              batchNumber,
              status,
            },
          })
          .then((res) => res.data[0]);

        self.variableQuantity = response;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createTransferNoteAsync = flow(function* (
      transferNote: typeof DefaultTransferNoteValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Transfer Note Async');
      try {
        yield http.post('/transfer-notes', transferNote);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateTransferNoteAsync = flow(function* (
      id: string,
      transferNote: typeof DefaultTransferNoteValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Transfer Note Async');
      try {
        yield http.put(`/transfer-notes/${id}`, transferNote);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatusTransferNoteAsync = flow(function* (
      id: string,
      status: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Status Transfer Note Async');
      try {
        yield http.put(`/transfer-notes/${id}/status`, { status });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setProductItems,
      setDefaultProductItems,
      getSkuOptionsByWarehouseAsync,
      getWarehouseOptionsAsync,
      getProductInformationByGoodsIdAsync,
      getLocationOptionsByGoodsIdAsync,
      getBatchNumberOptionsAsync,
      getStockQuantityAsync,
      createTransferNoteAsync,
      updateTransferNoteAsync,
      deleteProductItems,
      updateStatusTransferNoteAsync,
      getStatusOptionsAsync,
      setDuplicateProduct,
    };
  });

export default TransferNoteAddedStore;
