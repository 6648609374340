import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ProductInformation = types.model('Product List Model', {
  id: types.identifier,
  sku: types.string,
  productName: types.string,
  location: types.string,
  lotNumber: types.string,
  importQuantity: types.string,
  remainQuantity: types.string,
});

export interface ProductInformationInstance extends Instance<typeof ProductInformation> {}
export interface ProductInformationInputModel extends SnapshotIn<typeof ProductInformation> {}
export interface ProductInformation extends SnapshotOut<typeof ProductInformation> {}
