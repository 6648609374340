import { SnapshotIn, types } from 'mobx-state-tree';

export const ImportStockFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
});

export interface ImportStockFilterParams extends SnapshotIn<typeof ImportStockFilterParamsModel> {}
export const DefaultImportStockFilterParams: ImportStockFilterParams = {
  keyword: '',
  type: '',
  status: '',
  fromDate: null,
  toDate: null,
};
