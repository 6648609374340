import { createStoreContext } from '@vklink/libs-state';

import { type StockTakeVoucherStoreInstance } from './StockTakeVoucherStore';

const [StockTakeVoucherStoreProvider, useStockTakeVoucherStore] =
  createStoreContext<StockTakeVoucherStoreInstance>();

export { StockTakeVoucherStoreProvider, useStockTakeVoucherStore };

export {
  default as StockTakeVoucherStore,
  type StockTakeVoucherStoreEnv,
  type StockTakeVoucherStoreInstance,
} from './StockTakeVoucherStore';
