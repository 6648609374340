import { types } from '@vklink/libs-state';
import { TransferNoteInputModel } from './TransferNoteModel';

export const VariableQuantityModel = types.model('TransferNote Model', {
  id: types.identifier,
  realStockQuantity: types.number,
  stockQuantity: types.number,
  totalBCSQuantity: types.maybeNull(types.string),
});

export const DefaultTransferNoteValue: Partial<TransferNoteInputModel> = {
  exportWarehouseId: '',
  importWarehouseId: '',
  enteredDate: '',
  issuedDate: '',
  shippingMethod: '',
  reason: '',
  trnItems: [],
};
