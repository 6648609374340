import { SnapshotIn, types } from 'mobx-state-tree';

export const ProductFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface ProductFilterParams extends SnapshotIn<typeof ProductFilterParamsModel> {}
export const DefaultProductFilterParams: ProductFilterParams = {
  keyword: '',
};
