export enum InventoryReportType {
  INIT_QTY = 'INIT_QTY',
  FINAL_QTY = 'FINAL_QTY',
  IMPORT_QTY = 'IMPORT_QTY',
  EXPORT_QTY = 'EXPORT_QTY',
}

export enum ReferenceType {
  TRANSFER_NOTE = 'TRANSFER_NOTE', // 0
  PURCHASE_ORDER = 'PURCHASE_ORDER', // 1
  CMOVE = 'CMOVE', // 2
  ORDER = 'ORDER', // 3
  ORDER_PAYMENT = 'ORDER_PAYMENT', // 4
  ORDER_PICKUP = 'ORDER_PICKUP', // 4.1
  ORDER_DELIVERY = 'ORDER_DELIVERY', // 4.2
  ORDER_RETURN = 'ORDER_RETURN', // 5
  ORDER_REPLACEMENT = 'ORDER_REPLACEMENT', //6
  ORDER_CANCEL = 'ORDER_CANCEL', //7
  ADS_PROFILE = 'ADS_PROFILE', //8
  ADS_PROFILE_CANCEL = 'ADS_PROFILE_CANCEL', // 9
  CHANGE_STATUS = 'CHANGE_STATUS', //13
  ADJUSTMENT_NOTE = 'ADJUSTMENT_NOTE', //15
  INTERNAL_ADJUSTMENT = 'INTERNAL_ADJUSTMENT', //16
}
