import { types, Instance } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';

import {
  ImportStockListModel,
  ExportStockListModel,
  OrderStatusListModel,
  ReturnReplacementListModel,
  SkuListModel,
  StockTakeListModel,
  InventoryStore,
  InventoryDetailStore,
  CMoveListModel,
  ChangeStatusListModel,
} from './models';

export type ReportStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ReportStore = types.model('Report Store', {
  importStocks: types.optional(ImportStockListModel, {}),
  exportStocks: types.optional(ExportStockListModel, {}),
  orderStatus: types.optional(OrderStatusListModel, {}),
  returnReplacement: types.optional(ReturnReplacementListModel, {}),
  sku: types.optional(SkuListModel, {}),
  stockTake: types.optional(StockTakeListModel, {}),
  inventoryStore: types.optional(InventoryStore, {}),
  inventoryDetailStore: types.optional(InventoryDetailStore, {}),
  cMove: types.optional(CMoveListModel, {}),
  changeStatus: types.optional(ChangeStatusListModel, {}),
});

export default ReportStore;
export type ReportStoreInstance = Instance<typeof ReportStore>;
