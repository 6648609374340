import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { PrintLabelModel } from './models/PrintLabelModel';
import dataDemo from '../../../../../../admin-db.json';

export type PrintLabelStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const PrintLabelStore = types
  .model('PrintLabel Store', {
    productList: types.array(PrintLabelModel),
  })
  .actions((self) => {
    const { load, loaded } = getEnv<PrintLabelStoreEnv>(self);

    const getProductList = flow(function* () {
      const loadingId = load('Get PrintLabel Async');
      applySnapshot(self.productList, dataDemo['print-labels']);
      loaded(loadingId);
    });

    return { getProductList };
  });

export default PrintLabelStore;
export type PrintLabelStoreInstance = Instance<typeof PrintLabelStore>;
