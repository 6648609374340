import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const DashboardModel = types.model('Dashboard Model', {
  id: types.identifier,
  column1: types.string,
  column2: types.string,
  column3: types.string,
  column4: types.maybeNull(types.string),
  column5: types.maybeNull(types.string),
  column6: types.maybeNull(types.string),
  column7: types.maybeNull(types.string),
  column8: types.maybeNull(types.string),
});

export interface DashboardInstance extends Instance<typeof DashboardModel> {}
export interface DashboardInputModel extends SnapshotIn<typeof DashboardModel> {}
export interface Dashboard extends SnapshotOut<typeof DashboardModel> {}
