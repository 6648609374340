import { useMemo } from 'react';
import { Toolbar, IconButton, Box } from '@mui/material';
import {
  AccessTimeOutlined,
  MapsHomeWorkOutlined,
  LocationOnOutlined,
  GroupOutlined,
  ExitToAppOutlined,
  ShoppingCartOutlined,
  ChevronLeft,
  ChevronRight,
  FeedOutlined,
  LocalOfferOutlined,
  LocalShippingOutlined,
  OutputOutlined,
  Inventory2Outlined,
  InventoryOutlined,
  KeyboardReturnOutlined,
  FindReplaceOutlined,
  ReplyOutlined,
  DriveFileMoveOutlined,
  SettingsOutlined,
  PersonOutlineOutlined,
  MoreHorizOutlined,
} from '@mui/icons-material';
import { observer } from '@vklink/libs-state';
import {
  MenuItem,
  MenuItemType,
  MultipleLevelsMenu,
  CollapsibleDrawer,
} from '@vklink/components-shared';
import { ExportStockRoutes, Report } from '../models/routes';
import { StockTakeVoucherRoutes } from '../models/routes/StockTakeVoucher';

const Sidebar = () => {
  const menuItems = useMemo<Array<MenuItem>>(() => {
    return [
      {
        id: 'dashboardGroup',
        label: 'GENERAL',
        type: MenuItemType.Label,
      },
      {
        id: 'dashboard',
        label: 'Dashboard',
        type: MenuItemType.Link,
        href: '/',
        icon: <AccessTimeOutlined />,
      },
      {
        id: 'featuresGroup',
        label: 'Features',
        type: MenuItemType.Label,
      },
      {
        id: 'masterData',
        label: 'Master Data',
        type: MenuItemType.Link,
        icon: <SettingsOutlined />,
        childs: [
          {
            id: 'userManagement',
            label: 'User Management',
            type: MenuItemType.Link,
            icon: <PersonOutlineOutlined />,
          },
          {
            id: 'warehouseManagement',
            label: 'Warehouse Management',
            type: MenuItemType.Link,
            href: '/warehouse',
            icon: <MapsHomeWorkOutlined />,
          },
          {
            id: 'locationManagement',
            label: 'Location Management',
            type: MenuItemType.Link,
            href: '/location',
            icon: <LocationOnOutlined />,
          },
          // {
          //   id: 'category',
          //   label: 'Category Management',
          //   type: MenuItemType.Link,
          //   href: '/categories',
          //   icon: <CategoryOutlined />,
          // },
          {
            id: 'productManagement',
            label: 'Product Management',
            type: MenuItemType.Link,
            icon: <ShoppingCartOutlined />,
            href: '/products',
          },
          {
            id: 'poManagement',
            label: 'PO Management',
            type: MenuItemType.Link,
            href: '/purchase-orders',
            icon: <InventoryOutlined />,
          },
        ],
      },
      {
        id: 'prepairStock',
        label: 'Prepair Stock',
        type: MenuItemType.Link,
        icon: <MoreHorizOutlined />,
        childs: [
          {
            id: 'importStock',
            label: 'Import Stock',
            type: MenuItemType.Link,
            href: '/import-stocks',
            icon: <ExitToAppOutlined />,
          },
          {
            id: 'labelling',
            label: 'Labelling',
            type: MenuItemType.Link,
            href: '/print-label',
            icon: <LocalOfferOutlined />,
          },
          {
            id: 'CMove&ChangeStatus',
            label: 'C-Move & Change status',
            href: '/c-move-and-change-status',
            type: MenuItemType.Link,
            icon: <DriveFileMoveOutlined />,
          },
          {
            id: 'transferNote',
            label: 'TRN Management',
            type: MenuItemType.Link,
            href: '/transfer-notes',
            icon: <ShoppingCartOutlined />,
          },
        ],
      },
      {
        id: 'orders',
        label: 'Order Management',
        type: MenuItemType.Link,
        href: '/orders',
        icon: <FeedOutlined />,
        childs: [
          {
            id: 'returnOrder',
            label: 'Return Order',
            href: '/return-order',
            type: MenuItemType.Link,
            icon: <KeyboardReturnOutlined />,
          },
          {
            id: 'replaceOrder',
            label: 'Replace Order',
            href: '/replace-order',
            type: MenuItemType.Link,
            icon: <FindReplaceOutlined />,
          },
          {
            id: 'refundOrder',
            label: 'Refund Order',
            href: '/refund-order',
            type: MenuItemType.Link,
            icon: <ReplyOutlined />,
          },
        ],
      },
      {
        id: 'pickupAndDelivery',
        label: 'Pickup & Delivery',
        href: '/pickup-goods',
        type: MenuItemType.Link,
        icon: <LocalShippingOutlined />,
      },
      {
        id: 'exportStock',
        label: 'Export Stock',
        href: ExportStockRoutes.LIST,
        type: MenuItemType.Link,
        icon: <OutputOutlined />,
      },
      {
        id: 'internal',
        label: 'Internal Adjustment',
        type: MenuItemType.Link,
        icon: <GroupOutlined />,
        childs: [
          {
            id: 'departmentManagement',
            label: 'Department Management',
            href: '/internals/departments',
            type: MenuItemType.Link,
          },
          {
            id: 'reasonCodeManagement',
            label: 'Reason Code Management',
            href: '/internals/reason-codes',
            type: MenuItemType.Link,
          },
          {
            id: 'adjustmentManagment',
            label: 'Adjustment Management',
            href: '/internals/adjustments',
            type: MenuItemType.Link,
          },
        ],
      },
      {
        id: 'stockTake',
        label: 'Stock Take',
        type: MenuItemType.Link,
        href: StockTakeVoucherRoutes.LIST,
        icon: <Inventory2Outlined />,
      },
      {
        id: 'report',
        label: 'Report',
        type: MenuItemType.Link,
        icon: <InventoryOutlined />,
        childs: [
          {
            id: 'inventoryReport',
            label: 'Inventory Report',
            href: Report.Inventory,
            type: MenuItemType.Link,
          },
          {
            id: 'importStockReport',
            label: 'Import Stock Report',
            href: Report.ImportStock,
            type: MenuItemType.Link,
          },
          {
            id: 'exportStockReport',
            label: 'Export Stock Report',
            href: Report.ExportStock,
            type: MenuItemType.Link,
          },
          {
            id: 'CMoveReport',
            label: 'C-Move Report',
            href: Report.CMove,
            type: MenuItemType.Link,
          },
          {
            id: 'changeStatusReport',
            label: 'Change Status Report',
            href: Report.ChangeStatus,
            type: MenuItemType.Link,
          },
          {
            id: 'orderStatusReport',
            label: 'Order Status Report',
            href: Report.OrderStatus,
            type: MenuItemType.Link,
          },
          {
            id: 'replacementReturnReport',
            label: 'Return & Replacement Report',
            href: Report.ReturnReplacement,
            type: MenuItemType.Link,
          },
          {
            id: 'skuReport',
            label: 'SKU Report',
            href: Report.Sku,
            type: MenuItemType.Link,
          },
          {
            id: 'stockTakeReport',
            label: 'Stock Take Report',
            href: Report.StockTake,
            type: MenuItemType.Link,
          },
        ],
      },
    ];
  }, []); //re-render when change local state

  return (
    <CollapsibleDrawer width={380}>
      {({ isOpen, onToggleClick, isExpand }) => {
        return (
          <>
            <Toolbar />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
            >
              <IconButton
                sx={{
                  position: isOpen || isExpand ? 'absolute' : 'unset',
                  top: `${64 + 16 - (40 - 32) / 2}px`,
                  right: 25,
                  zIndex: 1,
                  w: 40,
                  h: 40,
                  alignSelf: 'center',
                  mb: isOpen || isExpand ? 0 : 1,
                  mt: !(isOpen || isExpand) ? 1 : 0,
                }}
                onClick={onToggleClick}
              >
                {!isOpen ? (
                  <ChevronRight
                    sx={{
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                ) : (
                  <ChevronLeft
                    sx={{
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                )}
              </IconButton>
              <MultipleLevelsMenu items={menuItems} isDrawerOpen={isOpen || isExpand} />
            </Box>
          </>
        );
      }}
    </CollapsibleDrawer>
  );
};

export default observer(Sidebar);
