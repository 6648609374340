import { types, Instance, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  ReturnOrderModel,
  ReturnOrderFilterParamsModel,
  DefaultReturnOrderFilterParams,
  ReturnOrderFilterParams,
  ReturnOrderDetailModel,
  ProductItemModel,
  ReturnOrderDetail,
  OrderItem,
  OrderItemModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';

import { dayjs, ErrMsg, FormatDate, OrderType } from 'enums';
import { removeEmptyInObject } from 'pages/shared/utils';

export type ReturnOrderStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ReturnOrderStore = types
  .model('ReturnOrder Store', {
    returnOrders: types.optional(types.array(ReturnOrderModel), []),
    filterParams: types.optional(ReturnOrderFilterParamsModel, DefaultReturnOrderFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    returnOrderDetail: types.maybe(ReturnOrderDetailModel),
    listOldProductItems: types.optional(types.array(ProductItemModel), []),
    errMsg: types.maybeNull(types.string),
    selectedProduct: types.maybeNull(OrderItemModel),
  })
  .views((self) => {
    return {
      get returnOrderList() {
        return getSnapshot(self.returnOrders);
      },
      get getReturnOrderDetail() {
        return self.returnOrderDetail;
      },
      get getQueryParams() {
        return {
          ...self.filterParams,
          ...self.paginationParams,
        };
      },
      get getListProductItems() {
        return self.returnOrderDetail ? self.returnOrderDetail?.items : [];
      },
      get getErrMsg() {
        return self.errMsg;
      },
      get getListOldProductItems() {
        return getSnapshot(self.listOldProductItems);
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ReturnOrderStoreEnv>(self);

    const setQueryParams = (filterParams: ReturnOrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultReturnOrderFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getReturnOrdersAsync = flow(function* () {
      const loadingId = load('Get ReturnOrder Async');
      const response = yield http.get('/orders', {
        params: {
          ...self.getQueryParams,
          type: OrderType.RETURN,
        },
      });
      applySnapshot(self.returnOrders, []);
      applySnapshot(self.returnOrders, [...response.data]);
      applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const getReturnOrderByIdAsync = flow(function* (id?: string) {
      const loadingId = load('Get Order By Id');

      const response = yield http.get(`/orders/${id}?type=${OrderType.RETURN}`);

      self.returnOrderDetail = response.data;
      applySnapshot(self.listOldProductItems, []);

      loaded(loadingId);
    });

    const validateDuplicateBarcode = (barcode: string) => {
      const data = self.listOldProductItems.filter((x) => x.barcode == barcode);
      if (data.length > 0) {
        return true;
      }

      return false;
    };

    const setOldProductItemByScanBarcodeAsync = flow(function* (
      barcode: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Scan Old Product Item Async');
      try {
        const orderNumber = self.returnOrderDetail?.orderNumber;
        const checkDuplicateBarcodeReturned = yield http.get(
          `orders/${orderNumber}/check-duplicate-old-barcode/${barcode}`
        );

        const checkDuplicateBarcodeInListOld = validateDuplicateBarcode(barcode);

        if (checkDuplicateBarcodeReturned.data || checkDuplicateBarcodeInListOld) {
          self.errMsg = ErrMsg.DUPLICATE_BARCODE;
          cb?.error && cb.error(self.errMsg);
          return;
        }

        const response = yield http.get(`goods/scan-old-product/${barcode}/${orderNumber}`);
        const productItem = self.getListProductItems.find(
          (x) =>
            x.goodsCode == response.data.goodsCode &&
            x.goodsSetCode == (response.data.goodsSetCode ?? '') &&
            x.remaningQuantity > 0
        );

        if (!productItem) {
          self.errMsg = ErrMsg.OLD_QUANTITY_REPLACE_EXCEED;
          cb?.error && cb.error(self.errMsg);
          return;
        }

        const oldProductdata = {
          goodsSetCode: productItem?.goodsSetCode ? productItem?.goodsSetCode : null,
          goodsSetName: productItem?.goodsName ? productItem?.goodsSetName : null,
          goodsCode: response.data.goodsCode,
          goodsName: response.data.goodsName,
          barcode: response.data.barcodeNumber,
          quantity: 1,
        };

        productItem.remaningQuantity--;
        console.log(self.getListProductItems);

        applySnapshot(self.listOldProductItems, [...self.listOldProductItems, oldProductdata]);
        cb?.success && cb.success();
      } catch (error) {
        const sku = barcode.substring(0, 9);
        self.errMsg = ErrMsg.BARCODE_NOT_EXIST.replace('{}', sku);
        cb?.error && cb.error(self.errMsg);
      } finally {
        loaded(loadingId);
      }
    });

    const setErrMsg = (msg: string) => {
      self.errMsg = msg;
    };

    const deleteOldProductItem = (rowIndex: number) => {
      const indexInOrder = self.getListProductItems.findIndex(
        (x) =>
          x.goodsCode == self.listOldProductItems[rowIndex].goodsCode &&
          x.goodsSetCode === (self.listOldProductItems[rowIndex].goodsSetCode ?? '')
      );
      self.getListProductItems[indexInOrder].remaningQuantity += Number(
        self.listOldProductItems[rowIndex].quantity
      );
      self.listOldProductItems.splice(rowIndex, 1);
    };

    const saveReturnFullOrder = flow(function* (
      returnFullData: ReturnOrderDetail,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Return Full Order');
      try {
        yield http.post(`/stocks/return-full-order`, removeEmptyInObject(returnFullData));
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const cancelReturnOrder = flow(function* (orderNumber: string, cb?: RequestCallback) {
      const loadingId = load('Cancel Return Order');
      const data = {
        orderNumber: orderNumber,
      };
      try {
        yield http.post(`/stocks/cancel-return-order`, data);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const setSelectedProduct = (selectedProduct: OrderItem) => {
      self.selectedProduct = { ...selectedProduct };
    };

    const setOldProductItemByManual = (selectedProduct: OrderItem, quantity: number) => {
      const checkExistSku = self.listOldProductItems.find(
        (x) =>
          x.goodsCode == selectedProduct.goodsCode && x.goodsSetCode == selectedProduct.goodsSetCode
      );
      if (checkExistSku && checkExistSku.barcode == '') {
        const newQuantity = (checkExistSku?.quantity as number) + Number(quantity);
        checkExistSku.quantity = newQuantity;
      } else {
        const oldProductdata = {
          goodsSetCode: selectedProduct.goodsSetCode ? selectedProduct.goodsSetCode : '',
          goodsSetName: selectedProduct.goodsSetName ? selectedProduct.goodsSetName : '',
          goodsCode: selectedProduct.goodsCode ? selectedProduct.goodsCode : '',
          goodsName: selectedProduct.goodsName ? selectedProduct.goodsName : '',
          barcode: '',
          quantity: Number(quantity),
          reason: '',
          deductPercent: 0,
        };

        applySnapshot(self.listOldProductItems, [...self.listOldProductItems, oldProductdata]);
      }

      const indexInOrder = self.getListProductItems.findIndex(
        (x) =>
          x.goodsCode == selectedProduct.goodsCode && x.goodsSetCode == selectedProduct.goodsSetCode
      );
      self.getListProductItems[indexInOrder].remaningQuantity -= quantity;
    };

    const validateReturnPartData = (oldItems: any) => {
      let flag = true;
      oldItems.map((item: any) => {
        if (item.goodsSetCode) {
          const goodsSetInOrders = self.getListProductItems.filter(
            (x) => x.goodsSetCode == item.goodsSetCode
          );
          goodsSetInOrders.map((goodsSetInOrder) => {
            const totalQty = oldItems
              .filter(
                (f: any) =>
                  f.goodsCode === goodsSetInOrder.goodsCode && f.goodsSetCode == item.goodsSetCode
              )
              .reduce((a: number, b: any) => a + (b.quantity || 0), 0);
            if (totalQty == 0) {
              flag = false;
            }
          });
        }
      });
      return flag;
    };

    const saveReturnPartOrder = flow(function* (
      returnOrderDetail: ReturnOrderDetail,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Return Part Order');
      try {
        const returnDate = dayjs(returnOrderDetail.returnDate).format(FormatDate.DATE);
        const listOldItems = self.listOldProductItems.map((item, index) => {
          return {
            ...item,
            deductPercent:
              returnOrderDetail?.oldItems && returnOrderDetail?.oldItems[index]?.deductPercent,
            reason: returnOrderDetail?.oldItems && returnOrderDetail?.oldItems[index]?.reason,
          };
        });

        if (!validateReturnPartData(listOldItems)) {
          cb?.error && cb.error('Need to return all products of a set');
          return;
        }

        const data = {
          orderNumber: returnOrderDetail.orderNumber,
          returnDate: returnDate,
          oldItems: listOldItems,
        };
        yield http.post(`/stocks/return-part-order`, removeEmptyInObject(data));
        cb?.success && cb.success();
      } catch (err: any) {
        cb?.error && cb.error(err.response.data.title);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      setPaginationParams,
      getReturnOrdersAsync,
      getReturnOrderByIdAsync,
      setOldProductItemByScanBarcodeAsync,
      setErrMsg,
      deleteOldProductItem,
      saveReturnFullOrder,
      cancelReturnOrder,
      setSelectedProduct,
      setOldProductItemByManual,
      saveReturnPartOrder,
    };
  });

export default ReturnOrderStore;
export type ReturnOrderStoreInstance = Instance<typeof ReturnOrderStore>;
