export enum ImportStockType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  TRANSFER_NOTE = 'TRANSFER_NOTE',
}

export enum ImportStockStatus {
  INTRANSIT = 'INTRANSIT',
  COMPLETED_ABNORMAL = 'COMPLETED_ABNORMAL',
  INPUT_PARTIAL = 'INPUT_PARTIAL',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum ImportStockPart {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}
