import { createStoreContext } from '@vklink/libs-state';
import { type DepartmentStoreInstance } from './DepartmentStore';

const [DepartmentStoreProvider, useDepartmentStore] = createStoreContext<DepartmentStoreInstance>();

export { DepartmentStoreProvider, useDepartmentStore };

export {
  default as DepartmentStore,
  type DepartmentStoreEnv,
  type DepartmentStoreInstance,
} from './DepartmentStore';
