import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { TransferNoteModel } from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import {
  DefaultTransferNoteFilterParams,
  TransferNoteFilterParams,
  TransferNoteFilterParamsModel,
} from './models';

import TransferNoteAddedStore from './TransferNoteAddedStore';
import { removeEmptyInObject } from 'pages/shared/utils';

export type TransferNoteStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const TransferNoteStore = types
  .compose(
    TransferNoteAddedStore,
    types.model('Transfer Note Store', {
      transferNotes: types.array(TransferNoteModel),
      paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
      pagination: types.optional(PaginationModel, DefaultPaginationInfo),
      filterParams: types.optional(TransferNoteFilterParamsModel, DefaultTransferNoteFilterParams),
      transferNoteDetail: types.maybe(TransferNoteModel),
    })
  )
  .views((self) => {
    return {
      get transferNoteList() {
        return getSnapshot(self.transferNotes);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getTransferNoteDetail() {
        return self.transferNoteDetail;
      },
      get getListProductItems() {
        return self.transferNoteDetail
          ? getSnapshot(self.transferNoteDetail?.transferNoteItems)
          : [];
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<TransferNoteStoreEnv>(self);

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setQueryParams = (filterParams: TransferNoteFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultTransferNoteFilterParams, ...filterParams });
    };

    const getTransferNotesAsync = flow(function* () {
      const loadingId = load('Get Transfer Note Async');
      try {
        const response = yield http.get('/transfer-notes', {
          params: self.getQueryParams,
        });
        applySnapshot(self.transferNotes, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    const getTransferNoteByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Transfer Note Detail Async');
      try {
        const response = yield http.get(`/transfer-notes/${id}`);
        self.transferNoteDetail = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      setPaginationParams,
      getTransferNotesAsync,
      getTransferNoteByIdAsync,
    };
  });

export default TransferNoteStore;
export type TransferNoteStoreInstance = Instance<typeof TransferNoteStore>;
