import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { DummyDataModel } from 'stores/models';
import { ReportStoreEnv } from '../../Store';
import {
  DefaultReturnReplacementReportFilterParams,
  ReturnReplacementReportFilterParamsModel,
} from './ReturnReplacementReportFilterParamsModel';

import dataDemo from '../../../../../../../../admin-db.json';

export const ReturnReplacementListModel = types
  .model({
    items: types.optional(types.array(DummyDataModel), []),
    filterParams: types.optional(
      ReturnReplacementReportFilterParamsModel,
      DefaultReturnReplacementReportFilterParams
    ),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get itemList() {
        return getSnapshot(self.items);
      },
    };
  })
  .actions((self) => {
    const { load, loaded } = getEnv<ReportStoreEnv>(self);

    const getAsync = flow(function* () {
      const loadingId = load('Get Return & Replacement Report List Async');
      applySnapshot(self.items, new Array(10).fill(dataDemo.returnReplacementReportItem));
      loaded(loadingId);
    });

    return { getAsync };
  });

export type ReturnReplacementReportStoreInstance = Instance<typeof ReturnReplacementListModel>;
