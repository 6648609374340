import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { DepartmentStore, DepartmentStoreInstance, DepartmentStoreProvider } from './stores';
import { PageLayout } from 'pages/shared/layout';

const DepartmentLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<DepartmentStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(DepartmentStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);
  return (
    <DepartmentStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </DepartmentStoreProvider>
  );
};

export default DepartmentLayout;
