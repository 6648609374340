import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import {
  DefaultProductInventoryFilterParams,
  ProductInventoryFilterParams,
  ProductInventoryFilterParamsModel,
  ProductInventoryModel,
} from './models';
import { LocationOptionsModel, WarehouseOptionsModel } from 'pages/shared/models/common-options';
import { GOODS_API, LOCATION_API, WAREHOUSE_API } from 'api';

export type ProductInventoryStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProductInventoryStore = types
  .model('Product Inventory Store', {
    productInventories: types.array(ProductInventoryModel),
    warehouseOptions: types.array(WarehouseOptionsModel),
    locationOptions: types.array(LocationOptionsModel),
    filterParamsProductInventory: types.optional(
      ProductInventoryFilterParamsModel,
      DefaultProductInventoryFilterParams
    ),
    paginationParamsProductInventory: types.optional(
      PaginationParamsModel,
      DefaultPaginationParams
    ),
    paginationProductInventory: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get productInventoryList() {
        return getSnapshot(self.productInventories);
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getLocationOptions() {
        return self.locationOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getQueryParamsProductInventory() {
        return removeEmptyInObject({
          ...self.filterParamsProductInventory,
          ...self.paginationParamsProductInventory,
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ProductInventoryStoreEnv>(self);

    const setQueryParamsProductInventory = (filterParams: ProductInventoryFilterParams) => {
      applySnapshot(self.filterParamsProductInventory, {
        ...DefaultProductInventoryFilterParams,
        ...filterParams,
      });
    };

    const setPaginationParamsProductInventory = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParamsProductInventory, {
        ...DefaultPaginationParams,
        ...paginationParams,
      });
    };

    const getProductInventoriesAsync = flow(function* (id: string, cb?: RequestCallback) {
      const loadingId = load('Get Product Inventories Async');
      try {
        const response = yield http.get(`${GOODS_API.GET_PRODUCT_INVENTORIES}?goodsId=${id}`, {
          params: self.getQueryParamsProductInventory,
        });
        applySnapshot(self.productInventories, []);
        applySnapshot(self.productInventories, response.data);
        applySnapshot(self.paginationProductInventory, response.metadata.pagination);
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Options Async');
      try {
        const response = yield http.get(WAREHOUSE_API.GET_WAREHOUSES);
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationOptionsAsync = flow(function* (warehouseId: string) {
      const loadingId = load('Get Location Options Async');
      try {
        const response = yield http.get(`${LOCATION_API.GET_LOCATIONS}?warehouseId=${warehouseId}`);
        applySnapshot(self.locationOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParamsProductInventory,
      getProductInventoriesAsync,
      getWarehouseOptionsAsync,
      getLocationOptionsAsync,
      setPaginationParamsProductInventory,
    };
  });

export default ProductInventoryStore;
export type ProductInventoryStoreInstance = Instance<typeof ProductInventoryStore>;
