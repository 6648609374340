import { SnapshotIn, types } from 'mobx-state-tree';

export const StockTakeInputInventoryFilterParamsModel = types.model({
  locationId: types.optional(types.string, ''),
  goodsId: types.optional(types.string, ''),
});

export interface StockTakeInputInventoryFilterParams
  extends SnapshotIn<typeof StockTakeInputInventoryFilterParamsModel> {}
export const DefaultStockTakeInputInventoryFilterParams: StockTakeInputInventoryFilterParams = {
  locationId: '',
  goodsId: '',
};
