import { WarehouseType, WarehouseStatus } from 'enums';
export const WarehouseTypeOptions: SelectOption[] = [
  {
    label: 'Online',
    value: WarehouseType.ONLINE,
  },
  {
    label: 'Offline',
    value: WarehouseType.OFFLINE,
  },
  {
    label: 'NTBU',
    value: WarehouseType.NTBU,
  },
];

export const WarehouseStatusOptions: SelectOption[] = [
  {
    label: 'Active',
    value: WarehouseStatus.ACTIVE,
  },
  {
    label: 'Inactive',
    value: WarehouseStatus.INACTIVE,
  },
];
