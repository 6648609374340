import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultDepartmentFilterParams,
  DepartmentFilterParams,
  DepartmentFilterParamsModel,
  DepartmentModel,
  Department,
  DefaultDepartmentValue,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';

import dataDemo from '../../../../../../admin-db.json';

export type DepartmentStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const DepartmentStore = types
  .model('Department Store', {
    departments: types.array(DepartmentModel),
    departmentDetail: types.maybe(DepartmentModel),
    filterParams: types.optional(DepartmentFilterParamsModel, DefaultDepartmentFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get DepartmentList() {
        return self.departments;
      },
      get getQueryParams() {
        return {
          ...self.filterParams,
          ...self.paginationParams,
        };
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<DepartmentStoreEnv>(self);

    const setQueryParams = (filterParams: DepartmentFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultDepartmentFilterParams, ...filterParams });
    };

    const getDepartmentsAsync = flow(function* () {
      const loadingId = load('Get Department Async');
      // const response = yield http.get('/departments', {
      //   params: self.getQueryParams,
      // });

      //applySnapshot(self.departments, [...response.data]);
      applySnapshot(self.departments, dataDemo.departments);
      // applySnapshot(self.pagination, response.metadata.pagination);
      loaded(loadingId);
    });

    const createDepartment = flow(function* (
      department?: typeof DefaultDepartmentValue,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Department');
      try {
        yield http.post('/departments', department);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editDepartment = flow(function* (
      department?: typeof DefaultDepartmentValue,
      id?: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Edit Department');
      try {
        yield http.put(`/departments/${id}`, { ...department, id });
        applySnapshot(self.departments, []); //Re-define memory of list Departments
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getDepartmentById = flow(function* (id: string) {
      const loadingId = load('Get Department By Id');
      const response = yield http.get(`/departments/${id}`);
      self.departmentDetail = response.data;
      loaded(loadingId);
    });

    const setDepartmentDetail = (Department?: Department) => {
      const DepartmentDetail: any = Department;
      self.departmentDetail = { ...DepartmentDetail };
    };

    const deleteDepartment = flow(function* (id: number) {
      const loadingId = load('Delete Department');
      try {
        yield http.delete(`/departments/${id}`);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getDepartmentsAsync,
      createDepartment,
      editDepartment,
      getDepartmentById,
      deleteDepartment,
      setQueryParams,
      setDepartmentDetail,
    };
  });

export default DepartmentStore;
export type DepartmentStoreInstance = Instance<typeof DepartmentStore>;
