import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const ScanBarcodeModel = types.model({
  returnMethod: types.string,
  oldBarcode: types.string,
  newBarcode: types.string,
  sku: types.maybe(types.string),
  productName: types.string,
  quantity: types.maybeNull(types.number),
  remainingQuantity: types.number,
});

export interface ScanBarcodeModelInstance extends Instance<typeof ScanBarcodeModel> {}
export interface ScanBarcodeModelInputModel extends SnapshotIn<typeof ScanBarcodeModel> {}
export interface ScanBarcode extends SnapshotOut<typeof ScanBarcodeModel> {}
