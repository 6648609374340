export const groupItemsWithKeys = (items: any[], keys: string[]) => {
  const helper: any = {};
  const result = items.reduce((acc, item) => {
    const key = keys.map((k) => item[k]).join('-');
    if (!helper[key]) {
      helper[key] = { ...item };
      helper[key].count = 1;
      acc.push(helper[key]);
    } else {
      helper[key].count += 1;
    }
    return acc;
  }, []);

  return result;
};

export const countTotalInArray = (array: any, key: string) => {
  return array.reduce((a: any, b: any) => a + (b[key] || 0), 0);
};
