import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const DepartmentModel = types.model('Department Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
  description: types.string,
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export const DefaultDepartmentValue: Partial<DepartmentInputModel> = {
  code: 'CS',
  name: 'Customer Service',
  description: 'Customer Service Department',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
};

export interface DepartmentInstance extends Instance<typeof DepartmentModel> {}
export interface DepartmentInputModel extends SnapshotIn<typeof DepartmentModel> {}
export interface Department extends SnapshotOut<typeof DepartmentModel> {}
