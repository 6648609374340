import { OrderStatus } from 'enums';

export const OrderStatusOptions: SelectOption[] = [
  {
    label: 'Waiting Confirmation',
    value: OrderStatus.WAITING_ORDER,
  },
  {
    label: 'Confirmed',
    value: OrderStatus.CONFIRMED_ORDER,
  },
  {
    label: 'Picked',
    value: OrderStatus.PICKED_UP_ORDER,
  },
  {
    label: 'Exported',
    value: OrderStatus.EXPORTED_ORDER,
  },
  {
    label: 'Shipping',
    value: OrderStatus.EXPORTED_ORDER,
  },
  {
    label: 'Deliveried',
    value: OrderStatus.RECEIVED_ORDER,
  },
  {
    label: 'Full Return',
    value: OrderStatus.FULL_RETURN_ORDER,
  },
  {
    label: 'Partial Return',
    value: OrderStatus.PARTIAL_RETURN_ORDER,
  },
  {
    label: 'Replacement',
    value: OrderStatus.REPLACEMENT_ORDER,
  },
  {
    label: 'Cancel',
    value: OrderStatus.CANCEL_ORDER,
  },
];
