import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ChangeStatusModel = types.model('Change Status Model', {
  id: types.identifier,
  warehouse: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
  }),
  goods: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
  }),
  location: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
  }),
  batchNumber: types.string,
  status: types.string,
  stockQuantity: types.integer,
  realStockQuantity: types.integer,
  goodsId: types.string,
  warehouseName: types.maybeNull(types.string),
  goodsCode: types.maybeNull(types.string),
  goodsName: types.maybeNull(types.string),
  locationName: types.maybeNull(types.string),
  currentStatus: types.maybeNull(types.string),
  newStatus: types.maybeNull(types.string),
  quantity: types.maybeNull(types.integer),
});

export const DefaultChangeStatusValue: Partial<ChangeStatusInputModel> = {
  goodsId: '',
  batchNumber: '',
  currentStatus: '',
  newStatus: '',
  quantity: 0,
};

export interface ChangeStatusInstance extends Instance<typeof ChangeStatusModel> {}
export interface ChangeStatusInputModel extends SnapshotIn<typeof ChangeStatusModel> {}
export interface ChangeStatus extends SnapshotOut<typeof ChangeStatusModel> {}
