import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultStockTakeVoucherValue,
  StockTakeVoucher,
  StockTakeVoucherInputModel,
  StockTakeVoucherList,
  StockTakeVoucherListModel,
  StockTakeVoucherModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import {
  DefaultStockTakeVoucherFilterParams,
  StockTakeVoucherFilterParams,
  StockTakeVoucherFilterParamsModel,
} from './models';
import { groupItemsWithKeys, removeEmptyInObject } from 'pages/shared/utils';
import { WarehouseModel } from 'pages/warehouse-management/stores/models';
import dayjs from 'dayjs';
import { AdjustmentStatus, FormatDate } from 'enums';
import { ORDER_API, ResponseGenerator, STOCK_API, STOCK_TAKE_API } from 'api';
import { generatePath } from 'react-router';
import {
  DefaultStockTakeInputInventoryFilterParams,
  StockTakeInputInventoryFilterParams,
  StockTakeInputInventoryFilterParamsModel,
} from './models/StockTakeInputInventoryFilterParamsModel';

export type StockTakeVoucherStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const StockTakeVoucherStore = types
  .model('StockTakeVoucher Store', {
    stockTakeVoucher: types.optional(StockTakeVoucherModel, DefaultStockTakeVoucherValue),
    stockTakeVouchers: types.optional(types.array(StockTakeVoucherListModel), []),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    filterParams: types.optional(
      StockTakeVoucherFilterParamsModel,
      DefaultStockTakeVoucherFilterParams
    ),
    inputInventoryFilterParams: types.optional(
      StockTakeInputInventoryFilterParamsModel,
      DefaultStockTakeInputInventoryFilterParams
    ),
    warehouses: types.array(WarehouseModel),
  })
  .views((self) => {
    return {
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouses.map((el: any) => {
          return { value: el.id, label: el.name };
        });
      },
      get getStockTakeVouchers() {
        return getSnapshot<StockTakeVoucherList[]>(self.stockTakeVouchers);
      },
      get getStockTakeVoucher() {
        if (self.stockTakeVoucher) {
          return getSnapshot<StockTakeVoucher>(self.stockTakeVoucher);
        }
        return DefaultStockTakeVoucherValue;
      },
      get getLocationOptions() {
        const locations = self.stockTakeVoucher?.items.map((el: any) => {
          return { value: el.locationId, label: el.locationName };
        });
        return groupItemsWithKeys(locations, ['value']);
      },
      get getProductOptions() {
        const products = self.stockTakeVoucher?.items.map((el: any) => {
          return { value: el.goodsId, label: el.goodsCode + ' - ' + el.goodsName };
        });
        return groupItemsWithKeys(products, ['value']);
      },
      get isInputInventory() {
        return self.stockTakeVoucher.inputDate ? false : true;
      },
      get canConfirm() {
        return self.stockTakeVoucher?.status === AdjustmentStatus.INIT;
      },
      get canCancel() {
        return self.stockTakeVoucher.status === AdjustmentStatus.INIT;
      },
      get canApprove() {
        return self.stockTakeVoucher.status === AdjustmentStatus.IN_PROCESS;
      },
      get canReject() {
        return self.stockTakeVoucher.status === AdjustmentStatus.IN_PROCESS;
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<StockTakeVoucherStoreEnv>(self);

    const resetStockTakeVoucher = () => {
      applySnapshot(self.stockTakeVoucher, DefaultStockTakeVoucherValue);
    };

    const getCurrentStockTakeVoucherAsync = flow(function* (warehouseId: string) {
      const loadingId = load('Get Stock Take Voucher Async');
      try {
        const currentStockTakeResponse: ResponseGenerator = yield http.get(
          STOCK_TAKE_API.GET_CURRENT_STOCK_TAKE,
          {
            params: { warehouseId },
          }
        );
        if (currentStockTakeResponse.status == 200) {
          const currentStockTake = currentStockTakeResponse.data;
          if (currentStockTake) {
            applySnapshot(self.stockTakeVoucher, {
              ...currentStockTake,
              issueDate: dayjs(currentStockTake.issueDate).format(FormatDate.DATE_TIME),
            });
          } else {
            applySnapshot(self.stockTakeVoucher, DefaultStockTakeVoucherValue);
            const getOrderResult = yield getLatestOrderInfomationAsync(warehouseId);
            const getInventoryResult = yield getAllInventoryAsync(warehouseId);
            if (getOrderResult.error || getInventoryResult.error) {
              return { error: getOrderResult.error || ' ' + getInventoryResult.error || '' };
            }
          }
          return {};
        } else {
          return {
            error: 'Get Stock Take Voucher Async Error',
          };
        }
      } catch (err) {
        console.log(err);
        return {
          error: 'Get Stock Take Voucher Async Error',
        };
      } finally {
        loaded(loadingId);
      }
    });

    const getStockTakeVoucherDetailAsync = flow(function* (id: string, isViewDetail = false) {
      const loadingId = load('Get Stock Take Voucher Detail Async');
      try {
        const stockTakeResponse: ResponseGenerator = yield http.get(
          generatePath(STOCK_TAKE_API.GET_STOCK_TAKE_DETAIL, { id }),
          {
            params: {
              type: isViewDetail ? 'view' : null,
            },
          }
        );
        if (stockTakeResponse.status == 200) {
          const currentStockTake = stockTakeResponse.data;
          if (currentStockTake) {
            applySnapshot(self.stockTakeVoucher, {
              ...currentStockTake,
              issueDate: dayjs(currentStockTake.issueDate).format(FormatDate.DATE_TIME),
            });
          }
          return {};
        } else {
          return {
            error: 'Get Stock Take Voucher Detail Error',
          };
        }
      } catch (err) {
        console.log(err);
        return {
          error: 'Get Stock Take Voucher Detail Error',
        };
      } finally {
        loaded(loadingId);
      }
    });

    const getStockTakeVouchersAsync = flow(function* () {
      const loadingId = load('Get Stock Take Vouchers Async');
      try {
        const response = yield http.get(STOCK_TAKE_API.STOCK_TAKE, {
          params: {
            ...self.filterParams,
            ...self.paginationParams,
          },
        });
        applySnapshot(self.stockTakeVouchers, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
        return {
          error: 'Get Stock Take Vouchers Async Error',
        };
      } finally {
        loaded(loadingId);
      }
      return {};
    });

    const getLatestOrderInfomationAsync = flow(function* (warehouseId: string) {
      const loadingId = load('Get Latest Order Infomation Async');
      try {
        const latestOrderResponse: ResponseGenerator = yield http.get(
          ORDER_API.GET_LATEST_ORDER_INFOMATION,
          { params: { warehouseId } }
        );
        if (latestOrderResponse.status == 200) {
          const latestOrder = latestOrderResponse.data;
          if (latestOrder) {
            self.stockTakeVoucher.warehouseId = warehouseId;
            self.stockTakeVoucher.lastOrder = latestOrder.lastOrder;
            self.stockTakeVoucher.lastAds = latestOrder.lastAds;
            self.stockTakeVoucher.lastAdjustment = latestOrder.lastAdjustment;
            self.stockTakeVoucher.lastTransferNote = latestOrder.lastTransferNote;
          }
          return {};
        } else {
          return {
            error: 'Error when get latest order infomation',
          };
        }
      } catch (err) {
        console.log(err);
        return {
          error: 'Error when get latest order infomation',
        };
      } finally {
        loaded(loadingId);
      }
    });

    const getAllInventoryAsync = flow(function* (warehouseId: string) {
      const loadingId = load('Get All Inventory Async');
      try {
        const allInventoryResponse: ResponseGenerator = yield http.get(
          STOCK_API.GET_ALL_INVENTORY,
          { params: { warehouseId } }
        );
        if (allInventoryResponse.status == 200) {
          const allInventories = allInventoryResponse.data;
          if (allInventories) {
            self.stockTakeVoucher.items = allInventories.map((el: any) => {
              return {
                ...el,
                id: el.id ?? '',
                goodsSellPrice: el.goodsSellPrice ? parseFloat(el.goodsSellPrice) : 0,
                goodsOriginPrice: el.goodsOriginPrice ? parseFloat(el.goodsOriginPrice) : 0,
              };
            });
          }
        } else {
          return {
            error: 'Error when get all inventory',
          };
        }
      } catch (err) {
        console.log(err);
        return {
          error: 'Error when get all inventory',
        };
      } finally {
        loaded(loadingId);
      }
      return {};
    });

    const setQueryParams = (filterParams: StockTakeVoucherFilterParams) => {
      applySnapshot(self.filterParams, {
        ...DefaultStockTakeVoucherFilterParams,
        ...filterParams,
      });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getWarehousesAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses', {
          params: {},
        });
        applySnapshot(self.warehouses, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createStockTakeVoucherAsync = flow(function* () {
      const loadingId = load('Save Stock Take Voucher Async');
      try {
        const response = yield http.post(STOCK_TAKE_API.STOCK_TAKE, {
          ...self.getStockTakeVoucher,
        });
        if (response.status >= 200 && response.status < 300) {
          const data = response.data;
          if (data && data.error) {
            return {
              error: data.message,
            };
          }
          self.stockTakeVoucher.id = data.id;
          self.stockTakeVoucher.code = data.code;
          self.stockTakeVoucher.status = data.status;
          self.stockTakeVoucher.issueDate = dayjs(data.issueDate).format(FormatDate.DATE_TIME);
          return {};
        } else {
          return {
            error: 'Error when save stock take voucher',
          };
        }
      } catch (err) {
        return {
          error: 'Error when save stock take voucher',
        };
      } finally {
        loaded(loadingId);
      }
    });

    const updateStockTakeVoucherAsync = flow(function* (
      id: string,
      values: StockTakeVoucherInputModel
    ) {
      const loadingId = load('Update Stock Take Voucher Async');
      try {
        const response = yield http.put(
          generatePath(STOCK_TAKE_API.UPDATE_STOCK_TAKE_DETAIL, { id }),
          {
            items: [...(values.items ?? [])],
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const data = response.data;
          if (data && data.error) {
            return {
              error: data.message,
            };
          }
          return {};
        } else {
          return {
            error: 'Error when update stock take voucher',
          };
        }
      } catch (err) {
        return {
          error: 'Error when update stock take voucher',
        };
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatusStockTakeVoucherAsync = flow(function* (
      id: string,
      status: AdjustmentStatus,
      values: StockTakeVoucherInputModel
    ) {
      const loadingId = load('Update Status Stock Take Voucher Async');
      try {
        const response = yield http.put(
          generatePath(STOCK_TAKE_API.UPDATE_PRE_STOCK_TAKE_STATUS, { id }),
          {
            status,
            reason: values.reason,
            items: [...(values?.items ?? [])],
          }
        );
        const data = response.data;
        console.log(data);
        if (data && data.error) {
          return {
            error: data.data,
          };
        }
      } catch (err) {
        return {
          error: 'Error when update status stock take voucher',
        };
      } finally {
        loaded(loadingId);
      }
      return {};
    });

    const setInputInventoryFilterParams = (
      inputInventoryFilterParams: StockTakeInputInventoryFilterParams
    ) => {
      applySnapshot(self.inputInventoryFilterParams, {
        ...DefaultStockTakeInputInventoryFilterParams,
        ...inputInventoryFilterParams,
      });
    };

    const setInputStockTakeVoucher = (inputStockTakeVoucher: StockTakeVoucherInputModel) => {
      applySnapshot(self.stockTakeVoucher.items, [...(inputStockTakeVoucher.items ?? [])]);
    };

    return {
      getStockTakeVouchersAsync,
      getStockTakeVoucherDetailAsync,
      getCurrentStockTakeVoucherAsync,
      setQueryParams,
      setPaginationParams,
      getWarehousesAsync,
      createStockTakeVoucherAsync,
      updateStockTakeVoucherAsync,
      updateStatusStockTakeVoucherAsync,
      resetStockTakeVoucher,
      setInputInventoryFilterParams,
      setInputStockTakeVoucher,
    };
  });

export default StockTakeVoucherStore;
export type StockTakeVoucherStoreInstance = Instance<typeof StockTakeVoucherStore>;
