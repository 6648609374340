import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const CMoveModel = types.model('CMove Model', {
  id: types.identifier,
  warehouse: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
  }),
  goods: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
  }),
  location: types.model({
    id: types.string,
    code: types.string,
    name: types.string,
    type: types.string,
    pickingSku: types.maybeNull(types.string),
  }),
  batchNumber: types.string,
  status: types.string,
  stockQuantity: types.integer,
  realStockQuantity: types.integer,
  goodsId: types.string,
  currentLocationId: types.maybeNull(types.string),
  newLocationId: types.maybeNull(types.string),
  moveQuantity: types.maybeNull(types.integer),
  warehouseName: types.maybeNull(types.string),
  goodsCode: types.maybeNull(types.string),
  goodsName: types.maybeNull(types.string),
  currentLocationName: types.maybeNull(types.string),
});

export const DefaultCMoveValue: Partial<CMoveInputModel> = {
  goodsId: '',
  batchNumber: '',
  status: '',
  currentLocationId: '',
  newLocationId: null,
  moveQuantity: 0,
};

export interface CMoveInstance extends Instance<typeof CMoveModel> {}
export interface CMoveInputModel extends SnapshotIn<typeof CMoveModel> {}
export interface CMove extends SnapshotOut<typeof CMoveModel> {}
