import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { InternalAdjItemsModel } from './InternalAdjItemsModel';

export const InternalAdjDetailModel = types.model('InternalADJ Detail Model', {
  id: types.identifier,
  code: types.string,
  status: types.string,
  warehouseName: types.maybeNull(types.string),
  warehouseId: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  adjustmentVoucherItems: types.array(InternalAdjItemsModel),
  remark: types.maybeNull(types.string),
});

export interface InternalAdjDetailInstance extends Instance<typeof InternalAdjDetailModel> {}
export interface InternalAdjDetailInputModel extends SnapshotIn<typeof InternalAdjDetailModel> {}
export interface InternalAdjDetail extends SnapshotOut<typeof InternalAdjDetailModel> {}
