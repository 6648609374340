export enum ErrMsg {
  DUPLICATE_BARCODE = 'This product is already included in the exchange. Please check it again.',
  BARCODE_NOT_EXIST = 'This product {} is not in the order. Please check again!',
  BARCODE_INVALID = 'Barcode is invalid. Please check it again.',
  OLD_QUANTITY_REPLACE_EXCEED = 'The number of returned products exceeds the current quantity. Please check it again.',
  NEW_QUANTITY_REPLACE_EXCEED = 'The number of exchanged products is already equal to the number of returned products, cannot be added more',
  OLD_QUANTITY_MISMATCHED_NEW_QUANTITY = 'The number of replacement products is not equal. Please check again!',
  DUPLICATE_BARCODE_PICKUP = 'This product is already added before. Please check it again.',
  QUANTITY_PICKUP_EXCEED = 'Invalid scanning amount. Please check it again.',
  QUANTITY_PICKUP_FULL = 'This order is scanned fully',
}
