import { createStoreContext } from '@vklink/libs-state';

import { type TransferNoteStoreInstance } from './TransferNoteStore';

const [TransferNoteStoreProvider, useTransferNoteStore] =
  createStoreContext<TransferNoteStoreInstance>();

export { TransferNoteStoreProvider, useTransferNoteStore };

export {
  default as TransferNoteStore,
  type TransferNoteStoreEnv,
  type TransferNoteStoreInstance,
} from './TransferNoteStore';
