import { generatePath } from 'react-router';

import { HttpInstance } from '@vklink/libs-http';
import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { LOCATION_API, ORDER_API, ResponseGenerator } from 'api';
import { FormatDate, dayjs, LocationType, ErrMsg, OrderStatus, Highlight } from 'enums';
import {
  CartonDetailInformation,
  CartonInformation,
  CartonInformationModel,
  DefaultOrderPickupDetailValue,
  OrderPickupDetail,
  OrderPickupDetailModel,
  PickupGoods,
  PickupGoodsModel,
} from './models';
import { LocationOptionsModel } from 'pages/shared/models/common-options';
import { onlyUniqueForOptions, removeEmptyInObject } from 'pages/shared/utils';

export type PickupGoodsStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const PickupGoodsStore = types
  .model({
    pickupGoods: types.array(PickupGoodsModel),
    orderPickupDetail: types.optional(OrderPickupDetailModel, DefaultOrderPickupDetailValue),
    cartonDetailInformation: types.maybe(CartonInformationModel),
    scanningProduct: types.optional(types.string, ''),
    locationOptions: types.array(LocationOptionsModel),
    errMsg: types.maybeNull(types.string),
    totalQtyInput: 0,
    totalQtyOrder: 0,
  })
  .views((self) => {
    return {
      get listProducts() {
        return self.pickupGoods.length > 0 ? getSnapshot(self.pickupGoods) : [];
      },
      get isPickedUp() {
        return self.orderPickupDetail.orderStatus === OrderStatus.PICKED_UP_ORDER;
      },
      get receiveMethod() {
        return self.orderPickupDetail.deliveryAddress?.receiveMethod;
      },
      get itemCartonList() {
        return self.orderPickupDetail.orderPackages?.length
          ? getSnapshot(self.orderPickupDetail?.orderPackages)
          : [];
      },
      get getOrderDateFormatted() {
        return self.orderPickupDetail?.orderDate
          ? dayjs(self.orderPickupDetail?.orderDate).format(FormatDate.DATE)
          : '';
      },
      get getOrderProductInformation() {
        return self.orderPickupDetail.items.length > 0
          ? getSnapshot(self.orderPickupDetail.items)
          : [];
      },
      get getScannedOrderNumber() {
        return self.orderPickupDetail.orderNumber as string;
      },
      get getLocationOptions() {
        const result = self.locationOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
        return onlyUniqueForOptions(result);
      },
      get getErrMsg() {
        return self.errMsg;
      },
      get getTotalQtyInput() {
        return self.totalQtyInput;
      },
      get getTotalQtyOrder() {
        return self.totalQtyOrder;
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<PickupGoodsStoreEnv>(self);

    const setOrderPickupDetail = (orderPickupDetail: OrderPickupDetail) => {
      orderPickupDetail.orderDateFormatted = orderPickupDetail.orderDate
        ? dayjs(orderPickupDetail.orderDate).format(FormatDate.DATE)
        : '';

      applySnapshot(self.orderPickupDetail, {
        ...orderPickupDetail,
      });
      setScanningProduct(orderPickupDetail.orderPackages[0]?.id);

      self.totalQtyInput = orderPickupDetail.orderPickups.reduce(
        (a, b) => a + (b.quantity || 0),
        0
      );

      self.totalQtyOrder = orderPickupDetail.items.reduce((a, b) => a + (b.quantity || 0), 0);
    };

    const setCartonDetail = (cartonDetail: CartonInformation) => {
      self.cartonDetailInformation = { ...(cartonDetail as any) };
    };

    const setScanningProduct = (idScanningProduct: string) => {
      self.scanningProduct = idScanningProduct;
      const orderPickups = self.orderPickupDetail.orderPickups;

      if (idScanningProduct) {
        const result: any[] = orderPickups
          .map((el) => {
            if (el.orderPackageId === self.scanningProduct) {
              return {
                ...el,
                inputQuantity: el.quantity,
                quantity: self.getOrderProductInformation.find(
                  (item) =>
                    item.goodsId === el.goodsId && item.salesItemDetailId === el.salesItemDetailId
                )?.quantity,
                barcodeNumbers: el.barcodeNumbers && el.barcodeNumbers.map((el) => el),
                barcodeNumber: el.barcodeNumbers && el.barcodeNumbers[0],
              };
            }
            return;
          })
          .filter((el) => el);

        if (result.length) applySnapshot(self.pickupGoods, result);
        else applySnapshot(self.pickupGoods, []);
      } else {
        const result: any[] = orderPickups.map((el) => {
          return {
            ...el,
            inputQuantity: el.quantity,
            quantity: self.getOrderProductInformation.find(
              (item) =>
                item.goodsId === el.goodsId && item.salesItemDetailId === el.salesItemDetailId
            )?.quantity,
            barcodeNumber: el.barcodeNumbers && el.barcodeNumbers[0],
            barcodeNumbers: el.barcodeNumbers && el.barcodeNumbers.map((el) => el),
          };
        });
        applySnapshot(self.pickupGoods, result);
      }
    };

    const getOrderPickupDetailAsync = flow(function* (orderNumber: string) {
      const loadingId = load('Order Pickup Detail Async');
      try {
        const url = ORDER_API.GET_ORDER_PICKUP_DETAIL.replace(':orderNumber', orderNumber);
        const response: ResponseGenerator = yield http.get(url, {
          params: {},
        });
        if (response.status === 200) {
          return response.data;
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        loaded(loadingId);
      }
    });

    const validateDuplicateBarcode = (barcode: string) => {
      const checkBarcodeInPreviousCarton = self.orderPickupDetail.orderPickups.some(
        (el) => el.barcodeNumbers && el.barcodeNumbers.includes(barcode)
      );
      const checkBarcodeNumber = self.pickupGoods.some((el) => el.barcodeNumbers.includes(barcode));

      if (checkBarcodeInPreviousCarton || checkBarcodeNumber) {
        return true;
      }
      return false;
    };

    const validateQuantityWhenScanBarcode = (scannedProduct: PickupGoods) => {
      const totalInPreviousCarton = self.orderPickupDetail.orderPickups
        .filter(
          (f) =>
            f.goodsCode === scannedProduct?.goodsCode &&
            f.salesItemDetailId === scannedProduct.salesItemDetailId
        )
        .reduce((a, b) => a + (b.quantity || 0), 0);

      const dataInOrder = self.orderPickupDetail.items.find(
        (x) =>
          x.goodsCode == scannedProduct?.goodsCode &&
          x.salesItemDetailId == scannedProduct.salesItemDetailId
      );
      if (dataInOrder) {
        if (totalInPreviousCarton == dataInOrder.quantity) {
          return false;
        }
      }
      return true;
    };

    const deleteProductItems = flow(function* (item: PickupGoods, rowIndex: number) {
      self.totalQtyInput -= item.inputQuantity;
      self.pickupGoods.splice(rowIndex, 1);

      const orderPickup = self.orderPickupDetail.orderPickups.find(
        (el) =>
          el.goodsId === item.goodsId &&
          el.batchNumber === item.batchNumber &&
          el.salesItemDetailId === item.salesItemDetailId
      );
      if (!orderPickup) return;

      const loadingId = load('Delete product');
      try {
        const url = generatePath(ORDER_API.DELETE_ORDER_PICKUP_ITEM_BY_ID, {
          orderNumber: orderPickup.orderNumber,
        });

        const paramsDelete = {
          goodsSetId: orderPickup.goodsSetId,
          goodsId: orderPickup.goodsId,
          orderPackageId: self.scanningProduct,
        };

        yield http.put(url, {
          ...removeEmptyInObject(paramsDelete),
        });
        const orderDetail = yield getOrderPickupDetailAsync(orderPickup.orderNumber);
        applySnapshot(self.orderPickupDetail.items, orderDetail.items);
        applySnapshot(self.orderPickupDetail.orderPickups, orderDetail.orderPickups);
        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProductByBarcodeNumber = flow(function* (barcodeNumber: string, cb?: RequestCallback) {
      const loadingId = load('Get Product By Barcode Number');
      try {
        if (validateDuplicateBarcode(barcodeNumber)) {
          self.errMsg = ErrMsg.DUPLICATE_BARCODE_PICKUP;
          cb?.error && cb.error('');
          return;
        }
        const url = generatePath(ORDER_API.GET_PRODUCT_BY_BARCODE_NUMBER, { barcodeNumber });
        const response = yield http.get(url);
        let isFull = false;
        const scannedProduct = self.orderPickupDetail.items
          .map((el) => {
            if (el.goodsId === response.data.goodsId) {
              if (el.isHighlight !== Highlight.FULL) {
                return {
                  ...response.data,
                  salesItemDetailId: el.salesItemDetailId,
                  quantity: el.quantity,
                  goodsSetId: el.goodsSetId,
                  goodsSetCode: el.goodsSetCode,
                  inputQuantity: 1,
                  barcodeNumbers: [barcodeNumber],
                };
              } else {
                isFull = true;
              }
            }
          })
          .filter((x) => x)[0];

        if (!scannedProduct) {
          self.errMsg = isFull ? ErrMsg.QUANTITY_PICKUP_EXCEED : 'Cannot find product in order';
          cb?.error && cb.error(self.errMsg);
          return;
        }

        if (!validateQuantityWhenScanBarcode(scannedProduct)) {
          self.errMsg = ErrMsg.QUANTITY_PICKUP_EXCEED;
          cb?.error && cb.error('');
          return;
        }
        addProductToList(scannedProduct);
        self.totalQtyInput++;
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const addProductToList = (scannedProduct: PickupGoods) => {
      if (self.pickupGoods.length === 0) {
        applySnapshot(self.pickupGoods, [...self.pickupGoods, scannedProduct]);
        return;
      }
      const unique = {
        goodsId: scannedProduct.goodsId,
        stockStatus: scannedProduct.stockStatus,
        batchNumber: scannedProduct.batchNumber,
        locationId: scannedProduct.locationId,
      };

      let checkUnique = false;
      self.pickupGoods.forEach((el) => {
        if (JSON.stringify(unique) === JSON.stringify(el.getUnique)) {
          if (el.inputQuantity < el.quantity) {
            checkUnique = true;
            el.inputQuantity++;
            el.barcodeNumbers.length > 0 && el.barcodeNumbers.push(scannedProduct.barcodeNumber);
          }
        }
      });
      if (!checkUnique) applySnapshot(self.pickupGoods, [...self.pickupGoods, scannedProduct]);
    };

    const createOrderPackages = flow(function* (
      orderNumber: string,
      numberOfPackage: number,
      cb?: RequestCallback
    ) {
      const loadingId = load('Post Order Package By Barcode Number');
      try {
        const url = generatePath(ORDER_API.POST_ORDER_PACKAGE, { orderNumber });
        yield http.post(url, { numberOfPackage });

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);

        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const deleteOrderPackage = flow(function* (
      orderNumber: string,
      packageId: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Delete Order Package');
      try {
        const url = generatePath(ORDER_API.DELETE_ORDER_PACKAGE, { orderNumber, packageId });
        yield http.delete(url);
        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);

        applySnapshot(self.orderPickupDetail.orderPickups, orderDetail.orderPickups);
        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);
        applySnapshot(self.orderPickupDetail.items, orderDetail.items);
        applySnapshot(self.pickupGoods, []);
        self.scanningProduct = '';

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateOrderPackage = flow(function* (
      orderNumber: string,
      packageId: string,
      cartonInformation: CartonDetailInformation,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Order Package');
      const dataBody = {
        name: cartonInformation.packageName,
        weight: cartonInformation.weight,
        description: cartonInformation.description,
      };

      try {
        const url = generatePath(ORDER_API.UPDATE_ORDER_PACKAGE, { orderNumber, packageId });
        yield http.put(url, { ...dataBody });

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);

        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const saveProductWithDirectlyMethod = flow(function* (
      orderNumber: string,
      listProducts: PickupGoods[],
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Product With Directly Method');
      const products = listProducts.map((el) => {
        return {
          goodsId: el.goodsId,
          goodsSetId: el.goodsSetId,
          barcodeNumbers: el.barcodeNumbers,
          saleItemDetailId: el.salesItemDetailId,
        };
      });

      try {
        const url = generatePath(ORDER_API.SAVE_PRODUCT_WITH_DIRECTLY_METHOD, { orderNumber });
        yield http.post(url, { items: products });

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);
        applySnapshot(self.orderPickupDetail.orderPickups, orderDetail.orderPickups);
        applySnapshot(self.orderPickupDetail.items, orderDetail.items);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const saveProductToPackage = flow(function* (
      orderNumber: string,
      packageId: string,
      cartonInformation: CartonInformation,
      listProducts: PickupGoods[],
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Product To Package');
      const currentPage = {
        name: cartonInformation.packageName,
        weight: cartonInformation.weight,
        description: cartonInformation.description,
      };

      const products = listProducts.map((el) => {
        return {
          goodsId: el.goodsId,
          goodsSetId: el.goodsSetId,
          barcodeNumbers: el.barcodeNumbers,
          saleItemDetailId: el.salesItemDetailId,
        };
      });
      try {
        const url = generatePath(ORDER_API.UPDATE_ORDER_PACKAGE, { orderNumber, packageId });
        yield http.put(url, { ...currentPage, items: products });

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);
        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);
        applySnapshot(self.orderPickupDetail.orderPickups, orderDetail.orderPickups);
        applySnapshot(self.orderPickupDetail.items, orderDetail.items);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const savePickupInfo = flow(function* (
      orderNumber: string,
      dataSavePickup: any,
      locationId?: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Product To Package');
      try {
        const url = generatePath(ORDER_API.POST_SAVE_PICKUP_INFO, { orderNumber });
        yield http.post(url, { ...dataSavePickup, locationId });
        self.orderPickupDetail = yield getOrderPickupDetailAsync(orderNumber);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const generateBolCode = flow(function* (
      orderNumber: string,
      packageId: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Generate Bol Code');
      try {
        const url = generatePath(ORDER_API.GENERATE_BOL_CODE, { orderNumber, packageId });
        yield http.put(url);

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);

        applySnapshot(self.orderPickupDetail.orderPackages, orderDetail.orderPackages);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationOptionsByWarehouseId = flow(function* (warehouseId: string) {
      const loadingId = load('Get Location Async');
      try {
        const response = yield http.get(LOCATION_API.GET_LOCATIONS, {
          params: {
            warehouseId,
            type: LocationType.ORDERLOCATION,
          },
        });
        applySnapshot(self.locationOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const setErrMsg = (msg: string) => {
      self.errMsg = msg;
    };

    const updateOrderLocation = flow(function* (locationId: string, cb?: RequestCallback) {
      const loadingId = load('Update Order Location');
      try {
        const orderNumber = self.orderPickupDetail.orderNumber as string;
        const url = generatePath(ORDER_API.UPDATE_ORDER_LOCATION, { orderNumber });
        yield http.put(url, { locationId });

        const orderDetail = yield getOrderPickupDetailAsync(orderNumber);
        self.orderPickupDetail.orderDelivery = orderDetail.orderDelivery;

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setScanningProduct,
      setOrderPickupDetail,
      setCartonDetail,
      getOrderPickupDetailAsync,
      getProductByBarcodeNumber,
      deleteProductItems,
      createOrderPackages,
      deleteOrderPackage,
      updateOrderPackage,
      saveProductToPackage,
      savePickupInfo,
      getLocationOptionsByWarehouseId,
      setErrMsg,
      updateOrderLocation,
      saveProductWithDirectlyMethod,
      generateBolCode,
    };
  });

export default PickupGoodsStore;
export type PickupGoodsStoreInstance = Instance<typeof PickupGoodsStore>;
