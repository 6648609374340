import { SnapshotIn, types } from 'mobx-state-tree';

export const CMoveChangeStatusFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  locationId: types.optional(types.string, ''),
  warehouseId: types.optional(types.string, ''),
});

export interface CMoveChangeStatusFilterParams
  extends SnapshotIn<typeof CMoveChangeStatusFilterParamsModel> {}
export const DefaultCMoveChangeStatusFilterParams: CMoveChangeStatusFilterParams = {
  keyword: '',
  locationId: '',
  warehouseId: '',
};
