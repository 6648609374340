import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const RefundOrderModel = types.model('RefundOrder Model', {
  id: types.identifier,
  invoiceNumber: types.string,
  customerCode: types.string,
  orderNumber: types.string,
  orderDate: types.string,
  totalOrderValue: types.string,
  totalQv: types.string,
  totalCv: types.string,
  orderCategorization: types.string,
  paymentMethod: types.string,
  orderWeek: types.string,
  weekBonusAndCommission: types.string,
  amountUnpaid: types.string,
  theAmountPaid: types.string,
  paymentStatus: types.string,
  refundDate: types.string,
  inputDate: types.string,
});

export interface RefundOrderInstance extends Instance<typeof RefundOrderModel> {}
export interface RefundOrderInputModel extends SnapshotIn<typeof RefundOrderModel> {}
export interface RefundOrder extends SnapshotOut<typeof RefundOrderModel> {}
