import { SnapshotIn, types } from 'mobx-state-tree';

export const ExportStockFilterParamsModel = types
  .model({
    keyword: types.optional(types.string, ''),
    status: types.optional(types.string, ''),
    fromSellDate: types.maybeNull(types.string),
    toSellDate: types.maybeNull(types.string),
    warehouseId: types.maybeNull(types.string),
    receiveMethod: types.maybeNull(types.string),
    fromOutDate: types.maybeNull(types.string),
    toOutDate: types.maybeNull(types.string),
    fromPickupDate: types.maybeNull(types.string),
    toPickupDate: types.maybeNull(types.string),
    orderStatusFilter: types.optional(types.boolean, false),
    orderDateFilter: types.optional(types.boolean, false),
    warehouseFilter: types.optional(types.boolean, false),
    exportDateFilter: types.optional(types.boolean, false),
    pickupDateFilter: types.optional(types.boolean, false),
    deliveryMethodFilter: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get getOrderStatusFilter() {
        return self.orderStatusFilter === true ? 'block' : 'none';
      },
      get getOrderDateFilter() {
        return self.orderDateFilter === true ? 'block' : 'none';
      },
      get getWarehouseFilter() {
        return self.warehouseFilter === true ? 'block' : 'none';
      },
      get getExportDateFilter() {
        return self.exportDateFilter === true ? 'block' : 'none';
      },
      get getPickupDateFilter() {
        return self.pickupDateFilter === true ? 'block' : 'none';
      },
      get getDeliveryMethodFilter() {
        return self.deliveryMethodFilter === true ? 'block' : 'none';
      },
      get getFilter() {
        return {
          keyword: self.keyword,
          status: self.status,
          fromSellDate: self.fromSellDate,
          toSellDate: self.toSellDate,
          warehouseId: self.warehouseId,
          receiveMethod: self.receiveMethod,
          fromOutDate: self.fromOutDate,
          toOutDate: self.toOutDate,
          fromPickupDate: self.fromPickupDate,
          toPickupDate: self.toPickupDate,
        };
      },
      get getOptions() {
        return {
          orderStatusFilter: self.orderStatusFilter,
          orderDateFilter: self.orderDateFilter,
          warehouseFilter: self.warehouseFilter,
          exportDateFilter: self.exportDateFilter,
          pickupDateFilter: self.pickupDateFilter,
          deliveryMethodFilter: self.deliveryMethodFilter,
        };
      },
    };
  })
  .actions((self: any) => {
    const setQueryParamsValue = (name: string, value: any) => {
      self[name] = value;
    };

    return { setQueryParamsValue };
  });

export interface ExportStockFilterParams extends SnapshotIn<typeof ExportStockFilterParamsModel> {}
export const DefaultExportStockFilterParams: ExportStockFilterParams = {
  keyword: '',
  status: '',
  fromSellDate: null,
  toSellDate: null,
  warehouseId: '',
  receiveMethod: '',
  fromOutDate: null,
  toOutDate: null,
  fromPickupDate: null,
  toPickupDate: null,
};

export const DefaultValueExportStockFilterParams: ExportStockFilterParams = {
  keyword: '',
  status: '',
  fromSellDate: null,
  toSellDate: null,
  warehouseId: '',
  receiveMethod: '',
  fromOutDate: null,
  toOutDate: null,
  fromPickupDate: null,
  toPickupDate: null,
};
