import { types, SnapshotOut } from '@vklink/libs-state';

export const InventoryDetailModel = types.model({
  id: types.optional(types.string, ''),
  categoryName: types.maybeNull(types.string),
  goodsId: types.optional(types.string, ''),
  goodsName: types.optional(types.string, ''),
  goodsCode: types.optional(types.string, ''),
  warehouseName: types.optional(types.string, ''),
  warehouseId: types.optional(types.string, ''),
  locationName: types.optional(types.string, ''),
  locationCode: types.optional(types.string, ''),
  batchNumber: types.maybeNull(types.string),
  status: types.optional(types.string, ''),
  quantity: types.optional(types.number, 0),
  referenceCode: types.maybeNull(types.string),
  referenceId: types.maybeNull(types.string),
  referenceDate: types.maybeNull(types.string),
  referenceType: types.maybeNull(types.string),
});

export interface InventoryDetail extends SnapshotOut<typeof InventoryDetailModel> {}
