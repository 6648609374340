import { createStoreContext } from '@vklink/libs-state';

import { type ReplaceOrderStoreInstance } from './ReplaceOrderStore';

const [ReplaceOrderStoreProvider, useReplaceOrderStore] =
  createStoreContext<ReplaceOrderStoreInstance>();

export { ReplaceOrderStoreProvider, useReplaceOrderStore };

export {
  default as ReplaceOrderStore,
  type ReplaceOrderStoreEnv,
  type ReplaceOrderStoreInstance,
} from './ReplaceOrderStore';
