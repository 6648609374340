import { ExportStockType } from 'enums';

export const ExportStockTypeOptions: SelectOption[] = [
  {
    label: 'Exported',
    value: ExportStockType.EXPORTED_ORDER,
  },
  {
    label: 'Picked Up',
    value: ExportStockType.PICKED_UP_ORDER,
  },
  {
    label: 'Received',
    value: ExportStockType.RECEIVED_ORDER,
  },
];
