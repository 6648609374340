export const ORDER_API = {
  GET_ORDER_PICKUP_DETAIL: `orders/:orderNumber?type=PICKUP`,
  GET_PRODUCT_BY_BARCODE_NUMBER: 'goods/scan-new-product/:barcodeNumber',
  POST_ORDER_PACKAGE: 'orders/:orderNumber/package',
  DELETE_ORDER_PACKAGE: 'orders/:orderNumber/package/:packageId',
  UPDATE_ORDER_PACKAGE: 'orders/:orderNumber/package/:packageId',
  POST_SAVE_PICKUP_INFO: 'orders/:orderNumber/pickup',
  UPDATE_ORDER_LOCATION: 'orders/:orderNumber/pickup/location',
  DELETE_ORDER_PICKUP_ITEM_BY_ID: 'orders/:orderNumber/pickup',
  SAVE_PRODUCT_WITH_DIRECTLY_METHOD: 'orders/:orderNumber/pickup/directly',
  GENERATE_BOL_CODE: 'orders/:orderNumber/package/:packageId/generateBolCode',
  GET_LATEST_ORDER_INFOMATION: 'orders/latest',
  GET_CANCEL_DELAY_PAYMENT_ORDER: 'orders/:orderNumber/cancel',
};
