import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ReturnOrderModel = types.model('ReturnOrder Model', {
  id: types.identifier,
  actionType: types.maybeNull(types.string),
  orderNumber: types.maybeNull(types.string),
  sellDate: types.maybeNull(types.string),
  returnTf: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  receivedDate: types.maybeNull(types.string),
  allowReplacement: types.maybeNull(types.boolean),
  allowReturnFull: types.maybeNull(types.boolean),
  allowReturnPart: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export interface ReturnOrderInstance extends Instance<typeof ReturnOrderModel> {}
export interface ReturnOrderInputModel extends SnapshotIn<typeof ReturnOrderModel> {}
export interface ReturnOrder extends SnapshotOut<typeof ReturnOrderModel> {}
