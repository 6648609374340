import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderDetailMapModel = types.model('Order Detail Map Model', {
  orderNumber: types.string,
  orderDate: types.string,
  orderStatus: types.string,
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  mbid2: types.string,
  customerName: types.maybeNull(types.string),
  receiveMethod: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  receiver: types.maybeNull(types.string),
  receiverPhone: types.maybeNull(types.string),
  bolCodes: types.maybeNull(types.string),
});

export interface OrderDetailMap extends SnapshotOut<typeof OrderDetailMapModel> {}
