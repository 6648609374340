import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { AdjustmentStatus, dayjs, FormatDate } from 'enums';
import { StockTakeVoucherItemModel } from './StockTakeVoucherItemModel';

export const StockTakeVoucherModel = types
  .model('StockTakeVoucher Model', {
    id: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    issueDate: types.maybeNull(types.string),
    warehouseId: types.maybeNull(types.string),
    warehouseName: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    lastOrder: types.maybeNull(types.string),
    lastAds: types.maybeNull(types.string),
    lastAdjustment: types.maybeNull(types.string),
    lastTransferNote: types.maybeNull(types.string),
    closingDate: types.maybeNull(types.string),
    inputDate: types.maybeNull(types.string),
    inputBy: types.maybeNull(types.string),
    reason: types.maybeNull(types.string),
    adjusmentId: types.maybeNull(types.string),
    adjustmentCode: types.maybeNull(types.string),
    items: types.array(types.late(() => StockTakeVoucherItemModel)),
  })
  .views((self) => {
    return {
      get issueDateFormat() {
        const issueDate = self.issueDate;
        if (issueDate) {
          return dayjs(issueDate).format(FormatDate.DATE_TIME);
        }
        return dayjs().format(FormatDate.DATE_TIME);
      },
      get isCreateNew() {
        if (!self.code && self.warehouseId) return true;
        if (
          self.status &&
          self.status !== AdjustmentStatus.INIT &&
          self.status !== AdjustmentStatus.IN_PROCESS
        )
          return true;
        return false;
      },
      get isPrintVoucher() {
        return self.code;
      },
    };
  });

export const DefaultStockTakeVoucherValue: Partial<StockTakeVoucherInputModel> = {
  id: '',
  issueDate: dayjs().format(FormatDate.DATE_TIME),
  code: '',
  warehouseId: '',
  warehouseName: '',
  lastOrder: '',
  lastAds: '',
  lastAdjustment: '',
  lastTransferNote: '',
  closingDate: '',
  items: [],
};

export interface StockTakeVoucherInstance extends Instance<typeof StockTakeVoucherModel> {}
export interface StockTakeVoucherInputModel extends SnapshotIn<typeof StockTakeVoucherModel> {}
export interface StockTakeVoucher extends SnapshotOut<typeof StockTakeVoucherModel> {}
