import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { LabelMethod, PrintLabelType } from 'enums';

export const PrintLabelModel = types.model('PrintLabel Model', {
  id: types.identifier,
  column1: types.string,
  column2: types.string,
  column3: types.string,
  column4: types.maybeNull(types.string),
  column5: types.maybeNull(types.string),
  column6: types.maybeNull(types.string),
  column7: types.maybeNull(types.string),
  column8: types.maybeNull(types.string),
});

export const DefaultPrintLabelValue: Partial<any> = {
  labelMethod: LabelMethod.PO,
  type: PrintLabelType.PO,
  barcode: 'Choose barcode',
  sku: 'All',
  fromBarcodeNumber: '016580111',
  quantity: '016580111',
  keyword: '',
};

export interface PrintLabelInstance extends Instance<typeof PrintLabelModel> {}
export interface PrintLabelInputModel extends SnapshotIn<typeof PrintLabelModel> {}
export interface PrintLabel extends SnapshotOut<typeof PrintLabelModel> {}
