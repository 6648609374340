export enum PaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
}

export enum DeliveryMethod {
  HOME = 'HOME',
  OFFICE = 'DELIVERYPOINT',
  DIRECTLY = 'DIRECTLY',
}

export enum PaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UN_PAID',
}
