import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { ImportStock, ImportStockModel } from './models/ImportStockModel';
import {
  DefaultImportStockFilterParams,
  ImportHistoryModel,
  ImportStockFilterParams,
  ImportStockFilterParamsModel,
  ProductListModel,
  ProductInformation,
  ImportStockInputModel,
  ProductList,
  DefaultProductListFilterParams,
  ProductListFilterParamsModel,
  ProductListFilterParams,
  ImportHistory,
  ImportHistoryFilterParamsModel,
  DefaultImportHistoryFilterParams,
  ImportHistoryFilterParams,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { ResponseGenerator, STOCK_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import {
  dayjs,
  FormatDate,
  ImportStockPart,
  ImportStockType,
  LocationStatus,
  LocationType,
} from 'enums';
import { WarehouseModel } from 'pages/warehouse-management/stores/models';
import { SelectFieldModel } from 'stores/models/SelectFieldModel';

export type ImportStockStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ImportStockStore = types
  .model('ImportStock Store', {
    importStocks: types.array(ImportStockModel),
    importStockDetail: types.maybeNull(ImportStockModel),
    filterParams: types.optional(ImportStockFilterParamsModel, DefaultImportStockFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    productList: types.array(ProductListModel),
    importHistory: types.array(ImportHistoryModel),
    importStockModal: types.array(ProductInformation),
    warehouses: types.array(WarehouseModel),
    locationsOptions: types.array(SelectFieldModel),
    filterParamsProduct: types.optional(
      ProductListFilterParamsModel,
      DefaultProductListFilterParams
    ),
    filterParamsHistory: types.optional(
      ImportHistoryFilterParamsModel,
      DefaultImportHistoryFilterParams
    ),
  })
  .views((self: any) => {
    return {
      get importStockList() {
        return getSnapshot<ImportStock[]>(self.importStocks);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouses.map((el: any) => {
          return { value: el.id, label: el.name };
        });
      },
      get getLocationsOptions() {
        return self.locationsOptions.map((el: any) => {
          return { value: el.value, label: el.lable };
        });
      },
      get getFirstLocations() {
        return self.locationsOptions.length > 0 ? self.locationsOptions[0] : {};
      },
      get getListProducts() {
        if (!!!self.filterParamsProduct.keyword) {
          return self.importStockDetail?.items;
        } else {
          return self.importStockDetail?.items.filter(
            (el: ProductList) =>
              el.goodsName
                .toLowerCase()
                .includes(self.filterParamsProduct.keyword.trim().toLowerCase()) ||
              el.goodsCode
                .toLowerCase()
                .includes(self.filterParamsProduct.keyword.trim().toLowerCase())
          );
        }
      },
      get getImportHistories() {
        if (!!!self.filterParamsHistory.keyword) {
          return getSnapshot<ImportHistory[]>(self.importHistory);
        } else {
          return getSnapshot<ImportHistory[]>(self.importHistory).filter(
            (el: ImportHistory) =>
              el.goodsName
                .toLowerCase()
                .includes(self.filterParamsHistory.keyword.trim().toLowerCase()) ||
              el.goodsCode
                .toLowerCase()
                .includes(self.filterParamsHistory.keyword.trim().toLowerCase())
          );
        }
      },
    };
  })
  .actions((self: any) => {
    const { http, load, loaded } = getEnv<ImportStockStoreEnv>(self);

    const setQueryParams = (filterParams: ImportStockFilterParams) => {
      applySnapshot(self.filterParams, {
        ...DefaultImportStockFilterParams,
        ...filterParams,
      });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setCurrentImportStock = (importStock: ImportStockInputModel) => {
      applySnapshot(self, {
        ...self,
        importStockDetail: {
          ...importStock,
          receivedDate: dayjs(importStock.receivedDate).format(FormatDate.DATE_TIME),
          importStockType: importStock.importStockType
            ? importStock.importStockType
            : ImportStockPart.FULL,
        },
      });
    };

    const setFilterParamsDetail = (filterParamsProduct: ProductListFilterParams) => {
      applySnapshot(self.filterParamsProduct, {
        ...DefaultProductListFilterParams,
        ...filterParamsProduct,
      });
    };

    const setFilterParamsHistory = (filterParamsHistory: ImportHistoryFilterParams) => {
      applySnapshot(self.filterParamsHistory, {
        ...DefaultImportHistoryFilterParams,
        ...filterParamsHistory,
      });
    };

    const getImportStocksAsync = flow(function* () {
      const loadingId = load('Get Import Stock List Async');
      try {
        const response: ResponseGenerator = yield http.get(STOCK_API.GET_IMPORT_STOCK, {
          params: self.getQueryParams,
        });
        applySnapshot(self.importStocks, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    const getImportStockDetailAsync = flow(function* (
      importType: ImportStockType,
      importId: string
    ) {
      const loadingId = load('Get Import Stock Detail Async');
      try {
        const response: ResponseGenerator = yield http.get(`stocks/import-stock/${importId}`, {
          params: {
            importType,
          },
        });
        if (response.data.importWarehouse.id) {
          yield getLocationsAsync(response.data.importWarehouse.id, response.data.warehousingtype);
        }
        response.data.items.map((el: ProductList) => {
          el.importLocationId = self.getFirstLocations.value;
          el.receivedQuantity = el.receivedQuantity || '0';
          el.importQuantity = el.orderedQuantity - parseInt(el.receivedQuantity);
          el.batchNumber = el.batchNumber ? el.batchNumber : '';
          return el;
        });

        let transferDateFormatted: Date | undefined;
        if (response.data.transferDate) {
          transferDateFormatted = new Date(response.data.transferDate);
          transferDateFormatted.setDate(transferDateFormatted.getDate() - 1);
        }
        applySnapshot(self.importStockDetail, {
          ...response.data,
          importStockType: ImportStockPart.PARTIAL,
          transferDate:
            response.data.transferDate ?? dayjs(response.data.transferDate).format(FormatDate.DATE),
          receivedDate: new Date().toISOString(),
          transferDateFormatted: transferDateFormatted,
        });
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehousesAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses', {
          params: {},
        });
        applySnapshot(self.warehouses, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationsAsync = flow(function* (
      warehouseId: string,
      warehousingType: ImportStockType
    ) {
      const loadingId = load('Get Locations Async');
      let params: any = {
        warehouseId: warehouseId,
        type: undefined,
        status: LocationStatus.ACTIVE,
      };
      if (warehousingType === ImportStockType.PURCHASE_ORDER) {
        params = { ...params, type: LocationType.DOCK };
      }

      try {
        const response = yield http.get('/locations', {
          params: params,
        });
        self.locationsOptions = response.data.map((el: any) => {
          return { value: el.id, label: el.name ?? '' };
        });
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const importStock = flow(function* (importStock: ImportStockInputModel, cb?: RequestCallback) {
      const loadingId = load('Import Stock Async');
      try {
        if (!importStock || !importStock.items || importStock?.items.length == 0) {
          return;
        }
        const items = importStock.items
          .filter((item) => item.importQuantity && (item.importQuantity as unknown as number) > 0)
          .map((item) => {
            return {
              ...item,
              quantity: item.importQuantity as unknown as number,
              locationId: item.importLocationId,
            };
          });
        const requestBody = {
          ...importStock,
          id: importStock.id ? importStock.id : '',
          items: items,
        };
        const apiRouter = `/stocks/import-stock/${
          importStock.warehousingtype == ImportStockType.PURCHASE_ORDER ? 'po' : 'trn'
        }/${importStock.exportWarehouseId}`;
        yield http.post(apiRouter, requestBody);
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getImportStockHistory = flow(function* (
      importType: ImportStockType,
      importId: string,
      goodsId: string
    ) {
      const loadingId = load('Import Stock History Async');
      try {
        const response = yield http.get(`stocks/import-stock/${importId}/history`, {
          params: {
            importType,
            goodsId,
          },
        });
        applySnapshot(self.importHistory, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getImportStocksAsync,
      getImportStockDetailAsync,
      getWarehousesAsync,
      getLocationsAsync,
      setQueryParams,
      setPaginationParams,
      setCurrentImportStock,
      setFilterParamsDetail,
      importStock,
      getImportStockHistory,
      setFilterParamsHistory,
    };
  });

export default ImportStockStore;
export type ImportStockStoreInstance = Instance<typeof ImportStockStore>;
