import { createStoreContext } from '@vklink/libs-state';
import { type LocationStoreInstance } from './LocationStore';

const [LocationStoreProvider, useLocationStore] = createStoreContext<LocationStoreInstance>();

export { LocationStoreProvider, useLocationStore };

export {
  default as LocationStore,
  type LocationStoreEnv,
  type LocationStoreInstance,
} from './LocationStore';
