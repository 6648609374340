import { types, Instance, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { guid } from '@vklink/libs-utils';
import {
  DefaultRefundOrderFilterParams,
  RefundOrderFilterParamsModel,
  RefundOrderModel,
  RefundProductModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';

import dataDemo from '../../../../../../admin-db.json';

export type RefundOrderStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const RefundOrderStore = types
  .model('RefundOrder Store', {
    refundProducts: types.optional(types.array(RefundProductModel), []),
    refundOrders: types.optional(types.array(RefundOrderModel), []),
    filterParams: types.optional(RefundOrderFilterParamsModel, DefaultRefundOrderFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get refundOrderList() {
        return getSnapshot(self.refundOrders);
      },
      get refundProductList() {
        return getSnapshot(self.refundProducts);
      },
      get getQueryParams() {
        return {
          ...self.filterParams,
          ...self.paginationParams,
        };
      },
    };
  })
  .actions((self) => {
    const { load, loaded } = getEnv<RefundOrderStoreEnv>(self);

    const getRefundOrdersAsync = flow(function* () {
      const loadingId = load('Get RefundOrder Async');
      applySnapshot(
        self.refundOrders,
        new Array(10).fill({
          id: guid(),
          ...dataDemo.refundOrders,
        })
      );
      loaded(loadingId);
    });

    const getRefundProducts = flow(function* () {
      const loadingId = load('Get RefundProduct Async');
      applySnapshot(
        self.refundProducts,
        new Array(10).fill({
          id: guid(),
          ...dataDemo.refundProducts,
        })
      );
      loaded(loadingId);
    });

    return {
      getRefundOrdersAsync,
      getRefundProducts,
    };
  });

export default RefundOrderStore;
export type RefundOrderStoreInstance = Instance<typeof RefundOrderStore>;
