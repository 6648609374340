const API_V1 = '';

export const GOODS_API = {
  GET_GOODS: `${API_V1}/goods`,
  POST_GOODS: `${API_V1}/goods`,
  PUT_GOODS: `${API_V1}/goods/:id`,
  GET_GOODS_BY_ID: `${API_V1}/goods/:id`,
  PUT_STATUS_GOODS: `${API_V1}/goods/:id/:status`,
  GET_PRODUCT_INVENTORIES: `${API_V1}/warehouses/inventories/goods-batches`,
};
