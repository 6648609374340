import { SnapshotIn, types } from 'mobx-state-tree';

export const CMoveReportFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
});

export interface CMoveReportFilterParams extends SnapshotIn<typeof CMoveReportFilterParamsModel> {}
export const DefaultCMoveReportFilterParams: CMoveReportFilterParams = {
  keyword: '',
  type: '',
  status: '',
};
