import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { REPORT_API, ResponseGenerator } from 'api';
import { dayjs, FormatDate, LocationStatus } from 'enums';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import { AutocompleteOptionModel } from 'stores/models/AutocompleteOptionModel';
import { SelectFieldModel } from 'stores/models/SelectFieldModel';
import { InventoryDetail, InventoryDetailModel } from './InventoryDetailModel';
import {
  DefaultInventoryReportFilterParams,
  InventoryReportFilterParams,
  InventoryReportFilterParamsModel,
} from './InventoryReportFilterParamsModel';

export type InventoryDetailStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const InventoryDetailStore = types
  .model({
    itemDetail: types.optional(types.array(InventoryDetailModel), []),
    filterParams: types.optional(
      InventoryReportFilterParamsModel,
      DefaultInventoryReportFilterParams
    ),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    locationOptions: types.optional(types.array(AutocompleteOptionModel), []),
    warehouseOptions: types.optional(types.array(SelectFieldModel), []),
  })
  .views((self: any) => {
    return {
      get getItemDetailList() {
        return getSnapshot<InventoryDetail[]>(self.itemDetail ?? []);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((item: any) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
      },
      get getLocationOptions() {
        return self.locationOptions.map((item: any) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
      },
    };
  })
  .actions((self: any) => {
    const { http, load, loaded } = getEnv<InventoryDetailStoreEnv>(self);

    const setQueryParams = (filterParams: InventoryReportFilterParams) => {
      applySnapshot(self.filterParams, {
        ...DefaultInventoryReportFilterParams,
        ...filterParams,
      });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getInventoryReportDetailAsync = flow(function* () {
      const loadingId = load('Get Inventory Report List Async');
      try {
        const response: ResponseGenerator = yield http.get(REPORT_API.GET_INVENTORY_REPORT_DETAIL, {
          params: {
            ...self.getQueryParams,
            fromDate: dayjs(self.filterParams.fromDate).format(FormatDate.DATE),
            toDate: dayjs(self.filterParams.toDate).format(FormatDate.DATE),
          },
        });
        applySnapshot(self.itemDetail, response.data.results);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehousesAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses', {
          params: {},
        });
        applySnapshot(
          self.warehouseOptions,
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name ?? '',
            };
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getLocationsAsync = flow(function* (warehouseId) {
      const params: any = {
        type: undefined,
        status: LocationStatus.ACTIVE,
      };
      if (warehouseId && warehouseId !== '') params.warehouseId = warehouseId;
      const loadingId = load('Get Locations Async');
      try {
        const response = yield http.get('/locations', { params: params });
        applySnapshot(
          self.locationOptions,
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name ?? '',
            };
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const setQueryParamsValue = flow(function* (name: string, value: any) {
      self.filterParams[name] = value;
      if (name === 'warehouseFilter') {
        yield getLocationsAsync(self.filterParams.warehouseId);
        if (value == true && self.warehouseOptions.length === 0) {
          yield getWarehousesAsync();
        }
      }
    });

    return {
      setQueryParams,
      setPaginationParams,
      getInventoryReportDetailAsync,
      getWarehousesAsync,
      getLocationsAsync,
      setQueryParamsValue,
    };
  });

export type InventoryDetailStoreInstance = Instance<typeof InventoryDetailStore>;
