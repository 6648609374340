import { MenuItem } from '@mui/material';
import { useAuthStore } from '@vklink/libs-auth';
import { observer } from '@vklink/libs-state';

const LogoutMenuItem = observer(() => {
  const { signoutAsync } = useAuthStore();

  return <MenuItem onClick={signoutAsync}>Logout</MenuItem>;
});

export default LogoutMenuItem;
