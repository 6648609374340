import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { ReturnOrderStore, ReturnOrderStoreInstance, ReturnOrderStoreProvider } from './stores';
import { PageLayout } from 'pages/shared/layout';

const ReturnOrderLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<ReturnOrderStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(ReturnOrderStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);

  return (
    <ReturnOrderStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </ReturnOrderStoreProvider>
  );
};

export default ReturnOrderLayout;
