import { createStoreContext } from '@vklink/libs-state';

import { type RefundOrderStoreInstance } from './RefundOrderStore';

const [RefundOrderStoreProvider, useRefundOrderStore] =
  createStoreContext<RefundOrderStoreInstance>();

export { RefundOrderStoreProvider, useRefundOrderStore };

export {
  default as RefundOrderStore,
  type RefundOrderStoreEnv,
  type RefundOrderStoreInstance,
} from './RefundOrderStore';
