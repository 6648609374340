import { SnapshotOut, types } from 'mobx-state-tree';

export const PaginationParamsModel = types.model({
  limit: types.optional(types.number, 10),
  page: types.optional(types.number, 1),
});

export const DefaultPaginationParams: PaginationParams = {
  limit: 10,
  page: 1,
};

export interface PaginationParams extends SnapshotOut<typeof PaginationParamsModel> {}
