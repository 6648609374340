import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import {
  StockTakeVoucherStore,
  StockTakeVoucherStoreInstance,
  StockTakeVoucherStoreProvider,
} from './stores';

import { PageLayout } from 'pages/shared/layout';

const StockTakeVoucherLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<StockTakeVoucherStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(StockTakeVoucherStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);

  return (
    <StockTakeVoucherStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </StockTakeVoucherStoreProvider>
  );
};

export default StockTakeVoucherLayout;
