import { types, Instance, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  ReplaceOrderModel,
  ReplaceOrderFilterParamsModel,
  ReplaceOrderFilterParams,
  DefaultReplaceOrderFilterParams,
  OrderDetailModel,
  ProductItemModel,
  OrderItemModel,
  OrderItem,
  ProductOptionsModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';

import { ErrMsg, OrderType } from 'enums';
import { onlyUniqueForOptions, removeEmptyInObject } from 'pages/shared/utils';

export type ReplaceOrderStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ReplaceOrderStore = types
  .model('ReplaceOrder Store', {
    listOldProductItems: types.optional(types.array(ProductItemModel), []),
    replaceOrders: types.optional(types.array(ReplaceOrderModel), []),
    filterParams: types.optional(ReplaceOrderFilterParamsModel, DefaultReplaceOrderFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    replacementOrderDetail: types.maybe(OrderDetailModel),
    errMsg: types.maybeNull(types.string),
    selectedProduct: types.maybeNull(OrderItemModel),
    productOptions: types.array(ProductOptionsModel),
    listNewProductItems: types.optional(types.array(ProductItemModel), []),
  })
  .views((self) => {
    return {
      get replaceOrderList() {
        return getSnapshot(self.replaceOrders);
      },
      get getQueryParams() {
        return {
          ...self.filterParams,
          ...self.paginationParams,
        };
      },
      get getReplacementOrderDetail() {
        return self.replacementOrderDetail;
      },
      get getListProductItems() {
        if (self.replacementOrderDetail) {
          return self.replacementOrderDetail.items.map((item) => ({
            ...item,
            remainingQty: item.quantity,
          }));
        }
        return [];
      },
      get getListOldProductItems() {
        return getSnapshot(self.listOldProductItems);
      },
      get getErrMsg() {
        return self.errMsg;
      },
      get getProductOptions() {
        const result = self.productOptions.map((el) => {
          return { value: el.code, label: el.code };
        });
        return onlyUniqueForOptions(result);
      },
      get getListNewProductItems() {
        return getSnapshot(self.listNewProductItems);
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ReplaceOrderStoreEnv>(self);

    const setQueryParams = (filterParams: ReplaceOrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultReplaceOrderFilterParams, ...filterParams });
    };

    const setSelectedProduct = (selectedProduct: OrderItem) => {
      self.selectedProduct = { ...selectedProduct };
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getReplaceOrdersAsync = flow(function* () {
      const loadingId = load('Get ReplaceOrder Async');
      try {
        const response = yield http.get('/orders', {
          params: {
            ...self.getQueryParams,
            type: OrderType.REPLACE,
          },
        });
        applySnapshot(self.replaceOrders, []);
        applySnapshot(self.replaceOrders, [...response.data]);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getReplaceOrderByIdAsync = flow(function* (id?: string) {
      const loadingId = load('Get Replacement Order By Id');

      const response = yield http.get(`/orders/${id}?type=REPLACEMENT`);

      self.replacementOrderDetail = response.data;
      applySnapshot(self.listNewProductItems, response.data.newProductItems);
      applySnapshot(self.listOldProductItems, response.data.oldProductItems);

      loaded(loadingId);
    });

    const setOldProductItemByScanBarcodeAsync = flow(function* (
      barcode: string,
      orderNumber: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Scan Old Product Item Async');
      try {
        const validate = validateDuplicateBarcode(barcode, 'old');
        if (!validate) {
          self.errMsg = ErrMsg.DUPLICATE_BARCODE;
          cb?.error && cb.error(self.errMsg);
          return;
        }

        const response = yield http.get(`goods/scan-old-product/${barcode}/${orderNumber}`);
        const oldProductdata = {
          goodsCode: response.data.goodsCode,
          goodsName: response.data.goodsName,
          barcode: response.data.barcodeNumber,
          quantity: 1,
        };

        if (!validateQuantityWhenScanOldBarcode(oldProductdata.goodsCode)) {
          self.errMsg = ErrMsg.OLD_QUANTITY_REPLACE_EXCEED;
          cb?.error && cb.error(self.errMsg);
          return;
        }

        const indexInOrder = self.getListProductItems.findIndex(
          (x) => x.goodsCode == oldProductdata.goodsCode
        );
        self.getListProductItems[indexInOrder].remainingQty--;

        applySnapshot(self.listOldProductItems, [...self.listOldProductItems, oldProductdata]);
        cb?.success && cb.success();
      } catch (error) {
        const sku = barcode.substring(0, 9);
        self.errMsg = ErrMsg.BARCODE_NOT_EXIST.replace('{}', sku);
        cb?.error && cb.error(self.errMsg);
      } finally {
        loaded(loadingId);
      }
    });

    const deleteOldProductItem = (rowIndex: number) => {
      const indexInOrder = self.getListProductItems.findIndex(
        (x) => x.goodsCode == self.listOldProductItems[rowIndex].goodsCode
      );
      self.getListProductItems[indexInOrder].remainingQty +=
        self.listOldProductItems[rowIndex].quantity || 0;
      self.listOldProductItems.splice(rowIndex, 1);
    };

    const validateDuplicateBarcode = (barcode: string, type: string) => {
      if (type == 'old') {
        const data = self.listOldProductItems.filter((x) => x.barcode == barcode);
        if (data.length > 0) {
          return false;
        }
      } else {
        const data = self.listNewProductItems.filter((x) => x.barcode == barcode);
        if (data.length > 0) {
          return false;
        }
      }

      return true;
    };

    const validateQuantityWhenScanOldBarcode = (sku: string) => {
      const dataInOrder = self.getListProductItems.find((x) => x.goodsCode == sku);
      const totalReplaceQty =
        self.listOldProductItems
          .filter((f) => f.goodsCode === sku)
          .reduce((a, b) => a + (b.quantity || 0), 0) + 1;

      if (dataInOrder) {
        if (dataInOrder.quantity < totalReplaceQty) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    };

    const setErrMsg = (msg: string) => {
      self.errMsg = msg;
    };

    const setProductOptions = () => {
      const data = self.getListProductItems.map((item) => {
        return {
          code: item.goodsCode,
        };
      });

      applySnapshot(self.productOptions, [...data]);
    };

    const setOldProductItemByManual = (sku: string, quantity: number) => {
      const data = self.getListProductItems.find((x) => x.goodsCode == sku);
      const checkExistSku = self.listOldProductItems.find((x) => x.goodsCode == sku);
      if (checkExistSku && checkExistSku.barcode == '') {
        const newQuantity = (checkExistSku?.quantity as number) + quantity;
        const index = self.listOldProductItems.findIndex((x) => x.goodsCode == sku);
        self.listOldProductItems[index].quantity = newQuantity;
      } else {
        const oldProductdata = {
          goodsCode: data ? data.goodsCode : '',
          goodsName: data ? data.goodsName : '',
          barcode: '',
          quantity: quantity,
        };
        applySnapshot(self.listOldProductItems, [...self.listOldProductItems, oldProductdata]);
      }

      const indexInOrder = self.getListProductItems.findIndex((x) => x.goodsCode == sku);
      self.getListProductItems[indexInOrder].remainingQty -= quantity;
    };

    const validateQuantityWhenScanNewBarcode = (sku: string) => {
      const dataInOrder = self.getListProductItems.find((x) => x.goodsCode == sku);
      const totalInListOldItem = self.listOldProductItems
        .filter((f) => f.goodsCode === sku)
        .reduce((a, b) => a + (b.quantity || 0), 0);
      const totalReplaceQty =
        self.listNewProductItems
          .filter((f) => f.goodsCode === sku)
          .reduce((a, b) => a + (b.quantity || 0), 0) + 1;

      if (dataInOrder) {
        if (totalReplaceQty > totalInListOldItem) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    };

    const setNewProductItemByScanBarcodeAsync = flow(function* (
      barcode: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Scan New Product Item Async');
      const validate = validateDuplicateBarcode(barcode, 'new');
      if (!validate) {
        self.errMsg = ErrMsg.DUPLICATE_BARCODE;
        cb?.error && cb.error(self.errMsg);
        return;
      }
      try {
        const response = yield http.get(`goods/scan-new-product/${barcode}`);
        const newProductdata = {
          goodsCode: response.data.goodsCode,
          goodsName: response.data.goodsName,
          barcode: response.data.barcodeNumber,
          quantity: 1,
        };

        const checkExistSku = self.getListOldProductItems.find(
          (x) => x.goodsCode == newProductdata.goodsCode
        );
        if (!checkExistSku) {
          const sku = barcode.substring(0, 9);
          self.errMsg = ErrMsg.BARCODE_NOT_EXIST.replace('{}', sku);
          cb?.error && cb.error(self.errMsg);
          return;
        }

        if (!validateQuantityWhenScanNewBarcode(newProductdata.goodsCode)) {
          self.errMsg = ErrMsg.NEW_QUANTITY_REPLACE_EXCEED;
          cb?.error && cb.error(self.errMsg);
          return;
        }

        applySnapshot(self.listNewProductItems, [...self.listNewProductItems, newProductdata]);
        cb?.success && cb.success();
      } catch (error) {
        const sku = barcode.substring(0, 9);
        self.errMsg = ErrMsg.BARCODE_NOT_EXIST.replace('{}', sku);
        cb?.error && cb.error(self.errMsg);
      } finally {
        loaded(loadingId);
      }
    });

    const deleteNewProductItem = (rowIndex: number) => {
      self.listNewProductItems.splice(rowIndex, 1);
    };

    const validateReplacementData = () => {
      const checkeds: string[] = [];
      for (let i = 0; i < self.listOldProductItems.length; i++) {
        const oldItem = self.listOldProductItems[i];
        const value = checkeds.find((x) => x == oldItem.goodsCode);
        if (!value) {
          const totalInListOldItem = self.listOldProductItems
            .filter((f) => f.goodsCode === oldItem.goodsCode)
            .reduce((sum, b) => sum + (b.quantity || 0), 0);

          const totalInListNewItem = self.listNewProductItems
            .filter((f) => f.goodsCode === oldItem.goodsCode)
            .reduce((sum, b) => sum + (b.quantity || 0), 0);

          if (totalInListOldItem != totalInListNewItem) {
            self.errMsg = ErrMsg.OLD_QUANTITY_MISMATCHED_NEW_QUANTITY;
            return false;
          }

          checkeds.push(oldItem.goodsCode);
        }
      }
      return true;
    };

    const setReason = (rowIndex: number, reason: string) => {
      self.listOldProductItems[rowIndex].reason = reason;
    };

    const saveReplacementOrder = flow(function* (
      orderNumber: string,
      replacementDate: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Save Replacement Order');
      try {
        const data = {
          orderNumber: orderNumber,
          replacementDate: replacementDate,
          oldItems: self.listOldProductItems,
          newItems: self.listNewProductItems,
        };
        yield http.post(`/stocks/replacement-order`, removeEmptyInObject(data));
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const resetListOldProductItems = () => {
      applySnapshot(self.listOldProductItems, []);
    };

    const resetListNewProductItems = () => {
      applySnapshot(self.listNewProductItems, []);
    };

    return {
      setQueryParams,
      setPaginationParams,
      getReplaceOrdersAsync,
      setOldProductItemByScanBarcodeAsync,
      getReplaceOrderByIdAsync,
      deleteOldProductItem,
      setErrMsg,
      setSelectedProduct,
      setProductOptions,
      setOldProductItemByManual,
      setNewProductItemByScanBarcodeAsync,
      deleteNewProductItem,
      validateReplacementData,
      saveReplacementOrder,
      setReason,
      resetListOldProductItems,
      resetListNewProductItems,
    };
  });

export default ReplaceOrderStore;
export type ReplaceOrderStoreInstance = Instance<typeof ReplaceOrderStore>;
