import { DeliveryMethod, PaymentMethod } from 'enums';

export const DeductReturnedProductOptions: SelectOption[] = [
  {
    label: '0%',
    value: '0%',
  },
  {
    label: '10%',
    value: '10%',
  },
  {
    label: 'Other',
    value: -1,
  },
];

export const PaymentMethodOptions: SelectOption[] = [
  {
    label: 'Card',
    value: PaymentMethod.CARD,
  },
  {
    label: 'Cash',
    value: PaymentMethod.CASH,
  },
];

export const PointOptions: SelectOption[] = [
  {
    label: 'Point',
    value: 'POINT',
  },
];

export const DeliveryMethodOptions: SelectOption[] = [
  {
    label: 'Delivery at home',
    value: DeliveryMethod.HOME,
  },
  {
    label: 'Delivery at the delivery point',
    value: DeliveryMethod.OFFICE,
  },
  {
    label: 'Delivery directly',
    value: DeliveryMethod.DIRECTLY,
  },
];
