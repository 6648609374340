import { SnapshotIn, types } from 'mobx-state-tree';

export const OrderStatusReportFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
});

export interface OrderStatusReportFilterParams
  extends SnapshotIn<typeof OrderStatusReportFilterParamsModel> {}
export const DefaultOrderStatusReportFilterParams: OrderStatusReportFilterParams = {
  keyword: '',
  type: '',
  status: '',
};
