import { createStoreContext } from '@vklink/libs-state';
import { type InternalAdjStoreInstance } from './InternalAdjStore';

const [InternalAdjStoreProvider, useInternalAdjStore] =
  createStoreContext<InternalAdjStoreInstance>();

export { InternalAdjStoreProvider, useInternalAdjStore };

export {
  default as InternalAdjStore,
  type InternalAdjStoreEnv,
  type InternalAdjStoreInstance,
} from './InternalAdjStore';
