import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ImportHistoryModel = types.model('Product List Model', {
  batchNumber: types.string,
  exportLocationId: types.maybeNull(types.string),
  exportLocationName: types.maybeNull(types.string),
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  createdBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  exportwarehouseid: types.maybeNull(types.string),
  exportwarehousename: types.maybeNull(types.string),
  importLocationId: types.maybeNull(types.string),
  importLocationName: types.maybeNull(types.string),
  importWarehouseId: types.maybeNull(types.string),
  importWarehouseName: types.maybeNull(types.string),
  quantity: types.number,
  referenceId: types.string,
  referenceType: types.maybeNull(types.string),
  referencecode: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});

export interface ImportHistoryInstance extends Instance<typeof ImportHistoryModel> {}
export interface ImportHistoryInputModel extends SnapshotIn<typeof ImportHistoryModel> {}
export interface ImportHistory extends SnapshotOut<typeof ImportHistoryModel> {}
