import { ProductCategory, UnitProduct } from 'enums';

export const ProductCategoryOptions: SelectOption[] = [
  {
    label: 'Health & Beauty',
    value: ProductCategory.HEALTH,
  },
  {
    label: 'Home & Garden',
    value: ProductCategory.HOME,
  },
  {
    label: 'Sport & Outdoor',
    value: ProductCategory.SPORT,
  },
  {
    label: 'Technology',
    value: ProductCategory.TECH,
  },
  {
    label: 'Other',
    value: ProductCategory.OTHER,
  },
];

export const UnitProductOptions: SelectOption[] = [
  {
    label: 'Pack',
    value: UnitProduct.PACK,
  },
];

export const ProductStatusOptions: SelectOption[] = [
  {
    label: 'Not Use',
    value: false,
  },
  {
    label: 'Use',
    value: true,
  },
];
