import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ReasonCodeModel = types.model('ReasonCode Model', {
  id: types.identifier,
  code: types.string,
  reason: types.string,
  description: types.maybeNull(types.string),
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export const DefaultReasonCodeValue: Partial<ReasonCodeInputModel> = {
  code: 'REA001',
  reason: 'Reason 001',
  description: 'Description 001',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
};

export interface ReasonCodeInstance extends Instance<typeof ReasonCodeModel> {}
export interface ReasonCodeInputModel extends SnapshotIn<typeof ReasonCodeModel> {}
export interface ReasonCode extends SnapshotOut<typeof ReasonCodeModel> {}
