import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderItemModel = types.model('Order Item Model', {
  goodsSetCode: types.maybeNull(types.string),
  goodsSetName: types.maybeNull(types.string),
  goodsCode: types.maybeNull(types.string),
  goodsName: types.maybeNull(types.string),
  quantity: types.number,
  returnedQuantity: types.number,
  remaningQuantity: types.number,
});

export interface OrderItem extends SnapshotOut<typeof OrderItemModel> {}
