import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { PickupDetailModel } from './PickupDetailModel';

export const CartonInformationModel = types
  .model('Carton Information Model', {
    id: types.identifier,
    orderNumber: types.string,
    packageNumber: types.string,
    packageCode: types.string,
    packageName: types.string,
    totalProduct: types.optional(types.number, 0),
    weight: types.optional(types.number, 0),
    bolCode: types.maybeNull(types.string),
    trackAndTrace: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    isSendToDeliveryVendor: types.maybeNull(types.boolean),
    items: types.maybe(types.array(PickupDetailModel)),
  })
  .views((self) => ({
    get autoPackageName() {
      return `Carton ${self.packageNumber}`;
    },
    get autoPackageCode() {
      return `CT${self.packageNumber}`;
    },
  }));

export const DefaultCartonInformationValue: Partial<CartonInformationInputModel> = {
  packageCode: '',
  packageName: '',
  totalProduct: 0,
  weight: 0,
  description: '',
};

export type CartonDetailInformation = Pick<
  CartonInformation,
  'packageCode' | 'packageName' | 'totalProduct' | 'weight' | 'description'
>;

export interface CartonInformationInstance extends Instance<typeof CartonInformationModel> {}
export interface CartonInformationInputModel extends SnapshotIn<typeof CartonInformationModel> {}
export interface CartonInformation extends SnapshotOut<typeof CartonInformationModel> {}
