import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { PurchaseOrderDetailModel } from './PurchaseOrderDetailModel';

export const PurchaseOrderModel = types.model('PurchaseOrder Model', {
  id: types.identifier,
  code: types.string,
  type: types.string,
  enteredDate: types.maybeNull(types.string),
  issuedDate: types.maybeNull(types.string),
  supplierCode: types.string,
  supplierName: types.string,
  shippingMethod: types.string,
  description: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  warehouseId: types.string,
  warehouseName: types.maybeNull(types.string),
  warehouse: types.model({
    id: types.string,
    name: types.string,
  }),
  purchaseOrderItems: types.array(PurchaseOrderDetailModel),
});

export interface PurchaseOrderInstance extends Instance<typeof PurchaseOrderModel> {}
export interface PurchaseOrderInputModel extends SnapshotIn<typeof PurchaseOrderModel> {}
export interface PurchaseOrder extends SnapshotOut<typeof PurchaseOrderModel> {}
