import { createStoreContext } from '@vklink/libs-state';
import { type ImportStockStoreInstance } from './ImportStockStore';

const [ImportStockStoreProvider, useImportStockStore] =
  createStoreContext<ImportStockStoreInstance>();

export { ImportStockStoreProvider, useImportStockStore };

export {
  default as ImportStockStore,
  type ImportStockStoreEnv,
  type ImportStockStoreInstance,
} from './ImportStockStore';
