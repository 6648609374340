import { generatePath } from 'react-router';

import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { ExportStockModel } from './models/ExportStockModel';
import {
  DefaultExportStockFilterParams,
  ExportStockFilterParams,
  ExportStockFilterParamsModel,
  ExportStock,
  OrderPackagesModel,
  OrderPackages,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import { EXPORT_STOCK_API } from 'api';
import { WarehouseOptionsModel } from 'pages/shared/models/common-options';

export type ExportStockStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ExportStockStore = types
  .model('ExportStock Store', {
    exportStocks: types.array(ExportStockModel),
    packagesDetail: types.array(OrderPackagesModel),
    confirmedExportStocks: types.array(ExportStockModel),
    warehouseOptions: types.array(WarehouseOptionsModel),
    filterParams: types.optional(ExportStockFilterParamsModel, DefaultExportStockFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams.getFilter,
          ...self.paginationParams,
        });
      },
      get getWarehouseOptions() {
        return self.warehouseOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get listExportStocks() {
        return getSnapshot(self.exportStocks);
      },
      get listConfirmedExportStocks() {
        return getSnapshot(self.confirmedExportStocks);
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ExportStockStoreEnv>(self);

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const setQueryParamsValue = flow(function* (name: string, value: any) {
      (self.filterParams as any)[name] = value;
    });

    const setQueryParams = (filterParams: ExportStockFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultExportStockFilterParams, ...filterParams });
    };

    const setConfirmedExportStock = (detailExportStock: ExportStock[]) => {
      applySnapshot(self.confirmedExportStocks, detailExportStock);
    };

    const setPackagesDetail = (packagesDetail: OrderPackages[]) => {
      applySnapshot(self.packagesDetail, packagesDetail);
    };

    const getExportStockByOrderNumberAsync = flow(function* (orderNumber: string) {
      const loadingId = load('Get Exprot Stock List Async');
      try {
        const response = yield http.get(
          generatePath(EXPORT_STOCK_API.GET_DETAIL_EXPORT_STOCK, { id: orderNumber })
        );
        setConfirmedExportStock([response.data]);
      } catch (error) {
        console.log(error);
      } finally {
        loaded(loadingId);
      }
    });

    const getExportStocksAsync = flow(function* () {
      const loadingId = load('Get Exprot Stock List Async');
      try {
        const response = yield http.get(EXPORT_STOCK_API.GET_EXPORT_STOCKS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.exportStocks, []);
        applySnapshot(self.exportStocks, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehouseOptionsAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get('/warehouses');
        applySnapshot(self.warehouseOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createExportStockAsync = flow(function* (
      outDate: string,
      listOrders: string[],
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Export Stock Async');
      const data = {
        outDate: outDate,
        id: listOrders,
      };

      try {
        yield http.post(EXPORT_STOCK_API.POST_EXPORT_STOCK, { ...data });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setPaginationParams,
      setQueryParams,
      setConfirmedExportStock,
      setQueryParamsValue,
      setPackagesDetail,
      createExportStockAsync,
      getExportStocksAsync,
      getWarehouseOptionsAsync,
      getExportStockByOrderNumberAsync,
    };
  });

export default ExportStockStore;
export type ExportStockStoreInstance = Instance<typeof ExportStockStore>;
