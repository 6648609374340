export enum Report {
  Base = '/reports',
  ImportStock = '/reports/import-stock',
  ExportStock = '/reports/export-stock',
  OrderStatus = '/reports/order-status',
  ReturnReplacement = '/reports/return-replacement',
  Sku = '/reports/sku',
  StockTake = '/reports/stock-take',
  Inventory = '/reports/inventory',
  InventoryDetail = '/reports/inventory/detail',
  InventoryDetailQuery = '/reports/inventory/detail?goodsId=:goodsId&warehouseId=:warehouseId&locationId=:locationId&fromDate=:fromDate&toDate=:toDate&batchNumber=:batchNumber&status=:status&type=:type',
  CMove = '/reports/c-move',
  ChangeStatus = '/reports/change-status',
}
