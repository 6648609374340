import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { AdjustmentStatus } from 'enums';

export const StockTakeVoucherListModel = types
  .model('StockTakeVoucherListModel Model', {
    id: types.string,
    voucherCode: types.string,
    issueDate: types.string,
    closingDate: types.maybeNull(types.string),
    warehouseId: types.string,
    warehouseName: types.string,
    voucherStatus: types.string,
    totalItem: types.number,
    totalRealQuantity: types.number,
    adjustmentStatus: types.maybeNull(types.string),
    adjustmentCode: types.maybeNull(types.string),
    adjustmentReason: types.maybeNull(types.string),
    approvedDate: types.maybeNull(types.string),
    rejecter: types.maybeNull(types.string),
    rejectedDate: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get canClose() {
        return self.voucherStatus === AdjustmentStatus.INIT;
      },
    };
  });

export const DefaultStockTakeVoucherListValue: Partial<StockTakeVoucherListInputModel> = {
  id: '',
  issueDate: '',
  voucherStatus: '',
  warehouseId: '',
  warehouseName: '',
  closingDate: '',
};

export interface StockTakeVoucherListInstance extends Instance<typeof StockTakeVoucherListModel> {}
export interface StockTakeVoucherListInputModel
  extends SnapshotIn<typeof StockTakeVoucherListModel> {}
export interface StockTakeVoucherList extends SnapshotOut<typeof StockTakeVoucherListModel> {}
