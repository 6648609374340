import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { ProductCategoryOptions, UnitProductOptions } from 'constant';

export const ProductModel = types.model('Product Model', {
  id: types.string,
  code: types.string,
  name: types.string,
  unit: types.maybeNull(types.string),
  priceBeforeVat: types.maybeNull(types.string),
  priceAfterVat: types.maybeNull(types.string),
  vatPrice: types.maybeNull(types.string),
  isGift: types.boolean,
  sourceFrom: types.maybeNull(types.string),
  isPromotion: types.boolean,
  status: types.string,
  startPromotion: types.maybeNull(types.string),
  endPromotion: types.maybeNull(types.string),
  createdAt: types.string,
  deletedAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  weight: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  isUse: types.maybeNull(types.boolean),
  isNew: types.maybeNull(types.boolean),
  isWeb: types.maybeNull(types.boolean),
  isSet: types.maybeNull(types.boolean),
  category: types.maybeNull(types.string),
  isAds: types.maybeNull(types.boolean),
  // goodsSets: types.array(types.string),
});

export const DefaultProductValue: Partial<ProductInputModel> = {
  code: '',
  name: '',
  unit: UnitProductOptions[0].value,
  isPromotion: false,
  startPromotion: '',
  endPromotion: '',
  weight: null,
  description: '',
  isUse: false,
  isNew: false,
  isGift: false,
  category: ProductCategoryOptions[0].value,
};

export type CreateProduct = Pick<
  ProductInputModel,
  | 'name'
  | 'code'
  | 'description'
  | 'unit'
  | 'weight'
  | 'isGift'
  | 'isPromotion'
  | 'startPromotion'
  | 'endPromotion'
  | 'isNew'
  | 'isUse'
  | 'category'
  | 'priceBeforeVat'
>;

export interface ProductInstance extends Instance<typeof ProductModel> {}
export interface ProductInputModel extends SnapshotIn<typeof ProductModel> {}
export interface Product extends SnapshotOut<typeof ProductModel> {}
