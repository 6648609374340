import { SnapshotIn, types } from 'mobx-state-tree';

export const ReturnOrderFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
});

export interface ReturnOrderFilterParams extends SnapshotIn<typeof ReturnOrderFilterParamsModel> {}
export const DefaultReturnOrderFilterParams: ReturnOrderFilterParams = {
  keyword: '',
  fromDate: null,
  toDate: null,
};
