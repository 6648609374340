const API_V1 = '';

export const INTERNAL_ADJUSTMENTS_API = {
  GET_LIST_INTERNAL_ADJUSTMENTS: `${API_V1}/internal-adjustments`,
  CREATE_INTERNAL_ADJUSTMENTS: `${API_V1}/internal-adjustments`,
  GET_INTERNAL_ADJUSTMENT_BY_ID: `${API_V1}/internal-adjustments/:id`,
  UPDATE_STATUS_INTERNAL_ADJUSTMENT: `${API_V1}/internal-adjustments/:id/status`,
  EDIT_INTERNAL_ADJUSTMENTS: `${API_V1}/internal-adjustments/:id`,
  PRINT_BARCODE: `${API_V1}/internal-adjustments/:id/items/:itemId/print-barcode`,
  CHECK_STOCK: `${API_V1}/internal-adjustments/:id/check-stock`,
};
