import { generatePath } from 'react-router';

import { types, Instance, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  WarehouseModel,
  CreateWarehouse,
  WarehouseFilterParamsModel,
  DefaultWarehouseFilterParams,
  WarehouseFilterParams,
  Warehouse,
  BranchOptionsModel,
  BranchDetailModel,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { removeEmptyInObject } from 'pages/shared/utils';
import { WAREHOUSE_API } from 'api';

export type WarehouseStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const WarehouseStore = types
  .model('Warehouse Store', {
    warehouses: types.array(WarehouseModel),
    warehouseDetail: types.maybe(WarehouseModel),
    branchOptions: types.array(BranchOptionsModel),
    branchDetail: types.maybe(BranchDetailModel),
    filterParams: types.optional(WarehouseFilterParamsModel, DefaultWarehouseFilterParams),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => {
    return {
      get warehouseList() {
        return self.warehouses;
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getWarehouseDetail() {
        return self.warehouseDetail;
      },
      get getBranchOptions() {
        return self.branchOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<WarehouseStoreEnv>(self);

    const setQueryParams = (filterParams: WarehouseFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultWarehouseFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getBranchOptionsAsync = flow(function* () {
      const response = yield http.get(WAREHOUSE_API.GET_BRANCHES);
      applySnapshot(self.branchOptions, response.data);
    });

    const getWarehousesAsync = flow(function* () {
      const loadingId = load('Get Warehouse Async');
      try {
        const response = yield http.get(WAREHOUSE_API.GET_WAREHOUSES, {
          params: self.getQueryParams,
        });
        applySnapshot(self.warehouses, []);
        applySnapshot(self.warehouses, response.data.results);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBranchDetailAsync = flow(function* (branchId: string) {
      const loadingId = load('Get Address By Branch Id Async');
      try {
        const response = yield http.get(
          generatePath(WAREHOUSE_API.GET_BRANCH_BY_ID, { id: branchId })
        );
        self.branchDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createWarehouse = flow(function* (warehouse: CreateWarehouse, cb?: RequestCallback) {
      const loadingId = load('Create Warehouse');
      try {
        const addWarehouse = removeEmptyInObject(warehouse);
        yield http.post(WAREHOUSE_API.POST_WAREHOUSE, addWarehouse);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editWarehouse = flow(function* (
      warehouse: CreateWarehouse,
      id: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Edit Warehouse');
      try {
        yield http.put(generatePath(WAREHOUSE_API.PUT_WAREHOUSE, { id: id }), { ...warehouse, id });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getWarehouseById = flow(function* (id: string) {
      const loadingId = load('Get Warehouse By Id');
      const response = yield http.get(generatePath(WAREHOUSE_API.GET_WAREHOUSE_BY_ID, { id: id }));
      self.warehouseDetail = response.data;
      loaded(loadingId);
    });

    const setWarehouseDetail = (warehouse: Warehouse) => {
      self.warehouseDetail = { ...warehouse, branch: null };
    };

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Warehouse');
      try {
        yield http.patch(
          generatePath(WAREHOUSE_API.PUT_STATUS_WAREHOUSE, { id: self.warehouseDetail?.id })
        );
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getWarehousesAsync,
      createWarehouse,
      editWarehouse,
      getWarehouseById,
      updateStatus,
      setQueryParams,
      setWarehouseDetail,
      setPaginationParams,
      getBranchOptionsAsync,
      getBranchDetailAsync,
    };
  });

export default WarehouseStore;
export type WarehouseStoreInstance = Instance<typeof WarehouseStore>;
