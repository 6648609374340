import { generatePath } from 'react-router';

import { types, Instance, applySnapshot, flow, getEnv, getSnapshot } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import {
  DefaultProductFilterParams,
  ProductFilterParams,
  ProductFilterParamsModel,
  ProductModel,
  CreateProduct,
} from './models';
import {
  DefaultPaginationInfo,
  DefaultPaginationParams,
  PaginationModel,
  PaginationParamsModel,
} from 'pages/shared/models/pagination';
import { ProductStatus } from 'enums';

import { removeEmptyInObject } from 'pages/shared/utils';
import ProductInventoryStore from './ProductInventoryStore';
import { GOODS_API } from 'api';

export type ProductStoreEnv = {
  http: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProductStore = types
  .compose(
    ProductInventoryStore,
    types.model('Product Store', {
      products: types.array(ProductModel),
      productDetail: types.maybe(ProductModel),
      filterParams: types.optional(ProductFilterParamsModel, DefaultProductFilterParams),
      paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
      pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    })
  )
  .views((self) => {
    return {
      get listProducts() {
        return self.products;
      },
      get productInventoryList() {
        return getSnapshot(self.productInventories);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
    };
  })
  .actions((self) => {
    const { http, load, loaded } = getEnv<ProductStoreEnv>(self);

    const setQueryParams = (filterParams: ProductFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultProductFilterParams, ...filterParams });
    };

    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    const getProductsAsync = flow(function* () {
      const loadingId = load('Get Product Async');
      try {
        const response = yield http.get(GOODS_API.GET_GOODS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.products, []);
        applySnapshot(self.products, response.data.results);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createProduct = flow(function* (product: CreateProduct, cb?: RequestCallback) {
      const loadingId = load('Create Product');
      try {
        yield http.post(GOODS_API.POST_GOODS, product);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editProduct = function (product: CreateProduct, id: string, cb?: RequestCallback) {
      const loadingId = load('Edit Product');
      try {
        http.put(generatePath(GOODS_API.PUT_GOODS, { id: id }), { ...product, id });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    };

    const getProductById = flow(function* (id: string) {
      const loadingId = load('Get Product By Id');
      const response = yield http.get(generatePath(GOODS_API.GET_GOODS_BY_ID, { id }));
      self.productDetail = response.data;

      loaded(loadingId);
    });

    const editProductStatus = flow(function* (id: string, status: string, cb?: RequestCallback) {
      const loadingId = load('Edit Product Status');
      const productStatus = (
        status === ProductStatus.ACTIVE ? ProductStatus.INACTIVE : ProductStatus.ACTIVE
      ).toLowerCase();
      try {
        yield http.put(generatePath(GOODS_API.PUT_STATUS_GOODS, { id, status: productStatus }));
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getProductsAsync,
      createProduct,
      editProduct,
      getProductById,
      setQueryParams,
      editProductStatus,
      setPaginationParams,
    };
  });

export default ProductStore;
export type ProductStoreInstance = Instance<typeof ProductStore>;
