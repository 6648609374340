import { AdjustmentStatus, AdjustmentKind } from 'enums';

export const AdjustmentStatusOptions: SelectOption[] = [
  {
    label: 'Init',
    value: AdjustmentStatus.INIT,
  },
  {
    label: 'Approved',
    value: AdjustmentStatus.APPROVED,
  },
  {
    label: 'Cancelled',
    value: AdjustmentStatus.CANCELLED,
  },
  {
    label: 'Rejected',
    value: AdjustmentStatus.REJECTED,
  },
  {
    label: 'In Process',
    value: AdjustmentStatus.IN_PROCESS,
  },
];

export const AdjustmentKindOptions: SelectOption[] = [
  {
    label: 'Import Stock',
    value: AdjustmentKind.INWARD,
  },
  {
    label: 'Export Stock',
    value: AdjustmentKind.OUTWARD,
  },
];
