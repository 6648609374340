import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const TransferProductModel = types.model({
  goodsCode: types.string,
  goodsName: types.string,
  barcodeNumber: types.string,
  quantity: types.maybeNull(types.number),
  replacementMethod: types.string,
  sku: types.maybe(types.string),
  productName: types.string,
  remainingQuantity: types.number,
  oldBarcode: types.string,
  newBarcode: types.string,
  reasons: types.array(types.string),
});

export const DefaultTransferProductItem: Partial<TransferProductModelInputModel> = {
  quantity: 0,
};

export interface TransferProductModelInstance extends Instance<typeof TransferProductModel> {}
export interface TransferProductModelInputModel extends SnapshotIn<typeof TransferProductModel> {}
export interface TransferProduct extends SnapshotOut<typeof TransferProductModel> {}
