import { createStoreContext } from '@vklink/libs-state';
import { type ReasonCodeStoreInstance } from './ReasonCodeStore';

const [ReasonCodeStoreProvider, useReasonCodeStore] = createStoreContext<ReasonCodeStoreInstance>();

export { ReasonCodeStoreProvider, useReasonCodeStore };

export {
  default as ReasonCodeStore,
  type ReasonCodeStoreEnv,
  type ReasonCodeStoreInstance,
} from './ReasonCodeStore';
